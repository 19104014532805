/* eslint-disable no-restricted-syntax */
import React, { useEffect } from "react";
import { useAppSelector } from "../../../../hooks/redux/hooks";
import { RoleUser } from "../../../../slices/users.slice";
import AudioRecorder from "./AudioRecorder.component";
import { IAcceptedFiles } from "../../../../shared/models/interfaces/acceptedfile.interface";

const SecondaryOptions = ({
  handleClick,
  handleEmojiClick,
  handleCommenttemplateClick,
  openPicker,
  sending,
  setSending,
  setRecording,
  sendAudio,
  message,
  thumbs,
  keyword,
  setKeyword,
  handleSendClick,
}: {
  handleEmojiClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleCommenttemplateClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleClick: () => void;
  openPicker: boolean;
  sending: boolean;
  setSending: React.Dispatch<React.SetStateAction<boolean>>;
  setRecording: React.Dispatch<React.SetStateAction<boolean>>;
  sendAudio: (blobUrl: string, blob: Blob) => Promise<void>;
  message: string | undefined;
  thumbs: IAcceptedFiles;
  keyword: string;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  handleSendClick: () => Promise<void>;
}) => {
  const { user } = useAppSelector((state) => state.auth);
  const { AudioBar, handleMicClick, recording } = AudioRecorder({
    extraActions: sendAudio,
    setSending,
  });

  useEffect(() => {
    setRecording(recording);
  }, [recording]);

  const items = [
    {
      onClick: handleEmojiClick,
      icon: "la-grin",
      title: "Emojis",
    },
    {
      onClick: handleClick,
      icon: "la-paperclip",
      title: "Anexos",
    },
  ];

  if (!user?.roles?.includes(RoleUser.CONTACT)) {
    items.push({
      onClick: handleCommenttemplateClick,
      icon: "la-comment",
      title: "Modelos de respostas",
    });
  }

  const getClassMessage = () => {
    if (sending) {
      return "";
    }
    if ((message && message.length > 0) || (thumbs && thumbs.length > 0)) {
      return "la-paper-plane";
    }
    return "la-microphone";
  };

  const getTitle = () => {
    if (sending) {
      return "Enviando...";
    }
    if ((message && message.length > 0) || (thumbs && thumbs.length > 0)) {
      return "Enviar mensagem";
    }
    return "Gravar áudio";
  };

  const showItems = () => {
    const jsx = [];
    for (const item of items) {
      jsx.push(
        <button
          key={item.title}
          disabled={recording}
          type="button"
          onClick={item.onClick}
          className={`las ${item.icon} icon hover:text-agitalks-alphaC0 ${
            openPicker && item.icon === "la-grin"
              ? "text-agitalks"
              : "text-gray-700 hover:!text-agitalks"
          }`}
          title={item.title}
          aria-label={item.title}
        />
      );
    }
    return jsx;
  };

  const getOnlyAudioBar = () => {
    return <AudioBar />;
  };

  return (
    <div className="sendbox-action-container">
      <div className="flex gap-3">{showItems()}</div>
      {!recording || sending ? (
        <button
          type="button"
          disabled={sending}
          onClick={async () => {
            if (keyword.length > 0) setKeyword("");
            if (
              (message && message.length > 0) ||
              (thumbs && thumbs.length > 0)
            ) {
              await handleSendClick();
            } else {
              await handleMicClick();
            }
          }}
          className={`las ${getClassMessage()} icon text-gray-700`}
          title={getTitle()}
          aria-label={getTitle()}
        />
      ) : (
        getOnlyAudioBar()
      )}
    </div>
  );
};

export default SecondaryOptions;
