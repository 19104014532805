import React, { FormEvent, useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useInput from "../../../../hooks/useInput";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux/hooks";
import validatePassword from "../../../../shared/utils/validation/password";
import { recoveryPassword, reset } from "../../../../slices/auth.slice";
import LoadingDots from "../../../../shared/components/LoadingDots.component";
import { IUser, UserType } from "../../../../slices/users.slice";
import FormButton from "../../../../shared/components/Buttons/FormButton.component";
import StrongPasswordChecklist from "../../../../shared/components/StrongPasswordChecklist.component";
import ShowHideButton from "../../../../shared/components/Buttons/ShowHideButton.component";

const PasswordForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingAuth, isSuccess } = useAppSelector((state) => state.auth);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code")?.replaceAll(" ", "+");

  const goToLogin = async () => {
    navigate("/login");
  };

  const {
    text: password,
    shouldDisplayError: passwordHasError,
    textChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    clearHandler: passwordClearHandler,
  } = useInput(validatePassword);
  const {
    text: confirmPassword,
    shouldDisplayError: confirmPasswordHasError,
    textChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
    clearHandler: confirmPasswordClearHandler,
  } = useInput(validatePassword);

  const [showHide, setShowHide] = useState<{
    pass: boolean;
    confirmpass: boolean;
  }>({
    pass: false,
    confirmpass: false,
  });

  const clearForm = () => {
    passwordClearHandler();
    confirmPasswordClearHandler();
  };

  const handleShowHide = (
    e: React.MouseEvent<Element, MouseEvent>,
    type: "pass" | "confirmpass"
  ) => {
    e.preventDefault();
    setShowHide((prevState) => ({
      pass: type === "pass" ? !prevState.pass : prevState.pass,
      confirmpass:
        type === "confirmpass" ? !prevState.confirmpass : prevState.confirmpass,
    }));
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(reset());
      clearForm();
    }
  }, [isSuccess, dispatch]);

  const onSubmitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== confirmPassword) return;
    if (passwordHasError || confirmPasswordHasError) return;
    if (password.length === 0 || confirmPassword.length === 0) return;

    const newPassword = {
      code: code || "",
      password,
    };
    const { payload } = await dispatch(recoveryPassword(newPassword));
    const _user = payload as IUser;
    if (_user?._id) {
      goToLogin();
    }
  };

  if (isLoadingAuth)
    return <LoadingDots className="flex justify-center items-center" />;
  return (
    <Form
      className="bg-white h-full rounded-xl flex flex-col -mt-[16px]"
      onSubmit={onSubmitHandler}
    >
      <label className="pt-2 relative">
        <Form.Input
          label={t("form.label.password")}
          value={password}
          // className="pb-2"
          // required
          onChange={passwordChangeHandler}
          onBlur={passwordBlurHandler}
          error={passwordHasError}
          type={showHide.pass ? "input" : "password"}
          name="password"
          id="password"
          variant="outlined"
          placeholder={t("form.placeholder.password")}
          autoComplete="new-password"
          action
        >
          <input />
          <ShowHideButton
            title={`${!showHide.pass ? "Mostrar" : "Esconder"} ${t(
              "form.label.password"
            ).toLowerCase()}`}
            hide={showHide.pass}
            onClick={(e) => handleShowHide(e, "pass")}
          />
        </Form.Input>
        {passwordHasError ? (
          <StrongPasswordChecklist
            rules={["minLength", "specialChar", "number", "capital"]}
            minLength={8}
            value={password || ""}
          />
        ) : null}
      </label>
      <label className="pt-2 relative">
        <Form.Input
          // className="pb-2"
          label={t("form.label.confirm-password")}
          value={confirmPassword}
          // required
          onChange={confirmPasswordChangeHandler}
          onBlur={confirmPasswordBlurHandler}
          error={confirmPassword.length > 0 && password !== confirmPassword}
          type={showHide.confirmpass ? "input" : "password"}
          name="confirmPassword"
          id="confirmPassword"
          variant="outlined"
          placeholder={t("form.placeholder.confirm-password")}
          autoComplete="new-password"
          action
        >
          <input />
          <ShowHideButton
            title={`${!showHide.confirmpass ? "Mostrar" : "Esconder"} ${t(
              "form.label.confirm-password"
            ).toLowerCase()}`}
            hide={showHide.confirmpass}
            onClick={(e) => handleShowHide(e, "confirmpass")}
          />
        </Form.Input>
        {confirmPasswordHasError || password !== confirmPassword ? (
          <StrongPasswordChecklist
            rules={["match"]}
            minLength={8}
            value={confirmPassword || ""}
          />
        ) : null}
      </label>

      <FormButton
        className="flex flex-wrap justify-center mt-4"
        disabled={
          password.length === 0 ||
          confirmPassword.length === 0 ||
          passwordHasError ||
          confirmPasswordHasError ||
          password !== confirmPassword ||
          false
        }
        label={t("formbutton.label.save")}
      />
    </Form>
  );
};

PasswordForm.defaultProps = {
  widgetType: UserType.NONE,
};

export default PasswordForm;
