import React from "react";
import {
  IAgideskEntity,
  reselectExternalappAgidesk,
} from "../../../slices/externalapps.slice";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";

export type dataProps = {
  team?: { _id: string; name: string };
  service: IAgideskEntity;
};

const ServicesDisplayComponent = ({
  data,
  setData,
  type,
}: {
  data: dataProps[];
  setData: React.Dispatch<React.SetStateAction<dataProps[]>>;
  type: "team-service" | "service";
}) => {
  const dispatch = useAppDispatch();
  const { selectedExternalAppAgidesk } = useAppSelector(
    (state) => state.externalapps
  );
  const { user } = useAppSelector((state) => state.auth);

  const handleDelete = async (i: number) => {
    const deleteVal = [...data];
    deleteVal.splice(i, 1);
    setData(deleteVal);
    if (type === "service") {
      const _serviceData = deleteVal.map((item) => item.service);
      dispatch(
        reselectExternalappAgidesk({
          ...selectedExternalAppAgidesk,
          fields: {
            ...selectedExternalAppAgidesk.fields,
            service: _serviceData,
          },
        })
      );
    } else if (type === "team-service") {
      dispatch(
        reselectExternalappAgidesk({
          ...selectedExternalAppAgidesk,
          fields: {
            ...selectedExternalAppAgidesk.fields,
            ticketfinishteam: deleteVal as any,
          },
        })
      );
    }
  };

  const getBaseComponent = ({
    _team,
    _service,
    i,
  }: {
    _team?: { _id?: string; name?: string };
    _service: { _id: string; name: string };
    i: number;
  }) => {
    return (
      <div
        key={
          _team?._id !== "undefined" && _team?._id
            ? `${_team?._id}-${_service._id}`
            : `${_service._id}`
        }
        className="cursor-pointer border border-slate-300 bg-white flex items-center p-3 rounded-md"
      >
        {_team?._id !== "undefined" && _team?._id ? (
          <>
            <h2 className="text-[12px] font-semibold">{_team?.name}</h2>
            <i title="Relação" className="flex items-center las la-link px-2" />
          </>
        ) : null}
        <h2 className="text-[12px] font-semibold">{_service?.name}</h2>
        <i
          title={
            _team?._id !== "undefined" ? "Remover relação" : "Remover serviço"
          }
          className="pl-2 flex items-center text-red las text-[14px] la-times-circle cursor-pointer hover:text-red-alphaC0"
          role="presentation"
          onClick={() => handleDelete(i)}
        />
      </div>
    );
  };

  const getDataComponent = () => {
    if (type === "service") {
      return data.map((val: dataProps, i: number) => {
        const _service = {
          _id: val?.service?.id,
          name: val?.service?.title,
        };
        return getBaseComponent({ _service, i });
      });
    }
    return data.map((val: dataProps, i: number) => {
      const _service = {
        _id: val?.service?.id,
        name: val?.service?.title,
      };
      let _userTeam;
      if (typeof val.team === "string") {
        _userTeam = user?.teams?.find((_t) => _t._id === val?.team);
      } else if (typeof val.team?._id !== "undefined") {
        _userTeam = { ...val.team };
      }
      const _team = {
        _id: _userTeam?._id,
        name: _userTeam?.name,
      };
      return getBaseComponent({ _team, _service, i });
    });
  };

  const hasDataTitle = {
    service: "Serviços selecionados",
    "team-service": "Relações entre equipes e serviços",
  };
  const hasNotDataTitle = {
    service: "Nenhum serviço relacionado",
    "team-service": "Nenhuma relação criada",
  };

  return (
    <div className="flex flex-col gap-y-2">
      <label>
        {data.length > 0 && typeof data[0].service !== "undefined"
          ? hasDataTitle[type]
          : hasNotDataTitle[type]}
      </label>
      {data.length > 0 && typeof data[0].service !== "undefined" ? (
        <div
          id={type === "service" ? "agidesk-services" : "agidesk-team-services"}
          className={`flex flex-wrap gap-1.5 max-h-[250px] overflow-y-scroll ${
            data?.length ? "" : "justify-center"
          }`}
        >
          {getDataComponent()}
        </div>
      ) : null}
    </div>
  );
};

export default ServicesDisplayComponent;
