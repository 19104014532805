import React from "react";
import { LoginContactForm } from "../features/auth/components/Forms";
import AuthLayoutPage from "./AuthLayout.page";
import { useAppSelector } from "../hooks/redux/hooks";
import { UserType } from "../slices/users.slice";
import LoadingDots from "../shared/components/LoadingDots.component";
import GroupedForm from "../features/auth/components/Forms/Grouped.form";

const LoginPage = ({
  widgetType,
  isOffline,
  setIsOffline,
}: {
  widgetType?: UserType;
  isOffline?: boolean;
  setIsOffline?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { isLoadingAuth } = useAppSelector((state) => state.auth);
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const getContent = () => {
    if (
      (widgetType === UserType.CONTACT && isAuthenticated) ||
      (widgetType !== UserType.CONTACT && isLoadingAuth)
    ) {
      return <LoadingDots className="flex justify-center items-center" />;
    }
    if (widgetType === UserType.CONTACT) {
      return (
        <LoginContactForm isOffline={isOffline} setIsOffline={setIsOffline} />
      );
    }
    return <GroupedForm widgetType={widgetType} />;
  };

  return (
    <AuthLayoutPage widgetType={widgetType}>{getContent()}</AuthLayoutPage>
  );
};

LoginPage.defaultProps = {
  widgetType: UserType.NONE,
  isOffline: false,
  setIsOffline: undefined,
};

export default LoginPage;
