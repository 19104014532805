import { useState } from "react";
import { twJoin } from "tailwind-merge";
import Toggle from "../../shared/components/Toggle.component";
interface CustomCollapsibleProps {
  title: React.ReactNode;
  children: React.ReactNode;
  classname?: string;
  action?: () => void;
  open?: boolean;
}
const CustomCollapsible: React.FC<CustomCollapsibleProps> = ({
  title,
  children,
  classname,
  action,
  open,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const toggleCollapse = () => {
    action && action();
    setIsOpen((prev) => !prev);
  };
  return (
    <section className={twJoin("w-full", classname)}>
      <div className="w-full flex flex-row gap-1">
        <Toggle
          onChange={toggleCollapse}
          checked={isOpen}
          mainClass=""
          labelClass="ml-4"
          label=""
          containerClass=""
        />
        {title}
      </div>
      {isOpen && children}
    </section>
  );
};
export default CustomCollapsible;
