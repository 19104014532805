import React from "react";

const ChatSortComponent = ({
  selectedSort,
  setSelectedSort,
  disabled,
}: {
  selectedSort: number;
  setSelectedSort: React.Dispatch<React.SetStateAction<number>>;
  disabled: boolean;
}) => {
  return (
    <div
      className={`chat-sort ${disabled ? "disabled" : ""}`}
      title={`Ordenando por conversas mais ${
        selectedSort === 1 ? "antigas" : "recentes"
      }`}
      role="presentation"
      onClick={() => {
        if (!disabled) {
          setSelectedSort((prevState) => (prevState === 1 ? -1 : 1));
        }
      }}
    >
      <i className="las la-exchange-alt rotate-90" />
      <span>{selectedSort === 1 ? "Mais antigas" : "Mais recentes"}</span>
    </div>
  );
};

export default ChatSortComponent;
