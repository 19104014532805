interface CustomToggleProps {
  checked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const CustomToggle = ({
  checked,
  onChange,
  disabled = false,
}: CustomToggleProps) => (
  <label className="relative inline-block leading-none h-5">
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className="
          w-8 h-0 cursor-pointer
          focus:outline-0 dark:focus:outline-0
          border-0 dark:border-0
          focus:ring-offset-transparent dark:focus:ring-offset-transparent
          focus:ring-transparent dark:focus:ring-transparent
          focus-within:ring-0 dark:focus-within:ring-0
          focus:shadow-none dark:focus:shadow-none
          peer
        "
    />
    <span
      className="
          absolute top-0 left-0 block w-8 h-[18px] bg-red dark:bg-gray-600 rounded-full
          transition duration-100
          peer-checked:bg-green dark:peer-checked:bg-green
          peer-disabled:bg-opacity-40 peer-disabled:cursor-not-allowed
        "
    ></span>
    <span
      className="
          absolute top-0 left-0 block w-4 h-4 mt-0.5 ml-0.5 bg-white dark:bg-gray-50 rounded-full
          shadow-md transition-transform duration-300
          peer-checked:translate-x-3
          peer-disabled:bg-opacity-75
        "
    ></span>
  </label>
);

export default CustomToggle;
