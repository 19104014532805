import ActionButtonsModal from "../../../../shared/components/Modals/ActionButtonsModal.component";
import TemplatePreview from "../WhatsApp/Template/TemplatePreview.component";
import { ResendTemplateProps } from "./@types";
import useResendTemplate from "./controller";

export const ResendTemplateModal = ({
  isOpen,
  closeModal,
}: ResendTemplateProps) => {
  const { chatTemplate, sendTemplate } = useResendTemplate();

  const handleSendTemplate = () => {
    sendTemplate();
    setTimeout(() => closeModal(false), 500);
  };

  const shouldShowTemplate = chatTemplate && typeof chatTemplate === "object";

  return isOpen ? (
    <div id="modal" className="fixed inset-0 z-[200]">
      <div
        role="presentation"
        className="fixed inset-0 w-full h-full bg-black opacity-80"
        onClick={() => closeModal(false)}
      />
      <div className="flex items-center justify-center min-h-screen">
        <div className="relative flex-container bg-white flex flex-col justify-center rounded-md max-h-full shadow-lg py-8 px-10 w-4/5 sm:w-2/4 md:max-w-[600px]">
          <h1 className="header flex items-center justify-center font-semibold">
            <i className="lab la-whatsapp text-4xl" />
            <p className="text-2xl ml-3">Revise o template</p>
          </h1>

          {shouldShowTemplate && (
            <TemplatePreview
              commenttemplate={chatTemplate?.message}
              isChatTemplate={!!chatTemplate?.message?.files?.length}
            />
          )}

          <ActionButtonsModal
            cancelAction={() => closeModal(false)}
            submitAction={handleSendTemplate}
          />
        </div>
      </div>
    </div>
  ) : null;
};
