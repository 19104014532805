import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";

import {
  LayoutPage,
  LoginPage,
  PasswordPage,
  RecoveryPage,
  PrivacyPage,
  SurveyratingPage,
  LoginTenantPage,
  PublichoursPage,
} from "../pages";
import {
  PrivateRoute,
  PublicRoute,
  WidgetAgentRoute,
  WidgetContactRoute,
} from "../routes";

import WidgetButton from "../widget/WidgetButton.component";
import RecentChatsList from "../features/chats/components/RecentChats/List.component";
import {
  changeStatus,
  refreshLogout,
  selectTenant,
  selectWidget,
  signin,
  // userdata,
} from "../slices/auth.slice";
import { ChatStatus, clearRecents, closeWidget } from "../slices/chats.slice";
import { UserStatus, UserType } from "../slices/users.slice";
import instances, { updateTenant } from "../services/instances";
import { getSettings } from "../slices/settings.slice";
import favicon from "../shared/utils/favicon";
import { teamsChatsAssignment } from "../slices/teams.slice";
import { loadUserPreferences } from "../slices/preferences.slice";

const App = () => {
  const { user, isAuthenticated, isStatusManualChanged } = useAppSelector(
    (state) => state.auth
  );
  const { recentChats, backlogChats, mineChats } = useAppSelector(
    (state) => state.chats
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [toggleOpenClose, setToggleOpenClose] = useState<boolean>(false);
  const [widgetType, setWidgetType] = useState<UserType>(UserType.NONE);
  const [disable, setDisable] = useState(false);
  const [src, setSrc] = useState("/favicon.ico");
  const [code, setCode] = useState("");
  const [badge, setBadge] = useState<number>(0);

  useEffect(() => {
    if (typeof user?._id !== "undefined" && isAuthenticated) {
      // (async () => {
      //   await dispatch(userdata());
      // })();
      if (user.status === UserStatus.OFFLINE && !isStatusManualChanged) {
        (async () => {
          await dispatch(
            changeStatus({ status: UserStatus.ONLINE, origin: user?._id })
          );
          await dispatch(teamsChatsAssignment());
        })();
      }
      dispatch(loadUserPreferences(user._id));
    }
  }, []);

  useEffect(() => {
    const backlogNotifications = backlogChats.reduce((accumulator, chat) => {
      if (
        chat?.notifications &&
        chat?.status &&
        ![ChatStatus.CANCELED, ChatStatus.SUSPEND, ChatStatus.FINISH].includes(
          chat.status
        )
      ) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);
    const mineChatsNotifications = mineChats.reduce((accumulator, chat) => {
      if (
        chat?.notifications &&
        chat?.status &&
        ![ChatStatus.CANCELED, ChatStatus.FINISH].includes(chat.status)
      ) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);

    setBadge(backlogNotifications + mineChatsNotifications);
  }, [backlogChats, mineChats]);

  const GenerateBlob = () => {
    const blob = new Blob([favicon({ notifications: badge })], {
      type: "image/svg+xml;utf8",
    });
    const img = URL.createObjectURL(blob);
    setSrc(img);
  };

  useEffect(() => {
    GenerateBlob();
  }, [badge]);

  const getInfos = async ({ widgetcode }: { widgetcode?: string }) => {
    const response = await instances.instanceWidget.post("", {
      widgetcode: widgetcode || code,
    });
    if (
      typeof response?.status !== "undefined" &&
      [200, 201].includes(response.status)
    ) {
      await dispatch(getSettings({ widget: "widget" }));
    }
  };

  useEffect(() => {
    try {
      window.addEventListener("message", async (event) => {
        if (typeof event.data === "string" && event.data.length > 0) {
          const widgetInfo = event.data.split("|");
          if (widgetInfo.length > 1) {
            if (
              widgetInfo[1] === UserType.AGENT ||
              widgetInfo[1] === UserType.CONTACT
            ) {
              // dispatch(refreshLogout());
              localStorage.clear();
              // console.log(widgetInfo[1]);
              setWidgetType(widgetInfo[1] || UserType.CONTACT);
              const slug = widgetInfo[0];
              if (widgetInfo[1] === UserType.CONTACT) {
                dispatch(refreshLogout());
                updateTenant(slug, "contact");
                dispatch(selectTenant(slug));
                dispatch(selectWidget("contact"));
              } else if (widgetInfo[1] === UserType.AGENT) {
                updateTenant(slug, "agent");
                dispatch(selectTenant(slug));
                dispatch(selectWidget("agent"));
                await dispatch(signin({ tenant: slug }));
              }
            }
            if (widgetInfo[2] && widgetInfo[2].length > 0) {
              setCode(widgetInfo[2]);
              await getInfos({ widgetcode: widgetInfo[2] });
            }
          }
        }
      });
    } catch (error) {
      // console.log(error);
    }
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <Helmet
          defer={false}
          title={`${badge > 0 ? `(${badge})` : ""} ${t("agitalks")}`}
          prioritizeSeoTags
        >
          <meta
            name="description"
            content="Plataforma omnichannel para comunicação interna e atendimento ao cliente"
          />
          <link rel="icon" href={`${src}`} type="image/x-icon" />
        </Helmet>
        {widgetType === UserType.NONE ? <ToastContainer /> : null}
        {widgetType !== UserType.NONE ? (
          <div className="flex flex-col">
            {recentChats.length > 0 &&
            widgetType === UserType.AGENT &&
            toggleOpenClose ? (
              <RecentChatsList />
            ) : null}
            {widgetType === UserType.AGENT ? (
              <WidgetAgentRoute
                disable={disable}
                setDisable={setDisable}
                toggleOpenClose={toggleOpenClose}
                widgetType={widgetType}
              />
            ) : (
              <WidgetContactRoute
                disable={disable}
                setDisable={setDisable}
                toggleOpenClose={toggleOpenClose}
                widgetType={widgetType}
                onClick={() => getInfos({ widgetcode: undefined })}
              />
            )}
            <WidgetButton
              widgetType={widgetType}
              toggleOpenClose={toggleOpenClose}
              onToggleOpenClose={(e) => {
                e.stopPropagation();
                window.parent.postMessage("toggleOpenClose", "*");
                if (user && widgetType === UserType.CONTACT) {
                  dispatch(
                    changeStatus({
                      status: !toggleOpenClose
                        ? UserStatus.ONLINE
                        : UserStatus.OFFLINE,
                      origin: user._id,
                    })
                  );
                }
                if (recentChats.length > 0 && widgetType === UserType.AGENT) {
                  dispatch(clearRecents());
                }
                dispatch(closeWidget(toggleOpenClose));
                setToggleOpenClose((prevState) => !prevState);
              }}
            />
          </div>
        ) : (
          <Routes>
            <Route element={<PublicRoute />}>
              <Route path="/company" element={<LoginTenantPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/login-agidesk" element={<LoginPage />} />
              <Route path="/recovery" element={<RecoveryPage />} />
              <Route path="/password" element={<PasswordPage />} />
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/surveyrating" element={<SurveyratingPage />}>
                <Route
                  path="/surveyrating/:_id"
                  element={<SurveyratingPage />}
                />
              </Route>
              <Route
                path="/schedule/officehours/:tenant"
                element={<PublichoursPage />}
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/*"
                element={
                  <LayoutPage
                    widgetType={widgetType}
                    disable={disable}
                    setDisable={setDisable}
                  />
                }
              />
            </Route>
          </Routes>
        )}
      </Router>
    </HelmetProvider>
  );
};

export default App;
