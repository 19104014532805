import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { formatRelative, format, parseISO } from "date-fns";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Accordion, Sidebar } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import LoadingDots from "../shared/components/LoadingDots.component";
import {
  ICampaignBatchSearchFilter,
  ICampaignStats,
  IFilterProps,
  ITarget,
  getSearchCampaignsBatches,
  reselectCampaign,
  reselectLocalCampaign,
  selectCampaign,
} from "../slices/campaigns.slice";
import TableComponent from "../shared/components/Tables/Table.component";
import DropdownMenu from "../features/messages/components/DropdownMenu.component";
import StatisticCard from "../features/surveys/components/StatisticCard.component";
import { ICardItems } from "../slices/surveys.slice";
import { ptBR } from "date-fns/locale";
import campaignBatchesService from "../services/campaignBatches.service";
import Button from "../shared/components/Buttons/Button.component";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import SidebarComponent from "../shared/components/Sidebars/Sidebar.component";
import SearchbarFilters from "../shared/components/SearchbarFilters.component";
import NotificationBadge from "../features/chats/components/NotificationBadge.component";
import LinkButton from "../shared/components/Buttons/LinkButton.component";
import {
  CampaignBatchStatus,
  ICampaignBatch,
  updateCampaignBatch,
  sendBatch,
} from "../slices/campaignbatches.slice";
import PreviewModal from "../shared/components/Modals/PreviewContentModal.component";
import useCampaigns from "../hooks/features/useCampaigns";
import ConfirmationModal from "../shared/components/Modals/ConfirmationModal.component";
import { getSettings } from "../slices/settings.slice";
import useGetIdPage from "../hooks/useGetIdPage";
import removeDuplicates from "../shared/utils/removeDuplicates";
import { IFiltersDropdownInstances } from "../shared/components/@types/searchBarFilter.types";

const CampaignBatchesPage = ({ toggleSidebar }: { toggleSidebar: boolean }) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { effect } = useCampaigns();
  const { selectedCampaignBatches, selectedCampaignBatchesStats } =
    useAppSelector((state) => state.campaigns);

  const { isLoadingCampaignBatches } = useAppSelector(
    (state) => state.campaignBatches
  );
  const [instancesPerPage, setInstancesPerPage] = useState<number>(5);
  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [keyword, setKeyword] = useState("");
  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [, setReloadForced] = useState<boolean>(true);
  const [activeIndex, setActiveIndex] = useState<number[]>([0]);
  const [isLoadingCampaignsBatches, setIsLoadingCampaignsBatches] =
    useState<boolean>(false);
  const [targetGridResult, setTargetGridResult] = useState<object>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [showCancelBatchModal, setShowCancelBatchModal] =
    useState<boolean>(false);
  const _batch = useRef<ICampaignBatch>();
  const [selectedCampaignBatch, setSelectedCampaignBatch] =
    useState<ICampaignBatch>();

  const defaultCampaignFilters: IFiltersDropdownInstances = {
    content: [],
    period: [],
    status: [],
  };

  const [searchFiltersSelected, setSearchFiltersSelected] =
    useState<IFiltersDropdownInstances>(defaultCampaignFilters);
  const [searchFiltersOptions, setSearchFiltersOptions] =
    useState<IFiltersDropdownInstances>(defaultCampaignFilters);

  interface IProps {
    title: string;
    icon: string;
    color: string;
  }
  interface IStatusProps {
    [key: string]: IProps;
  }

  const statusProps: IStatusProps = {
    SUCCESS: {
      title: "Enviado",
      icon: "las la-check-double",
      color: "text-green",
    },
    PENDING: {
      title: "Pendente",
      icon: "las la-hourglass-end",
      color: "text-agidesk",
    },
    SENDING: {
      title: "Enviando",
      icon: "las la-exclamation",
      color: "text-yellow",
    },
    CANCELED: {
      title: "Cancelado",
      icon: "las la-ban",
      color: "text-red",
    },
  };

  const defaultStatisticItems = [
    // {
    //   title: "Lotes",
    //   icon: "las la-archive",
    //   divider: true,
    //   value: 0,
    //   type: "",
    // },
    {
      title: "Gerados",
      icon: "lar la-paper-plane",
      color: "text-blue-survey",
      value: 0,
      type: "created",
    },
    {
      title: "Enviados",
      icon: "las la-check-double",
      color: "text-green",
      value: 0,
      type: "success",
    },
    {
      title: "Pendentes",
      icon: "las la-hourglass-end",
      color: "text-agidesk",
      value: 0,
      type: "pending",
    },
  ];
  const [statisticItems, setStatisticItems] = useState<ICardItems[]>(
    defaultStatisticItems
  );

  const prepareGridResult = async (_id: string, isOpen?: boolean) => {
    let contentFilter: string = "";
    const searchByContent =
      typeof keyword !== "undefined" && keyword.length > 0 && isOpen;

    if (searchByContent) {
      contentFilter = keyword;
    }

    const payload = await campaignBatchesService.searchBatchTargetInfo({
      id: _id,
      // skip: isOpen ? 0 : skip,
      skip,
      limit,
      filter: contentFilter,
      deleted: showDeleted,
    });
    let current: any[] = [];
    let results: any = {};
    if (typeof payload !== "undefined") {
      setTargetGridResult({
        ...targetGridResult,
        [_id]: {
          results: payload.results,
          count: payload.count,
        },
      });
    } else {
      if (!searchByContent) setActiveIndex([0]);
    }
  };

  const { getId } = useGetIdPage();
  // const getId = useCallback(() => {
  //   if (params && typeof params._id !== "undefined") return params._id;
  //   return location.pathname.split("/").slice(-1)[0];
  // }, []);

  useEffect(() => {
    dispatch(reselectCampaign(null));
    // dispatch(reselectLocalCampaign(null));
    const _id = getId();
    if (location.pathname === `/settings/campaign/${_id}/batches`) {
      (async () => {
        await dispatch(getSettings({}));
        const { payload } = await dispatch(selectCampaign({ _id }));
        if (typeof payload?._id !== "undefined") {
          dispatch(reselectLocalCampaign(payload));
        }
      })();
    }
  }, []);

  const formatFilters = () => {
    const _filters: any = {
      ...searchFiltersSelected,
      campaign: getId(),
    };
    Object.keys(_filters).forEach((key) => {
      const currentValue = JSON.stringify(_filters[key as keyof IFilterProps]);
      if (
        currentValue == "[]" ||
        currentValue === undefined ||
        currentValue == "" ||
        currentValue == '""'
      ) {
        delete _filters[key as keyof IFilterProps];
      }
    });
    return _filters;
  };

  const _cancelBatch = async (_batch: ICampaignBatch) => {
    const { payload } = await dispatch(
      updateCampaignBatch({
        _batch: {
          ..._batch,
          status: CampaignBatchStatus.CANCELED,
        },
      })
    );
    if (typeof payload._id !== "undefined") {
      reloadPageData();
    }
  };

  const _sendBatch = async (_batch: ICampaignBatch) => {
    if (typeof _batch !== "undefined" && typeof _batch?._id !== "undefined") {
      await dispatch(sendBatch({ id: _batch?._id }));
    }
  };

  const reloadPageData = async (keepActive?: boolean) => {
    if (keepActive !== true) setActiveIndex([0]);
    await dispatch(
      getSearchCampaignsBatches({
        skip,
        limit,
        filter: formatFilters(),
        allStatus: true,
        deleted: false,
      })
    );
  };

  useEffect(() => {
    reloadPageData();
  }, [searchFiltersSelected]);

  useEffect(() => {
    const stats = selectedCampaignBatchesStats as ICampaignStats;
    formatStatistics(stats);
    if (
      typeof selectedCampaignBatches != "undefined" &&
      typeof activeIndex !== "undefined" &&
      activeIndex.length > 0
    ) {
      reloadGrid(true);
    }
  }, [selectedCampaignBatchesStats]);

  useEffect(() => {
    reloadGrid(true);
  }, [skip, limit, showDeleted, keyword]);

  useEffect(() => {
    if (reload) {
      reloadPageData(true);
      reloadGrid(true);
      setReload(false);
    }
  }, [reload]);

  // useEffect(() => {
  //   (async () => {
  //     const _id = getId();
  //     dispatch(reselectCampaign(null));
  //     dispatch(reselectLocalCampaign(null));
  //     await dispatch(selectCampaign({ _id }));
  //   })();
  // }, []);
  // useEffect(() => {
  //   effect.template();
  // }, [effect.template]);
  // useEffect(() => {
  //   effect.chat();
  // }, [effect.chat]);

  const reloadGrid = (activeSection?: boolean) => {
    const batch =
      typeof selectedCampaignBatches !== "undefined"
        ? selectedCampaignBatches[+activeIndex]
        : [];
    if (typeof batch?._id !== "undefined")
      prepareGridResult(batch._id, activeSection);
  };

  const formatStatistics = (stats: ICampaignStats) => {
    if (typeof stats !== "undefined") {
      const items =
        typeof statisticItems !== "undefined"
          ? statisticItems
          : defaultStatisticItems;
      const newStats: ICardItems[] = [];
      Object.entries(items).forEach((item) => {
        const _item = item[1];
        const type = _item.type as keyof ICampaignStats;
        const value = stats[type];

        newStats.push({
          ..._item,
          value,
        } as ICardItems);
      });
      setStatisticItems(newStats);
    }
  };

  const countSelectedFilters = () => {
    let selectedFilters = 0;
    if (typeof searchFiltersSelected !== "undefined") {
      for (const _instance of Object.keys(searchFiltersSelected)) {
        const instanceValues =
          searchFiltersSelected[_instance as keyof ICampaignBatchSearchFilter];
        if (
          typeof instanceValues !== "undefined" &&
          instanceValues.length > 0
        ) {
          selectedFilters +=
            typeof instanceValues === "string" ? 1 : instanceValues.length;
        }
      }
    }
    return selectedFilters;
  };

  const showAppliedFilters = () => {
    if (countSelectedFilters() <= 0) {
      return "";
    }
    const showFilters: string[] = [];
    if (
      typeof searchFiltersSelected !== "undefined" &&
      typeof searchFiltersOptions !== "undefined"
    ) {
      for (const instance of Object.keys(searchFiltersSelected)) {
        const instanceValues =
          searchFiltersSelected[instance as keyof IFiltersDropdownInstances] ||
          [];
        const instanceOptions =
          searchFiltersOptions[instance as keyof IFiltersDropdownInstances] ||
          [];

        if (
          typeof instanceValues !== "undefined" &&
          Array.isArray(instanceValues) &&
          instanceValues.length > 0
        ) {
          if (instance == "content") {
            showFilters.push(`${instanceValues.join(", ")}`);
          }
          if (instanceValues.length > 0 && Array.isArray(instanceOptions)) {
            const items = instanceValues.map((_value) => {
              const value = _value;
              const result = instanceOptions.find((item: any) => {
                return item.key === value;
              });
              return result?.text;
            });
            // showFilters.push(`${items.join(", ")}`);
            showFilters.push(`${removeDuplicates(items).join(", ")}`);
          }
        } else if (
          Array.isArray(instanceOptions) &&
          instanceOptions.length > 0
        ) {
          const result = instanceOptions.find(
            (item) => item.key === instanceValues
          );
          if (typeof result?.text !== "undefined") {
            showFilters.push(`${result?.text}`);
          }
        }
      }
    }
    const _filters: string = showFilters.join("; ");
    return (
      <div className="ml-3 text-[12px] text-break">
        {typeof searchFiltersSelected !== "undefined" ? (
          <div className="flex">
            <h2 className="font-semibold mr-2">Filtrando por:</h2>
            <h2>{`${
              _filters.length > 200
                ? _filters.substring(0, 200).concat("...")
                : _filters
            }`}</h2>
          </div>
        ) : null}
        <div className="w-full -ml-2">
          <LinkButton
            label="Limpar"
            leftIcon="las la-eraser -mr-2 ml-1"
            onClick={
              typeof clearFilters !== "undefined" ? clearFilters : () => null
            }
            extraClass="text-[12px]"
          />
        </div>
      </div>
    );
  };

  const buildTargetGrid = (_id: string | undefined) => {
    const current: any =
      typeof targetGridResult !== "undefined"
        ? targetGridResult[_id as keyof typeof targetGridResult]
        : undefined;

    const list =
      typeof current !== "undefined" &&
      typeof current["results"] !== "undefined"
        ? current["results"]
        : ([] as ITarget[]);

    const totalTargets = typeof current !== "undefined" ? current["count"] : 0;

    if (isLoadingCampaignBatches)
      return <LoadingDots className="flex justify-center items-center" />;

    return (
      <>
        <div className="">
          <div className="items-center flex">
            <label>{`Alvos selecionados`}</label>
          </div>
          <TableComponent
            tableId={`campaignbatchtargets-${_id}`}
            skip={skip}
            basicGrid={true}
            keyword={keyword}
            isLoading={isLoadingCampaignsBatches}
            setKeyword={setKeyword}
            setSkip={setSkip}
            activePage={activePage}
            setActivePage={setActivePage}
            instancesPerPage={instancesPerPage}
            setInstancesPerPage={setInstancesPerPage}
            limit={limit}
            setLimit={setLimit}
            list={list}
            total={totalTargets}
            icon={"las la-bullhorn"}
            showDeleted={false}
            setShowDeleted={setShowDeleted}
            setShowModal={setShowModal}
            setReload={setReload}
            reload={reload}
            plus={""}
          />
        </div>
      </>
    );
  };

  const campaignBatchesSection = () => {
    const getSectionOptionsItems = (batch: ICampaignBatch, index?: number) => {
      const options: any[] = [];
      const status = batch.status;
      const actions = {
        resend: {
          title: "Reenviar lote",
          icon: "las la-paper-plane",
          action: async () => {
            await _sendBatch(batch);
          },
        },
        cancel: {
          title: "Cancelar lote",
          icon: "las la-ban",
          action: () => {
            _batch.current = undefined;
            _batch.current = batch;
            setShowCancelBatchModal(true);
          },
          // action: async () => {
          //   await _cancelBatch(batch);
          // },
        },
        preview: {
          title: "Visualizar template",
          icon: "las la-eye",
          action: () => {
            if (typeof index !== "undefined")
              setSelectedCampaignBatch(selectedCampaignBatches[index]);
            setShowPreviewModal(true);
          },
        },
      };

      let statusOptions = ["preview"];
      switch (status) {
        case "PENDING": {
          statusOptions = ["resend", "cancel", "preview"];
          break;
        }
        case "SENDING": {
          statusOptions = ["cancel", "preview"];
          break;
        }
        default:
          statusOptions = ["preview"];
      }

      // (status == "PENDING" || status == "SENDING" )? ["resend", "cancel", "preview"] : ["preview"];
      // if (status == "SUCCESS") return [];

      statusOptions.forEach((item) => {
        const menu = actions[item as keyof typeof actions];
        options.push({
          _id: item,
          icon: <i className={`${menu?.icon} text-[14px] mr-2`} />,
          text: <span className="text-[12px]">{menu?.title}</span>,
          description: null,
          onClick() {
            {
              menu.action();
            }
          },
        });
      });
      return options;
    };

    if (typeof selectedCampaignBatches !== "undefined") {
      const panels: any[] = [];
      const getSectionOptions = (_batch: ICampaignBatch, index: number) => {
        return (
          <div className="grid grid-cols-2 md:grid-cols-2 content-center ">
            <div className=" text-center">
              <DropdownMenu
                id="header-batch-container"
                icon={
                  <i
                    role="presentation"
                    className={`las la-ellipsis-h text-[24px] mr-2 bg-none`}
                    title="Opções do lote"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                    }}
                  />
                }
                items={getSectionOptionsItems(_batch, index)}
              />
            </div>
            <div className=" justify-center text-center">
              <i
                className={`${
                  activeIndex.includes(index)
                    ? "las la-angle-up"
                    : "las la-angle-down"
                }`}
              />
            </div>
          </div>
        );
      };
      Object.keys(selectedCampaignBatches).forEach((index) => {
        const _batch = selectedCampaignBatches[index];
        panels.push({
          key: _batch._id,
          title: {
            accessKey: _batch._id,
            children: (
              <div className="-mt-2 flex justify-between  p-2 rounded-md shadow bg-white cursor-pointer w-full">
                <div className="flex items-center w-full">
                  <i
                    className={`text-[22px] ${
                      statusProps[_batch.status as keyof IStatusProps].color
                    } ${
                      statusProps[_batch.status as keyof IStatusProps].icon
                    } mr-4`}
                  />
                  <div className=" w-3/4 ">
                    <h1 className="font-semibold text-[14px]">
                      {_batch.name}
                      <div className="text-[12px] text-gray-999">
                        {statusProps[_batch.status as keyof IStatusProps].title}
                      </div>
                    </h1>
                  </div>
                  <div>
                    <div className="mt-1 flex items-center">
                      <div className="flex items-center mr-2">
                        <i
                          className={`mr-1 ${statusProps["PENDING"].color} ${statusProps["PENDING"].icon}`}
                        />
                        <h1
                          title={`${_batch.pending} contatos pendentes de envio`}
                        >
                          {_batch.pending}
                        </h1>
                      </div>
                      <div className="flex items-center mr-2">
                        <i
                          className={`mr-1 ${statusProps["SUCCESS"].color} ${statusProps["SUCCESS"].icon}`}
                        />
                        <h1 title={`${_batch.success} contatos enviados`}>
                          {_batch.success}
                        </h1>
                      </div>
                      <div className="flex items-center">
                        <i
                          className={`mr-1 ${statusProps["CANCELED"].color} ${statusProps["CANCELED"].icon}`}
                        />
                        <h1 title={`${_batch.canceled} contatos cancelados`}>
                          {_batch.canceled}
                        </h1>
                      </div>
                    </div>

                    <h1 className="font-semibold  text-agitalks text-[11px]">
                      Criado em: {` `}
                      {typeof _batch.created_at !== "undefined"
                        ? format(
                            parseISO(_batch?.created_at),
                            "dd/MM/yyyy', às' HH:mm",
                            {
                              locale: ptBR,
                            }
                          )
                        : "-"}
                    </h1>
                  </div>
                </div>
                {getSectionOptions(_batch, +index)}
              </div>
            ),
          },
          content: {
            key: `content-${_batch._id}`,
            content: (
              <div className="bg-white -mt-3.5 rounded-md p-5">
                {buildTargetGrid(_batch._id)}
              </div>
            ),
          },
        });
      });

      return (
        <>
          <Accordion
            panels={panels}
            fluid
            exclusive={false}
            activeIndex={activeIndex}
            onTitleClick={async (e, props) => {
              const _id = props.accessKey;
              const _index = props.index as number;
              if (!activeIndex.includes(_index)) {
                const _activeIndex = [_index];
                setKeyword("");
                setSkip(0);
                setLimit(5);
                setActiveIndex(_activeIndex);
                setActivePage(1);
                prepareGridResult(_id, true);
              } else {
                const _activeIndex = activeIndex.filter(
                  (item) => item !== _index
                );
                setActiveIndex(_activeIndex);
              }
            }}
          />
          {showPreviewModal ? (
            <PreviewModal
              type="campaignbatch"
              instance={selectedCampaignBatch}
              setShowPreviewModal={setShowPreviewModal}
            />
          ) : null}
          {showCancelBatchModal &&
          typeof _batch?.current?._id !== "undefined" ? (
            <ConfirmationModal
              disabled={false}
              action={async () => {
                if (typeof _batch.current?._id !== "undefined") {
                  await _cancelBatch(_batch.current);
                  _batch.current = undefined;
                }
                setShowCancelBatchModal(false);
              }}
              setShowModal={setShowCancelBatchModal}
              title="Tem certeza que deseja cancelar o envio do lote?"
            />
          ) : null}
        </>
      );
    }
  };

  if (isLoadingCampaignsBatches || loading)
    return (
      <LoadingDots
        className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
        large
      />
    );

  const buildFilters = () => {
    return (
      <div className="px-4 py-5 flex items-center justify-between md:p-0 w-full">
        <div className="md:flex items-center">
          {setVisible ? (
            <div className="relative">
              <Button
                minWidth
                extraClass="w-[90px] relative mb-3"
                inverted
                icon={showFilter ? "las la-times" : "las la-filter"}
                label={showFilter ? "Fechar" : "Filtros"}
                onClick={() => {
                  if (setVisible && setShowFilter) {
                    setVisible((prevState) => !prevState);
                    setShowFilter((prevState) => !prevState);
                    if (!location.hash.includes("#filters")) {
                      navigate(`${location.pathname}#filters`);
                    } else {
                      navigate(`${location.pathname}`);
                    }
                  }
                }}
              />
              {typeof searchFiltersSelected !== "undefined" &&
              countSelectedFilters() > 0 ? (
                <div className="absolute -right-1 bottom-2">
                  <NotificationBadge
                    title="filtro(s)"
                    badge={countSelectedFilters()}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="ml-4">{showAppliedFilters()}</div>
        </div>
      </div>
    );
  };

  const clearFilters = ({ _instance }: { _instance?: string }) => {
    if (typeof _instance === "undefined") {
      setActiveIndex([0]);
      document
        .querySelectorAll(".dropdown-filters .clear")
        .forEach((el: any) => el.click());
      document
        .querySelectorAll(".dropdown-filters-users .clear")
        .forEach((el: any) => el.click());
      setSearchFiltersSelected(defaultCampaignFilters);
      setSearchFiltersOptions(defaultCampaignFilters);
    } else {
      const _defaultCampaignFilters: any = defaultCampaignFilters;
      document
        .querySelectorAll(".dropdown-filters-users .clear")
        .forEach((el: any) => el.click());
      setSearchFiltersSelected({
        ...searchFiltersSelected,
        [_instance]: _defaultCampaignFilters[_instance],
      });
      setSearchFiltersOptions({
        ...searchFiltersOptions,
        [_instance]: _defaultCampaignFilters[_instance],
      });
    }
  };

  const buildSidebar = () => {
    return (
      <SidebarComponent
        visible={visible}
        setVisible={setVisible}
        setShowFilter={setShowFilter}
        setReloadForced={setReloadForced}
        content={
          <div className="flex-container pl-[5px]">
            <div className="header relative bg-white rounded-md py-8 px-10">
              <h1 className="flex items-center justify-center font-semibold">
                <i className="las la-filter text-3xl" />

                <p className="text-xl ml-3">Filtrar resultados</p>
              </h1>
            </div>
            <SearchbarFilters
              clearFilters={clearFilters}
              searchFiltersOptions={searchFiltersOptions}
              searchFiltersSelected={searchFiltersSelected}
              setSearchFiltersOptions={setSearchFiltersOptions}
              setSearchFiltersSelected={setSearchFiltersSelected}
              setShowFilter={setShowFilter}
              setVisible={setVisible}
              defaultFilters={defaultCampaignFilters}
              searchInstanceName="campaignbatch"
            />
          </div>
        }
      />
    );
  };

  return (
    <Sidebar.Pusher>
      {buildFilters()}
      {buildSidebar()}
      <div
        key="statisticArea"
        className="grid grid-cols-1 md:grid-cols-3 gap-x-1.5 pb-2"
      >
        <StatisticCard items={statisticItems} colspan={3} />
      </div>

      {isLoadingCampaignBatches ? (
        <LoadingDots className="flex justify-center items-center" />
      ) : (
        campaignBatchesSection()
      )}

      <div className="mb-20" />
    </Sidebar.Pusher>
  );
};

export default CampaignBatchesPage;
