import React from "react";
import { Form } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { reselectSettings } from "../../slices/settings.slice";
import {
  reselectExternalappBotmaker,
  reselectExternalappWhatsApp,
} from "../../slices/externalapps.slice";
import useUserIsAdmin from "../../hooks/useUserIsAdmin";

const FinishMessagesSection = ({
  type,
}: {
  type: "settings" | "whatsapp" | "agibot";
}) => {
  const { isAdmin } = useUserIsAdmin();
  const dispatch = useAppDispatch();
  const { selectedSettings } = useAppSelector((state) => state.settings);
  const { selectedExternalAppBotmaker, selectedExternalAppWhatsApp } =
    useAppSelector((state) => state.externalapps);

  const _fields = {
    settings: {
      agentValue: selectedSettings?.finishmessages?.agent || "",
      contactValue: selectedSettings?.finishmessages?.contact || "",
      inactivityValue: selectedSettings?.finishmessages?.inactivity || "",
    },
    whatsapp: {
      agentValue:
        selectedExternalAppWhatsApp?.fields?.finishmessages?.agent || "",
      contactValue:
        selectedExternalAppWhatsApp?.fields?.finishmessages?.contact || "",
      inactivityValue:
        selectedExternalAppWhatsApp?.fields?.finishmessages?.inactivity || "",
    },
    agibot: {
      agentValue:
        selectedExternalAppBotmaker?.fields?.finishmessages?.agent || "",
      contactValue:
        selectedExternalAppBotmaker?.fields?.finishmessages?.contact || "",
      inactivityValue:
        selectedExternalAppBotmaker?.fields?.finishmessages?.inactivity || "",
    },
  };

  const changeValue = ({
    value,
    field,
  }: {
    value: string;
    field: "agent" | "contact" | "inactivity";
  }) => {
    switch (type) {
      case "settings":
        dispatch(
          reselectSettings({
            ...selectedSettings,
            finishmessages: {
              ...selectedSettings?.finishmessages,
              [field]: value,
            },
          })
        );
        break;
      case "agibot":
        dispatch(
          reselectExternalappBotmaker({
            ...selectedExternalAppBotmaker,
            fields: {
              ...selectedExternalAppBotmaker.fields,
              finishmessages: {
                ...selectedExternalAppBotmaker.fields?.finishmessages,
                [field]: value,
              },
            },
          })
        );
        break;
      case "whatsapp":
        dispatch(
          reselectExternalappWhatsApp({
            ...selectedExternalAppWhatsApp,
            fields: {
              ...selectedExternalAppWhatsApp.fields,
              finishmessages: {
                ...selectedExternalAppWhatsApp.fields?.finishmessages,
                [field]: value,
              },
            },
          })
        );
        break;
      default:
        break;
    }
  };

  return (
    <Form>
      <Form.Group widths="equal">
        <Form.TextArea
          readOnly={!isAdmin}
          style={{ resize: "none" }}
          label="Mensagem de encerramento pelo agente"
          placeholder="Exemplo: O agente encerrou a conversa"
          type="text"
          defaultValue={_fields[type].agentValue}
          onChange={(e, { value }) => {
            if (typeof value === "string") {
              changeValue({ value, field: "agent" });
            }
          }}
        />
      </Form.Group>
      {/* <Form.Group widths="equal">
        <Form.TextArea
          readOnly={!isAdmin}
          style={{ resize: "none" }}
          label="Mensagem de encerramento pelo contato"
          placeholder="Exemplo: O contato encerrou a conversa"
          type="text"
          defaultValue={_fields[type].contactValue}
          onChange={(e, { value }) => {
            if (typeof value === "string") {
              changeValue({ value, field: "contact" });
            }
          }}
        />
      </Form.Group> */}
      <Form.Group widths="equal">
        <Form.TextArea
          readOnly={!isAdmin}
          style={{ resize: "none" }}
          label="Mensagem de encerramento por inatividade"
          placeholder="Exemplo: Sua conversa foi encerrada por atingir o tempo de inatividade"
          type="text"
          defaultValue={_fields[type].inactivityValue}
          onChange={(e, { value }) => {
            if (typeof value === "string") {
              changeValue({ value, field: "inactivity" });
            }
          }}
        />
      </Form.Group>
    </Form>
  );
};

export default FinishMessagesSection;
