/* eslint-disable import/no-duplicates */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { formatRelative, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import Message from "./Message.component";
import LoadingDots from "../../../shared/components/LoadingDots.component";
import { IMessage, MessageType } from "../../../slices/messages.slice";
import useSenderColor from "../../../hooks/useSenderColor";
import { MessagesListProps } from "../models/MessagesListProps.type";
import { RoleUser, UserType } from "../../../slices/users.slice";
import Toggle from "../../../shared/components/Toggle.component";
import {
  addToRecents,
  ChatStatus,
  ChatType,
  checkingMessages,
  closeChat,
  createChat,
  IChat,
  openChat,
  removeFromRecents,
  selectChat,
} from "../../../slices/chats.slice";
import findInObject from "../../../shared/utils/findInObject";
import Button from "../../../shared/components/Buttons/Button.component";
import { ResendTemplateModal } from "./ResendTemplate";
import useResendTemplate from "./ResendTemplate/controller";
import ConfirmationModal from "../../../shared/components/Modals/ConfirmationModal.component";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

const MessagesList = ({
  messages,
  isTyping,
  isRecording,
  setIsReplying,
  setMessageReplied,
  keyword,
  setKeyword,
  messageSent,
  setMessageSent,
  listRef,
  endRef,
  widgetType,
  // limit,
  skip,
  historyMode,
  isLoading,
  isLoadingMessages,
  isSending,
}: MessagesListProps) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { selectedChat, checkingChatMessages, scrolling, mineChats } =
    useAppSelector((state) => state.chats);
  const { isSuccess } = useAppSelector((state) => state.messages);

  const limitRef = useRef<null | HTMLLIElement>(null);
  const loadingRef = useRef<boolean>(false);
  const [goReply, setGoReply] = useState<boolean>(false);
  const [parent, setParent] = useState<IMessage | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showBusyContactModal, setShowBusyContactModal] = useState(false);
  const [alertUser, setAlertUser] = useState<{
    user: { _id: string; name: string };
    team: string;
  }>({
    user: { _id: "", name: "" },
    team: "",
  });

  const navigate = useNavigate();

  const { chatTemplate, checkActiveChatAndNavigate } = useResendTemplate();

  const senderColor = useRef<string>("");
  const lastSenderId = useRef<string>("");

  const contactBusyMessage = `${t("modal.busy-contact.message.firstline")} <b>${
    alertUser.user.name
  }</b> ${t("modal.busy-contact.message.secondline")} <b>${
    alertUser.team
  }</b>.\n\n${t("modal.busy-contact.message.lastline")}`;

  const scrollToBottom = useCallback(() => {
    if ((messages && messages.length > 0) || keyword.length >= 0) {
      if (messageSent || isSending || skip === 0) {
        endRef.current?.scrollIntoView({});
        setMessageSent(false);
      } else if (loadingRef.current === true) {
        limitRef.current?.scrollIntoView({ behavior: "smooth" });
        loadingRef.current = false;
      } else if (!scrolling) {
        endRef.current?.scrollIntoView({});
      }
    }
  }, [messages?.length, selectedChat?._id, keyword.length]);

  async function getActionFromReply(_userId: string, payload: any) {
    const returnChat =
      payload && payload?._id && typeof payload?.status !== "undefined";
    const returnBusy =
      payload &&
      payload?.user &&
      payload?.team &&
      typeof payload?.status === "undefined";

    if (returnChat) {
      if (
        [
          ChatStatus.ACTIVE,
          ChatStatus.WAIT,
          ChatStatus.WAITCAMPAIGN,
          ChatStatus.WAITREPLY,
        ].includes(payload.status)
      ) {
        const _users = payload?.users || null;
        if (_users) {
          dispatch(removeFromRecents(payload));
          if (selectedChat?._id && widgetType !== UserType.CONTACT) {
            dispatch(addToRecents(selectedChat));
            dispatch(closeChat());
          }
          navigate(`/chat/${payload._id}`);
          const _action = await dispatch(selectChat({ _id: payload._id }));
          if (_action.payload) {
            dispatch(openChat(_action.payload));
          }
        }
      } else {
        handleClick({ uid: _userId, type: ChatType.WHATSAPP });
      }
      return undefined;
    }
    if (returnBusy) {
      setAlertUser({
        team: payload?.team?.name,
        user: {
          _id: payload?.user?._id,
          name: payload?.user?.name,
        },
      });
      setShowBusyContactModal(true);
      return true;
    }

    return false;
  }

  async function handleClick({ uid, type }: { uid: string; type?: string }) {
    try {
      let chat = null;
      if (!type) {
        if (mineChats.length > 0) {
          chat = mineChats.filter((_chat: IChat) => {
            const _found = _chat?.users?.filter(
              (_user) =>
                _user._id === uid && !_user.roles?.includes(RoleUser.CONTACT)
            );
            if (_found && _found.length > 0) {
              return _chat;
            }
            return null;
          });
        }
        if (chat && chat.length === 1) {
          dispatch(removeFromRecents(chat[0]));
          navigate(`/chat/${chat[0]._id}`);
        }
      }

      const { payload } = await dispatch(
        createChat({
          users: [uid, user?._id || ""],
          status: ChatStatus.ACTIVE,
          type: ChatType.INTERNAL,
          externalapp: undefined,
        })
      );
      if (showBusyContactModal) setShowBusyContactModal(false);
      const _chat = payload as IChat;
      navigate(`/chat/${_chat._id}`);
    } catch (error) {
      console.error(error);
    }
  }

  const getDaysSince = (dateString: string): number => {
    const now = Date.now();
    const messageDate = new Date(dateString).getTime();
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    return (now - messageDate) / millisecondsPerDay;
  };

  function calculateMessageTimeIsLowerThanOneDay(
    message: IMessage | undefined
  ) {
    if (!message) return true;

    const messageTime = getDaysSince(message.created_at!) < 1;

    return messageTime;
  }

  const handleResendTemplate = useCallback(async () => {
    const hasChat = await checkActiveChatAndNavigate();

    if (!hasChat) {
      setIsOpen(true);
    } else {
      getActionFromReply(hasChat.id, hasChat.payload);
    }
  }, [checkActiveChatAndNavigate]);

  const shouldDisableReSend = useMemo(
    () => calculateMessageTimeIsLowerThanOneDay(selectedChat?.lastmessage),
    [selectedChat]
  );

  const iconTitle = shouldDisableReSend
    ? "O template só pode ser reenviado após 24h"
    : "Reenviar template";

  useEffect(() => {
    if (messageSent || isSending) {
      endRef.current?.scrollIntoView({});
    }
  }, [messageSent, isSending]);

  useEffect(() => {
    if (isLoadingMessages) {
      loadingRef.current = true;
    }
  }, [isLoadingMessages]);

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  useEffect(() => {
    if (goReply) {
      document
        .getElementById(parent?._id || "")
        ?.scrollIntoView({ behavior: "smooth" });
      setGoReply(false);
      setParent(null);
    }
  }, [goReply, parent]);

  let _lastDay = "";
  let showAlready = true;
  const formatRelativeLocale = (token: string) => {
    switch (token) {
      case "lastWeek":
        return "eeee";
      case "yesterday":
        return "'ONTEM'";
      case "today":
        return "'HOJE'";
      default:
        return "dd/MM/yyyy";
    }
  };

  const locale = {
    ...ptBR,
    formatRelative: (token: string) => formatRelativeLocale(token),
  };

  return (
    <ul id="message-list">
      <li ref={listRef}>
        {" "}
        {isLoadingMessages ? (
          <LoadingDots className="flex justify-center items-center my-3" />
        ) : null}
      </li>
      {isLoading && !isSuccess ? (
        <LoadingDots className="flex justify-center items-center" />
      ) : (
        messages?.map((item, index) => {
          if (
            (item?.type === MessageType.CONTACT &&
              [UserType.AGENT, UserType.NONE].includes(widgetType)) ||
            (item?.type === MessageType.USER && widgetType === UserType.CONTACT)
          )
            return null;
          if (
            typeof item?.sender?._id !== "undefined" &&
            item?.sender?._id &&
            lastSenderId.current !== item?.sender?._id &&
            typeof selectedChat?._id !== "undefined" &&
            typeof item.sender?._id !== "undefined"
          ) {
            senderColor.current = useSenderColor(
              item.sender._id + selectedChat._id
            );
            lastSenderId.current = item?.sender?._id || "";
          }

          const currentDay =
            typeof item?.created_at !== "undefined"
              ? formatRelative(parseISO(item?.created_at), new Date(), {
                  locale,
                })
              : "";

          if (_lastDay !== currentDay && currentDay.length > 0) {
            _lastDay = currentDay;
            showAlready = true;
          } else {
            showAlready = false;
          }

          const _checked =
            typeof findInObject(
              checkingChatMessages.selectedMessages,
              "_id",
              item._id
            ) !== "undefined";

          return (
            <li
              ref={index === 3 ? limitRef : null}
              key={item._id}
              id={item._id}
              role="presentation"
              className={`${
                keyword.length > 0 ? "cursor-pointer" : "cursor-auto"
              }`}
              onClick={() => {
                if (keyword.length > 0) setKeyword("");
                setTimeout(() => {
                  document
                    .getElementById(item._id)
                    ?.scrollIntoView({ behavior: "smooth" });
                }, 0);
              }}
            >
              {selectedChat?.notifications &&
              selectedChat?.firstnotread?._id &&
              selectedChat?.firstnotread?._id === item._id ? (
                <div
                  // ref={notifyRef}
                  className="self-center items-center justify-center flex h-[35px] bg-black-alpha15 border-1 border-gray-ddd rounded-[25px]"
                >
                  <span className="text-[12px]">
                    {selectedChat.notifications}{" "}
                    {selectedChat.notifications > 1
                      ? "NOVAS MENSAGENS"
                      : "NOVA MENSAGEM"}
                  </span>
                </div>
              ) : null}
              {showAlready ? (
                <div
                  id="date-tag"
                  className="flex items-center justify-center sticky z-10 top-0"
                >
                  <div className="bg-white border p-[8px] rounded-[10px] shadow-sm max-w-fit">
                    {currentDay.toUpperCase()}
                  </div>
                </div>
              ) : null}
              <div
                className={`px-4 ${_checked ? "bg-[#E5E7EB] rounded-md" : ""} ${
                  typeof item?.sender?._id !== "undefined" && !item?.internal
                    ? "flex"
                    : ""
                } `}
              >
                {checkingChatMessages.checking && !item?.internal ? (
                  <Toggle
                    containerClass="flex justify-start"
                    mainClass="mr-6"
                    checked={_checked}
                    simple
                    onChange={(_, { checked }) => {
                      let _messages = [
                        ...checkingChatMessages.selectedMessages,
                      ];
                      if (checked === true) {
                        _messages.push(item);
                      } else {
                        _messages = _messages.filter(
                          (_msg) => _msg._id !== item._id
                        );
                      }
                      dispatch(
                        checkingMessages({
                          ...checkingChatMessages,
                          selectedMessages: _messages,
                        })
                      );
                      // chat.onChange({ checked: checked || false });
                    }}
                    labelClass=""
                    label=""
                  />
                ) : null}
                <Message
                  key={item._id}
                  sender={{ ...item?.sender, color: senderColor.current }}
                  message={item}
                  dir={item?.sender?._id === user?._id ? 1 : 0}
                  setIsReplying={setIsReplying}
                  setMessageReplied={setMessageReplied}
                  setGoReply={setGoReply}
                  setParent={setParent}
                  internal={item?.internal || false}
                />
              </div>
            </li>
          );
        })
      )}
      {(isTyping || isRecording) && !historyMode ? (
        <div>
          <LoadingDots className="w-[5.2em] h-[2.2em] relative p-[10px] mt-[8px] border-[2px] border-agitalks rounded-[20px]" />
        </div>
      ) : null}

      <div ref={endRef} />

      {chatTemplate && (
        <>
          <Button
            minWidth
            disabled={shouldDisableReSend}
            extraClass="w-fit mx-auto mt-4 absolute right-0 left-0 bg-transparent text-[#4183c4] border-[#4183C4] 
              hover:bg-transparent hover:text-[#4F9BE5] hover:border-[#4F9BE5] transition-all duration-300"
            label="Reenviar template"
            onClick={handleResendTemplate}
            icon="las la-sync"
            title={iconTitle}
          />
          <div className="h-16" />

          <ResendTemplateModal isOpen={isOpen} closeModal={setIsOpen} />
        </>
      )}

      {showBusyContactModal ? (
        <ConfirmationModal
          widgetType={widgetType}
          disabled={false}
          title={t("modal.busy-contact.title")}
          submitLabel="Conversar com o agente"
          submitIcon="las la-comments"
          content={contactBusyMessage}
          setShowModal={setShowBusyContactModal}
          action={() => handleClick({ uid: alertUser.user._id })}
          cancelAction={() => {
            setShowBusyContactModal(false);
          }}
        />
      ) : null}
    </ul>
  );
};

export default MessagesList;
