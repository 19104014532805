/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-children-prop */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-restricted-syntax */
import React, { ChangeEvent, useState } from "react";
// import Draggable from "react-draggable";
import { DropdownSearchInputProps, Form } from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import {
  ChatRoutingType,
  ITeam,
  createTeam,
  reselectTeam,
  updateTeam,
  updateTeamUsers,
} from "../../../slices/teams.slice";
import {
  createCustomer,
  reselectCustomer,
  updateCustomer,
  updateCustomerContacts,
} from "../../../slices/customers.slice";
import {
  createContact,
  createUser,
  selectUser,
  updateUser,
  IUser,
  RoleUser,
} from "../../../slices/users.slice";
import { Files } from "../../models/interfaces/file.interface";
import ActionButtonsModal from "./ActionButtonsModal.component";
import {
  changePassword,
  update,
  uploadAvatar,
  userdata,
} from "../../../slices/auth.slice";
import LoadingDots from "../LoadingDots.component";
// import PhoneMaskInput from "../PhoneMaskInput.component";
import instances from "../../../services/instances";
import userImage from "../../../assets/images/user.png";
import noImage from "../../../assets/images/noimage.png";
import {
  reselectSettings,
  updateSettings,
} from "../../../slices/settings.slice";
import useInput from "../../../hooks/useInput";
import validatePassword from "../../utils/validation/password";
import validateEmail from "../../utils/validation/email";
import { validateNameLength } from "../../utils/validation/length";
import Toggle from "../Toggle.component";
import removeDuplicates from "../../utils/removeDuplicates";
import StrongPasswordChecklist from "../StrongPasswordChecklist.component";
import ErrorLabel from "../../../features/auth/components/Forms/ErrorLabel.component";
import {
  ExternalAppType,
  IExternalAppBotmaker,
  IExternalAppWhatsApp,
  createExternalapps,
  selectExternalapp,
  updateExternalapps,
} from "../../../slices/externalapps.slice";
import logoonly from "../../../assets/images/logo-only-gray.png";
import {
  createOfficehour,
  reselectOfficehour,
  updateOfficehour,
} from "../../../slices/officehours.slice";
import { createHoliday, reselectHoliday } from "../../../slices/holidays.slice";
import {
  CampaignTypesDropdown,
  DaysDropdown,
  MonthsDropdown,
  WeekdaysDropdown,
  YearsDropdown,
} from "../Dropdowns";
import parseBlurTime from "../../utils/parseBlurTime";
import Dropdown from "../Dropdown.component";
import { externalappsService, usersService } from "../../../services";
import { ddOptions } from "../../models/interfaces/dropdownOptions.interface";
import PopupComponent from "../Popup.component";
import {
  ChatAdmin,
  ChatGroupRole,
  ChatStatus,
  ChatType,
  IChat,
  changeChat,
  closeChat,
  createChat,
  reselectChat,
} from "../../../slices/chats.slice";
import { IMessage, InternalMessageType } from "../../../slices/messages.slice";
import useSendInternalMessage from "../../../hooks/useSendInternalMessage";
import {
  createOfflinemessage,
  reselectOfflinemessage,
} from "../../../slices/offlinemessages.slice";
import { createSurvey, reselectSurvey } from "../../../slices/surveys.slice";
import useUserIsAdmin from "../../../hooks/useUserIsAdmin";
import {
  CampaignType,
  INewCampaign,
  createCampaign,
  reselectCampaign,
} from "../../../slices/campaigns.slice";
import {
  createCommenttemplate,
  reselectCommenttemplate,
} from "../../../slices/commenttemplates.slice";
import customToast from "../../utils/customToast";
import { IToastType } from "../../models/types/Toast.type";
import ShowHideButton from "../Buttons/ShowHideButton.component";
import { TagType, createTag, reselectTag } from "../../../slices/tags.slice";
import TagTypeDropdown from "../Dropdowns/TagTypes.dropdown.component";
import useLGPD from "../../../hooks/useLGPD";
import PhoneFlagInput from "../PhoneFlagInput.component";
import {
  handleMaskRegex,
  validNumberLength,
} from "../../utils/handleMaskLength";

const CreateModal = ({
  modal,
  profile,
  setShowModal,
  setVisible,
  setChangePass,
  title,
  icon,
  showFooter,
  setReloadForced,
  officehoursName,
  setOfficehoursName,
  officehoursStartTime,
  setOfficehoursStartTime,
  officehoursFinishTime,
  setOfficehoursFinishTime,
  setShowUsersList,
}: {
  modal?: boolean;
  profile?: boolean;
  showFooter?: boolean;
  title: string;
  icon: string;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setChangePass?: React.Dispatch<React.SetStateAction<boolean>>;
  setReloadForced?: React.Dispatch<React.SetStateAction<boolean>>;
  officehoursName?: string;
  setOfficehoursName?: React.Dispatch<React.SetStateAction<string>>;
  officehoursStartTime?: string;
  setOfficehoursStartTime?: React.Dispatch<React.SetStateAction<string>>;
  officehoursFinishTime?: string;
  setOfficehoursFinishTime?: React.Dispatch<React.SetStateAction<string>>;
  setShowUsersList?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const { isAdmin } = useUserIsAdmin();
  const { showCode } = useLGPD();
  const { sendInternalMessage } = useSendInternalMessage();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedUser, selectedContact } = useAppSelector(
    (state) => state.users
  );
  const { selectedChat } = useAppSelector((state) => state.chats);

  const { selectedExternalAppWhatsApp } = useAppSelector(
    (state) => state.externalapps
  );

  const { selectedExternalAppBotmaker } = useAppSelector(
    (state) => state.externalapps
  );

  // const { selectedExternalAppVtex } = useAppSelector(
  //   (state) => state.externalapps
  // );

  const { tenantID, user, isUploading } = useAppSelector((state) => state.auth);
  const { selectedTeam } = useAppSelector((state) => state.teams);
  const { selectedCustomer } = useAppSelector((state) => state.customers);
  const { selectedSettings } = useAppSelector((state) => state.settings);
  const { selectedOfficehour } = useAppSelector((state) => state.officehours);

  const [previewAvatar, setPreviewAvatar] = useState<string>(userImage);
  const [previewImage, setPreviewImage] = useState<string>(noImage);
  const [sendAvatar, setSendAvatar] = useState<File>();
  // const [tagType, setTagType] = useState<TagType>(TagType.CHAT);
  const [holidayDay, setHolidayDay] = useState<string>("-1");
  const [holidayMonth, setHolidayMonth] = useState<string>("-1");
  const [holidayYear, setHolidayYear] = useState<string>("-1");
  const [contactPhone, setContactPhone] = useState<string>("");
  const [contactCode, setContactCode] = useState<string>("");
  const [sendinvite, setSendinvite] = useState<boolean>(true);
  const [userPhone, setUserPhone] = useState<string>("");
  const [showHide, setShowHide] = useState<{
    pass: boolean;
    confirmpass: boolean;
    newuser: boolean;
  }>({
    pass: false,
    confirmpass: false,
    newuser: false,
  });

  const [disable, setDisable] = useState(false);

  const [groupUsers, setGroupUsers] = useState<string[]>([]);
  const [instanceOptions, setInstanceOptions] = useState<ddOptions[]>([]);
  const [focusId, setFocusId] = useState<string>("");
  const [campaignFields, setCampaignFields] = useState<INewCampaign>({
    name: "",
    type: CampaignType.WHATSAPP,
    externalapp: "",
    // maxsend: 0,
  });
  const [isLoadingDropdown, setIsLoadingDropdown] = useState(false);

  const handleShowHide = (
    e: React.MouseEvent<Element, MouseEvent>,
    type: "pass" | "confirmpass" | "newuser"
  ) => {
    e.preventDefault();
    setShowHide((prevState) => ({
      pass: type === "pass" ? !prevState.pass : prevState.pass,
      confirmpass:
        type === "confirmpass" ? !prevState.confirmpass : prevState.confirmpass,
      newuser: type === "newuser" ? !prevState.newuser : prevState.newuser,
    }));
  };

  const {
    text: password,
    shouldDisplayError: passwordHasError,
    textChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    // clearHandler: passwordClearHandler,
  } = useInput(validatePassword);

  const {
    text: confirmPassword,
    shouldDisplayError: confirmPasswordHasError,
    textChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
    // clearHandler: confirmPasswordClearHandler,
  } = useInput(validatePassword);

  const {
    text: email,
    shouldDisplayError: emailHasError,
    emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    // clearHandler: emailClearHandler,
  } = useInput(validateEmail);

  const {
    text: name,
    shouldDisplayError: nameHasError,
    textChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    // clearHandler: nameClearHandler,
  } = useInput(validateNameLength);

  const handleChange = async () => {
    if (sendAvatar) {
      const { payload } = await dispatch(uploadAvatar([sendAvatar]));
      if (payload && typeof payload !== "string") {
        const createdFile = payload as Files;
        return createdFile[0]?._id || "";
      }
      return "";
    }
    return "";
  };

  const getTenant = () => {
    if (
      typeof user?.tenant !== "undefined" &&
      user?.tenant !== null &&
      user?.tenant.length > 0
    ) {
      return user?.tenant;
    }
    if (
      typeof tenantID !== "undefined" &&
      tenantID !== null &&
      tenantID.length > 0
    ) {
      return tenantID;
    }
    return undefined;
  };

  const updatingTeam = async (id: string) => {
    if (selectedTeam) {
      const _users = await instances.instanceUsers.get(`/team/${id}`);
      if (selectedTeam.chatusers) {
        const _chatusers: string[] = [];
        for (const _chatuser of selectedTeam.chatusers) {
          _chatusers.push(_chatuser.user);
        }
        const intersect = _users.data.filter((_user: IUser) =>
          _chatusers.some((_cuser: string) => _user._id === _cuser)
        );
        const _newChatUsers = intersect.map((item: IUser) => {
          return {
            user: item._id,
          };
        });
        const _team = {
          _id: selectedTeam?._id,
          active: selectedTeam?.active,
          officehour: selectedTeam?.officehour?._id,
          survey: selectedTeam?.survey?._id,
          assignedbacklog: selectedTeam?.assignedbacklog,
          assignedtype: selectedTeam?.assignedtype,
          backlogsize: selectedTeam?.backlogsize,
          chatlimit: selectedTeam?.chatlimit,
          chatlimitsize: selectedTeam?.chatlimitsize,
          chatusers: _newChatUsers,
          name: selectedTeam?.name,
          routingtype: ChatRoutingType.ROUNDROBIN,
          // routingtype: selectedTeam?.routingtype,
          // reassignment: selectedTeam?.reassignment,
        };
        const { payload } = await dispatch(
          updateTeam({ _team, noToast: true })
        );
        dispatch(
          reselectTeam({
            ...selectedTeam,
            users: _users.data,
            chatusers: _newChatUsers,
            updated_at: payload.updated_at,
            updated_by: payload.updated_by,
          })
        );
      }
    }
  };

  const updatingCustomer = async (id: string) => {
    if (selectedCustomer) {
      const _customerContacts = await instances.instanceUsers.get(
        `/customer/${id}`
      );
      const _customer = {
        _id: selectedCustomer?._id,
        name: selectedCustomer?.name,
        active: selectedCustomer?.active,
        fullname: selectedCustomer?.fullname,
        code: selectedCustomer?.code,
        reference: selectedCustomer?.reference,
      };
      const { payload } = await dispatch(
        updateCustomer({ _customer, noToast: true })
      );
      dispatch(
        reselectCustomer({
          ...selectedCustomer,
          contacts: _customerContacts.data,
          updated_at: payload.updated_at,
          updated_by: payload.updated_by,
        })
      );
    }
  };

  const dropdownResults = {
    agidesk: async () => {
      if (!nameHasError && name.length > 2) {
        const { payload } = await dispatch(
          createExternalapps({
            type: ExternalAppType.AGIDESK,
            fields: {
              name,
            },
          })
        );
        if (modal && typeof payload?._id !== "undefined") {
          setDisable(false);
          setShowModal(false);
          navigate(`/settings/integration/agidesk/${payload?._id}`);
        } else {
          setDisable(false);
          if (setVisible) setVisible(false);
        }
      }
    },
    agibot: async () => {
      if (!nameHasError && name.length > 2) {
        const { payload } = await dispatch(
          createExternalapps({
            type: ExternalAppType.BOTMAKER,
            fields: {
              name,
            },
          })
        );
        setDisable(false);
        if (modal && typeof payload?._id !== "undefined") {
          setShowModal(false);
          navigate(`/settings/integration/agibot/${payload?._id}`);
        } else if (setVisible) setVisible(false);
      }
    },
    vtex: async () => {
      if (!nameHasError && name.length > 2) {
        const { payload } = await dispatch(
          createExternalapps({
            type: ExternalAppType.VTEX,
            fields: {
              name,
            },
          })
        );
        setDisable(false);
        if (modal && typeof payload?._id !== "undefined") {
          setShowModal(false);
          navigate(`/settings/integration/vtex/${payload?._id}`);
        } else if (setVisible) setVisible(false);
      }
    },
    whatsapp: async () => {
      if (!nameHasError && name.length > 2) {
        const { payload } = await dispatch(
          createExternalapps({
            type: ExternalAppType.WHATSAPP,
            fields: {
              name,
            },
          })
        );
        if (modal && typeof payload?._id !== "undefined") {
          setDisable(false);
          setShowModal(false);
          navigate(`/settings/integration/whatsapp/${payload?._id}`);
        } else {
          setDisable(false);
          if (setVisible) setVisible(false);
        }
      }
    },
    password: async () => {
      const _tenant = getTenant();
      if (
        user &&
        user.email &&
        typeof _tenant !== "undefined" &&
        _tenant !== null &&
        _tenant !== null &&
        !passwordHasError &&
        !confirmPasswordHasError &&
        password === confirmPassword
      ) {
        await dispatch(
          changePassword({
            tenant: _tenant,
            email: user?.email,
            password,
          })
        );
        setDisable(false);
        if (setVisible) setVisible(false);
        if (setChangePass) setChangePass(false);
      }
    },
    holiday: async () => {
      if (name.length > 2) {
        const { payload } = await dispatch(
          createHoliday({
            name,
            day: holidayDay,
            month: holidayMonth,
            year: holidayYear,
            teams: [],
            active: true,
            deleted: false,
          })
        );
        if (modal && typeof payload?._id !== "undefined") {
          setDisable(false);
          setShowModal(false);
          navigate(`/settings/holiday/${payload?._id}`);
        } else {
          setDisable(false);
          if (setVisible) setVisible(false);
          dispatch(reselectHoliday(null));
        }
      }
    },
    chattag: async () => {
      if (name.length > 2) {
        const { payload } = await dispatch(
          createTag({
            name,
            type: TagType.CHAT,
          })
        );
        if (modal && typeof payload?._id !== "undefined") {
          setDisable(false);
          setShowModal(false);
          navigate(`/settings/tags/chattag/${payload?._id}`);
        } else {
          setDisable(false);
          if (setVisible) setVisible(false);
          dispatch(reselectTag(null));
        }
      }
    },
    contacttag: async () => {
      if (name.length > 2) {
        const { payload } = await dispatch(
          createTag({
            name,
            type: TagType.CONTACT,
          })
        );
        if (modal && typeof payload?._id !== "undefined") {
          setDisable(false);
          setShowModal(false);
          navigate(`/settings/tags/contacttag/${payload?._id}`);
        } else {
          setDisable(false);
          if (setVisible) setVisible(false);
          dispatch(reselectTag(null));
        }
      }
    },
    survey: async () => {
      if (name.length > 2) {
        const { payload } = await dispatch(
          createSurvey({
            name,
            team: [],
            active: true,
            deleted: false,
            content: "",
          })
        );
        if (modal && typeof payload?._id !== "undefined") {
          setDisable(false);
          setShowModal(false);
          navigate(`/settings/survey/${payload?._id}`);
        } else {
          setDisable(false);
          if (setVisible) setVisible(false);
          dispatch(reselectSurvey(null));
        }
      }
    },
    commenttemplates: async () => {
      if (name.length > 2) {
        const { payload } = await dispatch(
          createCommenttemplate({
            name,
            team: [],
            active: true,
            deleted: false,
            content: "",
          })
        );
        if (modal && typeof payload?._id !== "undefined") {
          setDisable(false);
          setShowModal(false);
          navigate(`/settings/commenttemplate/${payload?._id}`);
        } else {
          setDisable(false);
          if (setVisible) setVisible(false);
          dispatch(reselectCommenttemplate(null));
        }
      }
    },
    campaign: async () => {
      if (
        campaignFields.name.length > 2 &&
        campaignFields.externalapp.length > 0 &&
        // campaignFields.maxsend > 0 &&
        campaignFields.type === CampaignType.WHATSAPP
      ) {
        const { payload } = await dispatch(
          createCampaign({
            name: campaignFields.name,
            externalapp: campaignFields.externalapp.includes("|%@g1%|")
              ? campaignFields.externalapp.split("|%@g1%|")[0]
              : campaignFields.externalapp,
            type: campaignFields.type,
            externalappnumber: campaignFields.externalapp.includes("|%@g1%|")
              ? campaignFields.externalapp.split("|%@g1%|")[1]
              : "",
            // maxsend: campaignFields.maxsend,
          })
        );
        if (modal && typeof payload?._id !== "undefined") {
          setDisable(false);
          setShowModal(false);
          navigate(`/settings/campaign/${payload?._id}`);
        } else {
          setDisable(false);
          if (setVisible) setVisible(false);
          dispatch(reselectCampaign(null));
        }
      }
    },
    offlinemessage: async () => {
      if (!nameHasError && name.length > 2) {
        const { payload } = await dispatch(createOfflinemessage({ name }));
        if (modal && typeof payload?._id !== "undefined") {
          setDisable(false);
          setShowModal(false);
          navigate(`/settings/offlinemessage/${payload?._id}`);
        } else {
          setDisable(false);
          if (setVisible) setVisible(false);
          dispatch(reselectOfflinemessage(null));
        }
      }
    },
    officehours: async () => {
      if (!nameHasError && name.length > 2) {
        const { payload } = await dispatch(createOfficehour({ name }));
        if (modal && typeof payload?._id !== "undefined") {
          setDisable(false);
          setShowModal(false);
          navigate(`/settings/officehour/${payload?._id}`);
        } else {
          setDisable(false);
          if (setVisible) setVisible(false);
        }
      }
    },
    officehour: async () => {
      const _officehours: any = selectedOfficehour?.officehours;
      const _newOfficehours = [
        {
          _id: _officehours.length,
          active: true,
          weekday: officehoursName,
          start: officehoursStartTime,
          finish: officehoursFinishTime,
        },
      ];

      const _officehour = {
        ...selectedOfficehour,
        _id: selectedOfficehour?._id,
        teams: selectedOfficehour?.teams?.map((_t: ITeam | string) => {
          if (typeof _t === "string") return _t;
          return _t._id;
        }),
        officehours: [..._officehours, ..._newOfficehours],
      };

      const { payload } = await dispatch(updateOfficehour({ _officehour }));
      if (
        typeof setOfficehoursName !== "undefined" &&
        typeof setOfficehoursStartTime !== "undefined" &&
        typeof setOfficehoursFinishTime !== "undefined"
      ) {
        setOfficehoursName("");
        setOfficehoursStartTime("");
        setOfficehoursFinishTime("");
        document
          .querySelectorAll(".dropdown-officehour .clear")
          .forEach((el: any) => el.click());
      }
      if (payload) {
        dispatch(
          reselectOfficehour({
            ...selectedOfficehour,
            officehours: [..._officehours, ..._newOfficehours],
            updated_at: payload.updated_at,
            updated_by: payload.updated_by,
          })
        );
        if (modal) {
          setDisable(false);
          setShowModal(false);
        } else if (setVisible && setReloadForced) {
          setDisable(false);
          dispatch(
            reselectOfficehour({
              ...selectedOfficehour,
              officehours: [..._officehours, ..._newOfficehours],
              updated_at: payload.updated_at,
              updated_by: payload.updated_by,
            })
          );
          setVisible(false);
          setReloadForced(false);
        }
      } else {
        setDisable(false);
      }
    },
    contacts: async () => {
      const _tenant = getTenant();
      if (emailHasError) return;
      if (
        name.length < 2 ||
        email.length === 0 ||
        !validNumberLength({ value: contactPhone?.replace(/[^0-9.]/g, "") }) ||
        // contactPhone?.replace(/[^0-9.]/g, "").length < 12 ||
        (contactCode?.replace(/\D+/g, "").length > 0 &&
          contactCode?.replace(/\D+/g, "").length !== 11)
      )
        return;
      if (typeof _tenant === "undefined" || _tenant === undefined) return;
      let _payload: IUser;
      let contactAvatar = "";
      if (sendAvatar) contactAvatar = await handleChange();
      if (contactAvatar !== "") {
        const { payload } = await dispatch(
          createContact({
            name,
            avatar: contactAvatar,
            email,
            code: contactCode.length === 11 ? contactCode : undefined,
            phone: contactPhone,
            roles: [RoleUser.CONTACT],
            sendinvite: false,
            tenant: _tenant,
          })
        );
        _payload = payload;
      } else {
        const { payload } = await dispatch(
          createContact({
            name,
            email,
            code: contactCode.length === 11 ? contactCode : undefined,
            phone: contactPhone,
            roles: [RoleUser.CONTACT],
            sendinvite: false,
            tenant: _tenant,
          })
        );
        _payload = payload;
      }
      if (_payload && typeof _payload !== "string") {
        if (modal) {
          setDisable(false);
          setShowModal(false);
          navigate(`/settings/contact/${_payload?._id}`);
        } else if (setVisible) {
          if (selectedCustomer) {
            const results =
              selectedCustomer.contacts?.map((contact) => contact?._id || "") ||
              [];
            results.push(_payload?._id || "");
            const { payload } = await dispatch(
              updateCustomerContacts({
                _customer: selectedCustomer?._id || "",
                contacts: results,
              })
            );
            if (payload._id) {
              await updatingCustomer(payload._id);
            }
          }
          setDisable(false);
          setVisible(false);
        }
      } else {
        setDisable(false);
      }
    },
    customers: async () => {
      if (nameHasError) return;
      if (name.length < 2) return;
      const { payload } = await dispatch(createCustomer({ name }));
      if (payload && typeof payload?._id !== "undefined") {
        if (modal) {
          setDisable(false);
          setShowModal(false);
          navigate(`/settings/customer/${payload?._id}`);
        } else if (setVisible) {
          if ((!profile || typeof profile !== "undefined") && selectedContact) {
            const results =
              selectedContact.customers?.map((customer) => customer._id) || [];
            results.push(payload._id);
            await dispatch(
              updateUser({
                ...selectedContact,
                avatar: selectedContact?.avatar?._id,
                _id: selectedContact._id,
                customers: results,
                tags: selectedContact?.tags?.map((_t) => {
                  if (typeof _t === "string") return _t;
                  return _t._id;
                }),
              })
            );
            await dispatch(
              selectUser({
                _id: selectedContact?._id || "",
                isContact: true,
              })
            );
          } else if (user) {
            const results =
              user.customers?.map((customer) => customer._id) || [];
            results.push(payload._id);
            await dispatch(
              update({
                _updateUser: {
                  ...user,
                  _id: user._id,
                  customers: results,
                  tags: user?.tags?.map((_t) => {
                    if (typeof _t === "string") return _t;
                    return _t._id;
                  }),
                },
              })
            );
            await dispatch(userdata());
          }
          setDisable(false);
          setVisible(false);
        }
      } else {
        setDisable(false);
      }
    },
    users: async () => {
      const _tenant = getTenant();
      if (_tenant === undefined || typeof _tenant === "undefined") return;
      if (emailHasError || passwordHasError) return;
      if (name.length < 2 || email.length === 0 || password.length === 0)
        return;
      let _payload: IUser;
      let userAvatar = "";
      if (sendAvatar) userAvatar = await handleChange();
      if (userAvatar !== "") {
        const { payload } = await dispatch(
          createUser({
            name,
            avatar: userAvatar,
            email,
            password,
            phone: userPhone,
            roles: [RoleUser.AGENT],
            sendinvite,
            tenant: _tenant,
          })
        );
        _payload = payload;
      } else {
        const { payload } = await dispatch(
          createUser({
            name,
            email,
            password,
            phone: userPhone,
            roles: [RoleUser.AGENT],
            sendinvite,
            tenant: _tenant,
          })
        );
        _payload = payload;
      }
      if (_payload && typeof _payload !== "string") {
        if (modal) {
          setDisable(false);
          setShowModal(false);
          navigate(`/settings/user/${_payload?._id}`);
        } else if (setVisible) {
          if (selectedTeam) {
            let results =
              selectedTeam.users?.map((_user) => _user?._id || "") || [];
            results.push(_payload?._id || "");

            results = removeDuplicates(results);

            const { payload } = await dispatch(
              updateTeamUsers({
                _team: selectedTeam?._id || "",
                users: results,
                noToast: true,
              })
            );
            if (payload._id) {
              await updatingTeam(payload._id);
            }
          }
          setDisable(false);
          setVisible(false);
        }
      } else {
        setDisable(false);
      }
    },
    teams: async () => {
      if (nameHasError) return;
      if (name.length < 2) return;
      const { payload } = await dispatch(createTeam({ name }));
      if (payload && typeof payload?._id !== "undefined") {
        if (modal) {
          setDisable(false);
          setShowModal(false);
          navigate(`/settings/team/${payload?._id}`);
        } else if (setVisible) {
          if (
            location.pathname.includes("whatsapp") ||
            location.pathname.includes("agibot")
          ) {
            const selectedExternalAppObj = location.pathname.includes(
              "whatsapp"
            )
              ? selectedExternalAppWhatsApp
              : selectedExternalAppBotmaker;
            const results =
              selectedExternalAppObj?.fields?.defaultteam?.map(
                (team) => team._id
              ) || [];
            results.push(payload._id);
            await dispatch(
              updateExternalapps({
                _externalapp: {
                  ...selectedExternalAppObj,
                  fields: {
                    ...selectedExternalAppObj.fields,
                    defaultteam: results,
                  },
                },
              })
            );
            await dispatch(
              selectExternalapp({
                _id: selectedExternalAppObj?._id || "",
              })
            );
          } else if (location.pathname.includes("general")) {
            const _settings = {
              _id: selectedSettings?._id,
              authtypes: selectedSettings?.authtypes,
              defaultagent: selectedSettings?.defaultagent?._id,
              defaultbot: {
                name: selectedSettings?.defaultbot?.name,
                welcomemsg: selectedSettings?.defaultbot?.welcomemsg,
                unavailablemsg: selectedSettings?.defaultbot?.unavailablemsg,
                userofflinemsg: selectedSettings?.defaultbot?.userofflinemsg,
              },
              defaultteam: selectedSettings?.defaultteam?.map((_t) => {
                if (typeof _t === "string") return _t;
                return _t._id;
              }),
              widget: selectedSettings?.widget,
              officehour: selectedSettings?.officehour?._id,
              isopen: selectedSettings?.isopen,
            };
            const _action = await dispatch(updateSettings({ _settings }));
            if (_action && _action.payload) {
              dispatch(
                reselectSettings({
                  ...selectedSettings,
                  updated_at: _action.payload.updated_at,
                  updated_by: _action.payload.updated_by,
                })
              );
              if (modal) {
                setDisable(false);
                setShowModal(false);
                navigate(`/settings/general`);
              } else if (setVisible && setReloadForced) {
                reselectSettings(selectedSettings);
                setDisable(false);
                setVisible(false);
                setReloadForced(false);
                navigate(`/settings/general`);
              }
            }
          } else if (
            (!profile || typeof profile !== "undefined") &&
            selectedUser
          ) {
            const results = selectedUser.teams?.map((team) => team._id) || [];
            results.push(payload._id);
            await dispatch(
              updateUser({
                ...selectedUser,
                avatar: selectedUser?.avatar?._id,
                _id: selectedUser._id,
                teams: results,
              })
            );
            await dispatch(selectUser({ _id: selectedUser?._id || "" }));
          } else if (user) {
            const results = user.teams?.map((team) => team._id) || [];
            results.push(payload._id);
            await dispatch(
              update({
                _updateUser: {
                  ...user,
                  avatar: user?.avatar?._id,
                  _id: user._id,
                  teams: results,
                },
              })
            );
            await dispatch(userdata());
          }
          setDisable(false);
          setVisible(false);
        }
      } else {
        setDisable(false);
      }
    },
    groupchat: async () => {
      let _message: IMessage | undefined | null;
      if (name.length < 2 || nameHasError) {
        return;
      }
      if (typeof user?._id !== "undefined" && user?._id !== null) {
        const _groupSettings: ChatAdmin[] = [];

        if (groupUsers.length > 0) {
          groupUsers.forEach((_user) => {
            _groupSettings.push({
              active: true,
              role: [ChatGroupRole.READER],
              user: _user as any,
              chatavatar: null,
              chatname: name,
            });
          });
        }

        if (selectedChat && typeof selectedChat?._id !== "undefined") {
          dispatch(closeChat());
        }

        const { payload } = await dispatch(
          createChat({
            name,
            groupsettings: _groupSettings,
            groupchat: true,
            users: [...groupUsers],
            status: ChatStatus.ACTIVE,
            type: ChatType.INTERNAL,
          })
        );

        const _chat = payload as IChat;

        if (typeof _chat._id !== "undefined") {
          await sendInternalMessage({
            type: InternalMessageType.CREATE_GROUP,
            message: {
              chat: _chat,
              content: `<b>[${user._id}]</b> criou o grupo <b>${_chat.name}</b>`,
            },
          });
        }

        for await (const _added of groupUsers) {
          await sendInternalMessage({
            type: InternalMessageType.ADD_TO_GROUP,
            message: {
              chat: _chat,
              content: `<b>[${user?._id}]</b> adicionou <b>[${_added}]</b>.`,
            },
          });
        }

        if (typeof _chat?._id !== "undefined" && _chat !== null) {
          navigate(`/chat/${_chat._id}`);
          if (typeof _message?._id !== "undefined" && _message) {
            dispatch(
              reselectChat({
                chat: {
                  ..._chat,
                  lastmessage: _message,
                  updated_at: _chat.updated_at,
                  updated_by: _chat.updated_by,
                },
              })
            );
            dispatch(
              changeChat({
                chat: {
                  ..._chat,
                  lastmessage: _message,
                  updated_at: _chat.updated_at,
                  updated_by: _chat.updated_by,
                },
              })
            );
          }
        }

        setDisable(false);
        if (setShowUsersList) setShowUsersList(false);
        if (setShowModal) setShowModal(false);
      }
    },
  };

  const forms = {
    chattag: (
      <div>
        <Form.Input
          autoComplete="off"
          className="pt-4"
          // icon="calendar outline"
          // iconPosition="left"
          label="Nome"
          value={name}
          minLength={2}
          required
          onChange={nameChangeHandler}
          onBlur={nameBlurHandler}
          error={nameHasError}
          type="text"
          name="name"
          id="name"
          variant="outlined"
          // size="small"
          placeholder="Insira um nome"
        />
        <div className="mt-4" />
        <TagTypeDropdown
          disabled
          label="Tipo de marcador"
          id="dropdown-tags-create"
          required
          value={TagType.CHAT}
          // onChange={(e, { value }) => {
          //   const _value = value as TagType;
          //   setTagType(_value);
          // }}
        />
        <div className="mb-3" />
      </div>
    ),
    contacttag: (
      <div>
        <Form.Input
          autoComplete="off"
          className="pt-4"
          // icon="calendar outline"
          // iconPosition="left"
          label="Nome"
          value={name}
          minLength={2}
          required
          onChange={nameChangeHandler}
          onBlur={nameBlurHandler}
          error={nameHasError}
          type="text"
          name="name"
          id="name"
          variant="outlined"
          // size="small"
          placeholder="Insira um nome"
        />
        <div className="mt-4" />
        <TagTypeDropdown
          disabled
          label="Tipo de marcador"
          id="dropdown-tags-create"
          required
          value={TagType.CONTACT}
          // onChange={(e, { value }) => {
          //   const _value = value as TagType;
          //   setTagType(_value);
          // }}
        />
        <div className="mb-3" />
      </div>
    ),
    holiday: (
      <div>
        <Form.Input
          autoComplete="off"
          className="pt-4"
          // icon="calendar outline"
          // iconPosition="left"
          label="Nome"
          value={name}
          minLength={2}
          required
          onChange={nameChangeHandler}
          onBlur={nameBlurHandler}
          error={nameHasError}
          type="text"
          name="name"
          id="name"
          variant="outlined"
          // size="small"
          placeholder="Insira um nome"
        />
        <div className="mt-4" />
        <DaysDropdown
          required
          defaultValue={holidayDay}
          onChange={(e, { value }) => {
            const _value = value as string;
            setHolidayDay(_value);
          }}
        />
        <MonthsDropdown
          required
          defaultValue={holidayMonth}
          onChange={(e, { value }) => {
            const _value = value as string;
            setHolidayMonth(_value);
          }}
        />
        <YearsDropdown
          required
          upward
          defaultValue={holidayYear}
          onChange={(e, { value }) => {
            const _value = value as string;
            setHolidayYear(_value);
          }}
        />
        <div className="mb-3" />
      </div>
    ),
    officehour: (
      <div>
        <div className="mt-4" />
        <WeekdaysDropdown
          className="dropdown-officehour"
          id="dropdown-officehours"
          required
          defaultValue={officehoursName}
          onChange={(e, { value }) => {
            const _value = value as string;
            if (typeof setOfficehoursName !== "undefined") {
              setOfficehoursName(_value);
            }
          }}
        />
        <div className="mb-3" />
        <Form.Group>
          <Form.Input
            autoComplete="off"
            readOnly={!isAdmin}
            className="w-full md:w-full"
            fluid
            id="starttime"
            width="8"
            label="Hora inicial"
            min="00:00"
            max="23:59"
            required
            children={
              <ReactInputMask
                maskPlaceholder={null}
                readOnly={!isAdmin}
                placeholder="Hora inicial"
                required
                mask={
                  typeof officehoursStartTime !== "undefined"
                    ? [
                        /[0-2]/,
                        officehoursStartTime[0] === "2" ? /[0-3]/ : /[0-9]/,
                        ":",
                        /[0-5]/,
                        /[0-9]/,
                      ]
                    : ""
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (typeof setOfficehoursStartTime !== "undefined") {
                    setOfficehoursStartTime(
                      e.target.value.replaceAll("_", "0")
                    );
                  }
                }}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (typeof setOfficehoursStartTime !== "undefined") {
                    setOfficehoursStartTime(parseBlurTime(e.target.value));
                  }
                }}
                value={officehoursStartTime}
              />
            }
          />
          <Form.Input
            autoComplete="off"
            readOnly={!isAdmin}
            className="w-full md:w-full"
            fluid
            id="finishtime"
            width="8"
            label="Hora final"
            min="00:01"
            max="23:59"
            required
            children={
              <ReactInputMask
                maskPlaceholder={null}
                readOnly={!isAdmin}
                placeholder="Hora final"
                required
                mask={
                  typeof officehoursFinishTime !== "undefined"
                    ? [
                        /[0-2]/,
                        officehoursFinishTime[0] === "2" ? /[0-3]/ : /[0-9]/,
                        ":",
                        /[0-5]/,
                        /[0-9]/,
                      ]
                    : ""
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (typeof setOfficehoursFinishTime !== "undefined") {
                    setOfficehoursFinishTime(
                      e.target.value.replaceAll("_", "0")
                    );
                  }
                }}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (typeof setOfficehoursFinishTime !== "undefined") {
                    setOfficehoursFinishTime(parseBlurTime(e.target.value));
                  }
                }}
                value={officehoursFinishTime}
              />
            }
          />
        </Form.Group>
        <div className="mb-3" />
      </div>
    ),
    contacts: (
      <>
        <div className="mt-4" />
        <Form.Input
          autoComplete="off"
          // icon="user outline"
          // iconPosition="left"
          label="Nome"
          value={name}
          required
          onChange={nameChangeHandler}
          onBlur={nameBlurHandler}
          error={nameHasError}
          type="text"
          name="name"
          id="name"
          variant="outlined"
          placeholder="Insira um nome"
        />
        <div className="mt-4" />
        <Form.Input
          autoComplete="new-email"
          // icon="at"
          // iconPosition="left"
          label="E-mail"
          value={email}
          required
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
          error={emailHasError}
          type="email"
          name="email"
          id="email"
          variant="outlined"
          placeholder="Insira um e-mail"
        />
        <div className="mt-4" />
        <PhoneFlagInput
          required
          error={
            contactPhone.length === 0
              ? false
              : !validNumberLength({ value: contactPhone })
          }
          defaultValue={contactPhone}
          onChange={(value: string) => {
            setContactPhone(value);
          }}
        />
        {/* <PhoneMaskInput
          error={
            contactPhone?.replace(/[^0-9.]/g, "").length > 0 &&
            contactPhone?.replace(/[^0-9.]/g, "").length < 12
          }
          required
          defaultValue={contactPhone}
          onChange={(e) => {
            setContactPhone(e.target.value);
          }}
        /> */}
        {showCode ? (
          <>
            <div className="mt-4" />
            <Form.Input
              label="CPF"
              error={
                contactCode?.replace(/\D+/g, "").length > 0 &&
                contactCode?.replace(/\D+/g, "").length < 11
              }
              children={
                <ReactInputMask
                  min={11}
                  max={11}
                  mask="999.999.999-99"
                  placeholder="Insira um CPF"
                  onChange={(e) => {
                    setContactCode(e.target.value.replace(/\D+/g, ""));
                  }}
                  maskPlaceholder={null}
                />
              }
              autoComplete="off"
            />
          </>
        ) : null}

        <div className="mb-3" />
      </>
    ),
    users: (
      <>
        <div className="mt-4" />
        <Form.Input
          autoComplete="off"
          // icon="user outline"
          // iconPosition="left"
          label="Nome"
          value={name}
          required
          onChange={nameChangeHandler}
          onBlur={nameBlurHandler}
          error={nameHasError}
          type="text"
          name="name"
          id="name"
          variant="outlined"
          placeholder="Insira um nome"
        />
        <ErrorLabel error={nameHasError} label="Insira um nome válido" />
        <div className="mt-4" />
        <Form.Input
          autoComplete="new-email"
          // icon="at"
          // iconPosition="left"
          label="E-mail"
          value={email}
          required
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
          error={emailHasError}
          type="email"
          name="email"
          id="email"
          variant="outlined"
          placeholder="Insira um e-mail"
        />
        <ErrorLabel error={emailHasError} label="Insira um e-mail válido" />
        <div className="mt-4" />
        <div className="relative">
          <Form.Input
            autoComplete="new-password"
            type={showHide.newuser ? "input" : "password"}
            label={t("form.label.password")}
            value={password}
            required
            onChange={passwordChangeHandler}
            onBlur={passwordBlurHandler}
            error={passwordHasError}
            name="password"
            id="password"
            variant="outlined"
            placeholder="Insira uma senha"
            action
          >
            <input />
            <ShowHideButton
              title={`${!showHide.newuser ? "Mostrar" : "Esconder"} ${t(
                "form.label.password"
              ).toLowerCase()}`}
              hide={showHide.newuser}
              onClick={(e) => handleShowHide(e, "newuser")}
            />
          </Form.Input>
        </div>
        {passwordHasError ? (
          <StrongPasswordChecklist
            rules={["minLength", "specialChar", "number", "capital"]}
            minLength={8}
            value={password || ""}
          />
        ) : null}
        <div className="mt-4" />
        <PhoneFlagInput
          error={
            userPhone.length === 0
              ? false
              : !validNumberLength({ value: userPhone })
          }
          defaultValue={userPhone}
          onChange={(value: string) => {
            if (validNumberLength({ value: userPhone })) {
              setUserPhone(value);
            } else {
              setUserPhone("");
            }
          }}
        />
        {/* <PhoneMaskInput
          error={
            userPhone?.replace(/[^0-9.]/g, "").length > 0 &&
            userPhone?.replace(/[^0-9.]/g, "").length < 12
          }
          defaultValue={userPhone}
          onChange={(e) => {
            setUserPhone(e.target.value);
          }}
        /> */}
        <Toggle
          containerClass="flex mt-4"
          defaultChecked={sendinvite}
          mainClass="scale-[80%]"
          onChange={(_, { checked }) => {
            setSendinvite(checked || false);
          }}
          labelClass="ml-2 mb-7"
          label="Enviar convite por e-mail?"
        />
        <div className="mb-3" />
      </>
    ),
    teams: (
      <>
        <PopupComponent
          className="flex items-center mt-3 -mb-3"
          label="Nome"
          required
          content={
            <h1 className="font-semibold text-[12px]">Máximo 24 caracteres</h1>
          }
        />
        <Form.Input
          autoComplete="off"
          className="pt-4"
          // icon="users"
          // iconPosition="left"
          value={name}
          required
          onChange={nameChangeHandler}
          onBlur={nameBlurHandler}
          error={nameHasError}
          type="text"
          name="name"
          id="name"
          variant="outlined"
          placeholder="Insira um nome"
          minLength={2}
          maxLength={24}
        />
      </>
    ),
    password: (
      <>
        <div className="mt-4" />
        <label className="relative">
          <Form.Input
            autoComplete="new-password"
            label={t("form.label.password")}
            value={password}
            required
            onChange={passwordChangeHandler}
            onBlur={passwordBlurHandler}
            error={passwordHasError}
            type={showHide.pass ? "input" : "password"}
            name="password"
            id="password"
            variant="outlined"
            placeholder="Nova senha"
            action
          >
            <input />
            <ShowHideButton
              title={`${!showHide.pass ? "Mostrar" : "Esconder"} ${t(
                "form.label.password"
              ).toLowerCase()}`}
              hide={showHide.pass}
              onClick={(e) => handleShowHide(e, "pass")}
            />
          </Form.Input>
          {passwordHasError ? (
            <StrongPasswordChecklist
              rules={["minLength", "specialChar", "number", "capital"]}
              minLength={8}
              value={password || ""}
            />
          ) : null}
        </label>
        <div className="mt-4" />
        <label className=" relative">
          <Form.Input
            autoComplete="new-password"
            label={t("form.label.confirm-password")}
            value={confirmPassword}
            required
            onChange={confirmPasswordChangeHandler}
            onBlur={confirmPasswordBlurHandler}
            error={confirmPassword.length > 0 && password !== confirmPassword}
            type={showHide.confirmpass ? "input" : "password"}
            name="confirmPassword"
            id="confirmPassword"
            variant="outlined"
            placeholder="Confirme a nova senha"
            action
          >
            <input />
            <ShowHideButton
              title={`${!showHide.confirmpass ? "Mostrar" : "Esconder"} ${t(
                "form.label.confirm-password"
              ).toLowerCase()}`}
              hide={showHide.confirmpass}
              onClick={(e) => handleShowHide(e, "confirmpass")}
            />
          </Form.Input>
          {confirmPasswordHasError || password !== confirmPassword ? (
            <StrongPasswordChecklist
              rules={["match"]}
              minLength={8}
              value={confirmPassword || ""}
            />
          ) : null}
        </label>
      </>
    ),
    groupchat: (
      <>
        <PopupComponent
          className="flex items-center mt-3 -mb-3"
          label="Nome"
          required
          content={
            <h1 className="font-semibold text-[12px]">Máximo 30 caracteres</h1>
          }
        />
        <Form.Input
          autoComplete="off"
          className="pt-4"
          // icon="users"
          // iconPosition="left"
          value={name}
          required
          onChange={nameChangeHandler}
          onBlur={nameBlurHandler}
          error={nameHasError}
          type="text"
          name="name"
          id="name"
          variant="outlined"
          placeholder="Insira um nome"
          minLength={2}
          maxLength={24}
        />
        <Dropdown
          fluid
          selection
          id="dropdown-group-users"
          label="Agentes"
          placeholder="Agentes"
          required
          loading={isLoadingDropdown && focusId === "dropdown-group-users"}
          search
          multiple
          options={instanceOptions}
          onOpen={async () => {
            setIsLoadingDropdown(true);
            const payload = await usersService.searchAgents({
              skip: 0,
              limit: 10,
              filter: "",
              deleted: false,
            });
            setIsLoadingDropdown(false);
            const _optionsUsers: object[] = [];
            payload.results
              .filter((_user: IUser) => _user?._id !== user?._id)
              .map((_user: IUser) =>
                _optionsUsers.push({
                  key: _user._id,
                  text: _user.name,
                  value: _user._id,
                  content: (
                    <div className="flex">
                      <h1 className="mr-2">{_user?.name}</h1>
                      {typeof _user?.email !== "undefined" ? (
                        <h1 className="text-gray-text">({_user?.email})</h1>
                      ) : null}
                    </div>
                  ),
                })
              );
            setInstanceOptions(_optionsUsers);
          }}
          onFocus={() => {
            setFocusId("dropdown-group-users");
          }}
          onBlur={() => {
            setFocusId("");
          }}
          onSearchChange={async (e: DropdownSearchInputProps) => {
            setIsLoadingDropdown(true);
            const payload = await usersService.searchAgents({
              skip: 0,
              limit: 10,
              filter: e.target.value,
              deleted: false,
            });
            setIsLoadingDropdown(false);
            const _optionsUsers: ddOptions[] = instanceOptions;
            payload.results
              .filter((_user: IUser) => _user?._id !== user?._id)
              .map((_user: IUser) => {
                if (
                  _optionsUsers.filter(
                    (_opUser) => _opUser.key === _user._id
                  )[0]
                ) {
                  return false;
                }
                _optionsUsers.push({
                  key: _user._id,
                  text: _user.name,
                  value: _user._id,
                  content: (
                    <div className="flex">
                      <h1 className="mr-2">{_user?.name}</h1>
                      {typeof _user?.email !== "undefined" ? (
                        <h1 className="text-gray-text">({_user?.email})</h1>
                      ) : null}
                    </div>
                  ),
                });
                return true;
              });
            setInstanceOptions(_optionsUsers);
          }}
          onChange={(e, { value }) => {
            const _value = value as string[];
            setGroupUsers(removeDuplicates(_value));
          }}
        />
      </>
    ),
    campaign: (
      <>
        <Form.Input
          autoComplete="off"
          label="Nome"
          value={campaignFields.name}
          minLength={2}
          required
          onChange={(e, { value }) => {
            setCampaignFields((prevState) => ({ ...prevState, name: value }));
          }}
          type="text"
          name="name"
          id="name"
          variant="outlined"
          placeholder="Insira um nome"
        />
        <CampaignTypesDropdown />
        <PopupComponent
          className="flex items-center mb-1"
          label="Número do WhatsApp"
          required
          content={
            <h1 className="font-semibold text-[12px]">
              Confira o limite de mensagens na sua conta META
            </h1>
          }
        />
        <Dropdown
          selection
          search
          fluid
          required
          // label="Número do WhatsApp"
          id="dropdown-campaign-externalapps"
          loading={
            isLoadingDropdown && focusId === "dropdown-campaign-externalapps"
          }
          disabled={instanceOptions.length === 1}
          value={campaignFields.externalapp}
          placeholder="Selecione a integração"
          options={instanceOptions}
          onOpen={async () => {
            setIsLoadingDropdown(true);
            const payload = await externalappsService.searchExternalApps({
              skip: 0,
              limit: 10,
              filter: {
                types: [ExternalAppType.WHATSAPP, ExternalAppType.BOTMAKER],
              },
            });
            setIsLoadingDropdown(false);
            const _options: object[] = [];
            payload?.results?.map(
              (_externalApp: IExternalAppWhatsApp | IExternalAppBotmaker) => {
                if (_externalApp.type === ExternalAppType.WHATSAPP) {
                  const _externalWpp = _externalApp as IExternalAppWhatsApp;
                  _options.push({
                    key: _externalWpp._id,
                    text: _externalWpp.fields.name,
                    value: _externalWpp._id,
                    content: (
                      <div className="flex">
                        <h1 className="mr-2">{_externalWpp?.fields?.name}</h1>
                        {typeof _externalWpp?.fields?.displayphone !==
                        "undefined" ? (
                          <h1 className="text-gray-text">
                            (
                            {handleMaskRegex(
                              _externalWpp?.fields?.displayphone
                            )}
                            )
                          </h1>
                        ) : null}
                      </div>
                    ),
                  });
                } else if (_externalApp.type === ExternalAppType.BOTMAKER) {
                  const _externalBot = _externalApp as IExternalAppBotmaker;
                  const _listPhones = _externalBot.fields.externalphones;
                  if (
                    typeof _listPhones !== "undefined" &&
                    _listPhones.length > 0
                  ) {
                    _listPhones.map((_externalapp) => {
                      return _options.push({
                        key: _externalapp.phone,
                        text: _externalapp.name,
                        value: `${_externalBot._id}|%@g1%|${_externalapp.phone}`,
                        content: (
                          <div className="flex">
                            <h1 className="mr-2">{_externalapp?.name}</h1>
                            {typeof _externalapp.phone !== "undefined" ? (
                              <h1 className="text-gray-text">
                                ({handleMaskRegex(_externalapp.phone)})
                              </h1>
                            ) : null}
                          </div>
                        ),
                      });
                    });
                  }
                }
                return null;
              }
            );
            setInstanceOptions(_options);
          }}
          onFocus={() => {
            setFocusId("dropdown-campaign-externalapps");
          }}
          onBlur={() => {
            setFocusId("");
          }}
          onSearchChange={async (e: DropdownSearchInputProps) => {
            setIsLoadingDropdown(true);
            const payload = await externalappsService.searchExternalApps({
              skip: 0,
              limit: 10,
              filter: {
                "fields.name": e.target.value,
                types: [ExternalAppType.WHATSAPP, ExternalAppType.BOTMAKER],
              },
            });
            setIsLoadingDropdown(false);
            const _options: ddOptions[] = instanceOptions;
            payload?.results?.map(
              (_externalApp: IExternalAppWhatsApp | IExternalAppBotmaker) => {
                if (_externalApp.type === ExternalAppType.WHATSAPP) {
                  const _externalWpp = _externalApp as IExternalAppWhatsApp;
                  _options.push({
                    key: _externalWpp._id,
                    text: _externalWpp.fields.name,
                    value: _externalWpp._id,
                    content: (
                      <div className="flex">
                        <h1 className="mr-2">{_externalWpp?.fields?.name}</h1>
                        {typeof _externalWpp?.fields?.displayphone !==
                        "undefined" ? (
                          <h1 className="text-gray-text">
                            (
                            {handleMaskRegex(
                              _externalWpp?.fields?.displayphone
                            )}
                            )
                          </h1>
                        ) : null}
                      </div>
                    ),
                  });
                } else if (_externalApp.type === ExternalAppType.BOTMAKER) {
                  const _externalBot = _externalApp as IExternalAppBotmaker;
                  const _listPhones = _externalBot.fields.externalphones;
                  if (
                    typeof _listPhones !== "undefined" &&
                    _listPhones.length > 0
                  ) {
                    _listPhones.map((_externalapp) => {
                      return _options.push({
                        key: _externalapp.phone,
                        text: _externalapp.name,
                        value: `${_externalBot._id}|%@g1%|${_externalapp.phone}`,
                        content: (
                          <div className="flex">
                            <h1 className="mr-2">{_externalapp?.name}</h1>
                            {typeof _externalapp.phone !== "undefined" ? (
                              <h1 className="text-gray-text">
                                ({handleMaskRegex(_externalapp.phone)})
                              </h1>
                            ) : null}
                          </div>
                        ),
                      });
                    });
                  }
                }
                return null;
              }
            );
            setInstanceOptions(_options);
          }}
          onChange={(e, { value }) => {
            if (typeof value === "string") {
              const _value = !value.includes("|%@g1%|")
                ? value
                : value.split("|%@g1%|")[0];
              const _key = !value?.includes("|%@g1%|")
                ? ""
                : value.split("|%@g1%|")[1];
              setCampaignFields((prevState) => ({
                ...prevState,
                externalapp: _key ? value : _value,
              }));
            }
          }}
        />
        {/* {campaignFields.externalapp.length > 0 ? (
          <Form.Input
            autoComplete="off"
            label="Quantidade de mensagens disponíveis no número selecionado"
            value={campaignFields.maxsend}
            required
            onChange={(e, { value }) => {
              const _value = +value;
              setCampaignFields((prevState) => ({
                ...prevState,
                maxsend: _value,
              }));
            }}
            type="text"
            name="total-messages-wpp"
            id="total-messages-wpp"
            variant="outlined"
            placeholder="Insira um valor"
          />
        ) : null} */}
      </>
    ),
    default: (
      <Form.Input
        autoComplete="off"
        // icon="info"
        // iconPosition="left"
        label="Nome"
        value={name}
        minLength={2}
        required
        onChange={nameChangeHandler}
        onBlur={nameBlurHandler}
        error={nameHasError}
        type="text"
        name="name"
        id="name"
        variant="outlined"
        placeholder="Insira um nome"
      />
    ),
  };

  const getDisabledType = () => {
    switch (icon) {
      case "las la-key":
        return (
          !user ||
          !user.email ||
          !user?.tenant ||
          typeof user?.tenant === "undefined" ||
          password.length < 8 ||
          passwordHasError ||
          confirmPassword.length < 8 ||
          confirmPasswordHasError ||
          password !== confirmPassword
        );
      case "las la-calendar":
        return (
          !officehoursName || !officehoursStartTime || !officehoursFinishTime
        );
      case "las la-address-book":
        return (
          name.length < 2 ||
          nameHasError ||
          email.length === 0 ||
          emailHasError ||
          // contactPhone?.replace(/[^0-9.]/g, "").length < 12
          !validNumberLength({ value: contactPhone?.replace(/[^0-9.]/g, "") })
        );
      case "las la-calendar-times":
        return (
          nameHasError ||
          name.length < 2 ||
          holidayDay.length === 0 ||
          holidayMonth.length === 0 ||
          holidayYear.length === 0
        );
      case "las la-user":
        return (
          name.length < 2 ||
          nameHasError ||
          email.length === 0 ||
          emailHasError ||
          password.length < 8 ||
          passwordHasError ||
          (userPhone.length > 0 &&
            userPhone?.replace(/[^0-9.]/g, "").length > 0 &&
            !validNumberLength({ value: userPhone?.replace(/[^0-9.]/g, "") }))
        );
      case "las la-users":
        return name.length < 2 || nameHasError || groupUsers.length < 1;
      case "las la-bullhorn":
        return (
          campaignFields.name.length < 2 ||
          campaignFields.externalapp.length === 0 ||
          campaignFields.type !== CampaignType.WHATSAPP // ||
          // campaignFields.maxsend < 1
        );
      default:
        return nameHasError || name.length < 2;
    }
  };

  const getTypeCreate = async () => {
    setDisable(true);
    switch (icon) {
      case "las la-key":
        return dropdownResults.password();
      case "agidesk":
        return dropdownResults.agidesk();
      case "lab la-whatsapp":
        return dropdownResults.whatsapp();
      case "las la-robot":
        return dropdownResults.agibot();
      case "las la-calendar-check":
        return dropdownResults.officehours();
      case "las la-calendar-times":
        return dropdownResults.holiday();
      case "las la-tags":
        return dropdownResults.chattag();
      case "las la-user-tag":
        return dropdownResults.contacttag();
      case "las la-hourglass-end":
        return dropdownResults.offlinemessage();
      case "las la-calendar":
        return dropdownResults.officehour();
      case "las la-address-book":
        return dropdownResults.contacts();
      case "las la-building":
        return dropdownResults.customers();
      case "las la-user":
        return dropdownResults.users();
      case "las la-user-friends":
        return dropdownResults.teams();
      case "las la-users":
        return dropdownResults.groupchat();
      case "las la-shopping-cart":
        return dropdownResults.vtex();
      case "las la-smile":
        return dropdownResults.survey();
      case "las la-bullhorn":
        return dropdownResults.campaign();
      case "las la-comment":
        return dropdownResults.commenttemplates();
      default:
        return null;
    }
  };

  const getType = () => {
    switch (icon) {
      case "las la-address-book":
        return forms.contacts;
      case "las la-user":
        return forms.users;
      case "las la-user-friends":
        return forms.teams;
      case "las la-tags":
        return forms.chattag;
      case "las la-user-tag":
        return forms.contacttag;
      case "las la-calendar-times":
        return forms.holiday;
      case "las la-calendar":
        return forms.officehour;
      case "las la-key":
        return forms.password;
      // case "las la-filter":
      //   return forms.filters;
      case "las la-users":
        return forms.groupchat;
      case "las la-bullhorn":
        if (instanceOptions.length === 0) {
          (async () => {
            const payload = await externalappsService.searchExternalApps({
              skip: 0,
              limit: 10,
              filter: {
                types: [ExternalAppType.WHATSAPP, ExternalAppType.BOTMAKER],
              },
            });
            if (
              typeof payload?.results === "undefined" ||
              !payload?.results ||
              payload?.results?.length === 0 ||
              payload.statusCode === 401
            ) {
              setShowModal(false);
              if (isAdmin) {
                navigate("/settings/integration/whatsapp");
                customToast({
                  type: IToastType.WARNING,
                  message: t("toast.warning.no-integration"),
                });
              }
            } else {
              const _options: ddOptions[] = [];
              payload?.results?.map((_externalWpp: IExternalAppWhatsApp) =>
                _options.push({
                  key: _externalWpp._id,
                  text: _externalWpp.fields.name,
                  value: _externalWpp._id,
                  content: (
                    <div className="flex">
                      <h1 className="mr-2">{_externalWpp?.fields?.name}</h1>
                      {typeof _externalWpp?.fields?.displayphone !==
                      "undefined" ? (
                        <h1 className="text-gray-text">
                          ({_externalWpp?.fields?.displayphone})
                        </h1>
                      ) : null}
                    </div>
                  ),
                })
              );
              setInstanceOptions(_options);
              if (
                _options.length === 1 &&
                typeof _options[0].value !== "undefined"
              ) {
                setCampaignFields((prevState) => ({
                  ...prevState,
                  externalapp: _options[0].value as string,
                }));
              }
            }
          })();
        }
        return forms.campaign;
      default:
        return forms.default;
    }
  };

  const modalContent = () => (
    <div
      id="modal-scroll"
      className={`relative bg-white rounded-md ${
        modal
          ? "flex flex-col justify-center w-fit sm:w-2/4 max-w-[500px] bg-white overflow-y-scroll max-h-full shadow-lg"
          : "w-full max-w-xl"
      } py-8 pl-10 pr-8`}
    >
      <h1 className="flex items-center justify-center font-semibold">
        {icon !== "agidesk" ? (
          <i className={`${icon} text-3xl`} />
        ) : (
          <img className="agidesk-logo" alt="Agidesk" src={logoonly} />
        )}
        <p className="text-xl ml-3">{title}</p>
      </h1>
      {isUploading ? (
        <LoadingDots className="flex justify-center items-center" />
      ) : (
        <div>
          {["las la-user", "las la-address-book"].includes(icon) ? (
            <>
              <PopupComponent
                className="flex items-center mt-3 -mb-3"
                label="Foto"
                content={
                  <div>
                    <h1>- Tamanho máximo do arquivo: 20MB</h1>
                    <h1>- Dimensões sugeridas: 150px x 150px</h1>
                  </div>
                }
              />
              <div className="relative">
                {typeof sendAvatar !== "undefined" ? (
                  <div
                    role="presentation"
                    title="Remover imagem"
                    onClick={() => {
                      setSendAvatar(undefined);
                      if (
                        ["las la-user", "las la-address-book"].includes(icon)
                      ) {
                        setPreviewAvatar(userImage);
                      } else {
                        setPreviewImage(noImage);
                      }
                    }}
                    className="absolute w-[25px] h-[25px] z-[210] bottom-[calc(50%+15px)] right-[calc(50%-60px)] rounded-full  text-red hover:text-red-alphaC0 cursor-pointer"
                  >
                    <i className="las la-times-circle text-[20px] bg-white rounded-full p-0" />
                  </div>
                ) : null}
                <div
                  className="w-[120px] h-[120px] relative border-[5px] border-white rounded-full bg-cover my-5 mx-auto overflow-hidden"
                  style={{
                    backgroundImage: `url(${
                      ["las la-user", "las la-address-book"].includes(icon)
                        ? previewAvatar
                        : previewImage
                    })`,
                  }}
                >
                  <input
                    type="file"
                    className="my_file"
                    title="Adicionar foto"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const input = event.target as HTMLInputElement;

                      if (!input.files?.length) {
                        return;
                      }
                      const _file = input.files[0];
                      setSendAvatar(_file);
                      if (
                        ["las la-user", "las la-address-book"].includes(icon)
                      ) {
                        setPreviewAvatar(URL.createObjectURL(_file));
                      } else {
                        setPreviewImage(URL.createObjectURL(_file));
                      }
                    }}
                  />
                </div>
              </div>
            </>
          ) : null}
          <Form
            className="mx-auto inline-table w-full overflow-x-hidden px-4"
            // className={`mx-auto ${
            //   focusId !== "" ? "min-h-[300px]" : ""
            // } w-full overflow-x-hidden px-4`}
          >
            {getType()}
            {showFooter !== false ? (
              <ActionButtonsModal
                sidebar={!modal}
                disabled={getDisabledType() || disable}
                cancelAction={() => {
                  setShowModal(false);
                  if (!modal && setVisible) {
                    setVisible(false);
                    if (
                      typeof setOfficehoursName !== "undefined" &&
                      typeof setOfficehoursStartTime !== "undefined" &&
                      typeof setOfficehoursFinishTime !== "undefined"
                    ) {
                      setOfficehoursName("");
                      setOfficehoursStartTime("");
                      setOfficehoursFinishTime("");
                      document
                        .querySelectorAll(".dropdown-officehour .clear")
                        .forEach((el: any) => el.click());
                    }
                  }
                  if (setChangePass) {
                    setChangePass(false);
                  }
                }}
                submitAction={() => {
                  getTypeCreate();
                }}
              />
            ) : null}
          </Form>
        </div>
      )}
    </div>
  );

  return modal || !setVisible ? (
    <div id="modal" className="fixed inset-0 z-[200] overflow-y-scroll">
      <div
        role="presentation"
        className="fixed inset-0 w-full h-full bg-black opacity-80"
        onClick={() => {
          setShowModal(false);
          if (!modal && setVisible) {
            setVisible(false);
          }
          if (setChangePass) {
            setChangePass(false);
          }
        }}
      />
      <div className="flex items-center justify-center min-h-screen">
        {/* <Draggable bounds="parent">{modalContent()}</Draggable> */}
        {modalContent()}
      </div>
    </div>
  ) : (
    modalContent()
  );
};

CreateModal.defaultProps = {
  modal: false,
  profile: false,
  setVisible: () => null,
  setChangePass: () => null,
  setReloadForced: () => null,
  showFooter: undefined,
  officehoursName: "",
  setOfficehoursName: undefined,
  officehoursStartTime: "",
  setOfficehoursStartTime: undefined,
  officehoursFinishTime: "",
  setOfficehoursFinishTime: undefined,
  setShowUsersList: undefined,
};

export default CreateModal;
