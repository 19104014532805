/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from "react";
import { DropdownSearchInputProps } from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import Dropdown from "./Dropdown.component";
import { ddOptions } from "../models/interfaces/dropdownOptions.interface";
import {
  getSearchContacts,
  getSearchFilterUsers,
  IFilterUser,
} from "../../slices/users.slice";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import Button from "./Buttons/Button.component";
import { getSearchFilterTeams, getSearchTeams } from "../../slices/teams.slice";
import SearchBox from "./SearchBox.component";
import { ChatStatus, ChatType } from "../../slices/chats.slice";
import removeDuplicates from "../utils/removeDuplicates";
import { getSearchExternalApps } from "../../slices/externalapps.slice";
import {
  IFilterCustomer,
  getSearchCustomers,
} from "../../slices/customers.slice";
import Toggle from "./Toggle.component";
import botAllowed from "../utils/botAllowed";
import { IFilterSurvey, getSearchSurveys } from "../../slices/surveys.slice";
import { TagType, getSearchTags } from "../../slices/tags.slice";
import useUserIsAdmin from "../../hooks/useUserIsAdmin";
import {
  IFilterCampaign,
  getSearchCampaigns,
} from "../../slices/campaigns.slice";
import { CampaignBatchStatus } from "../../slices/campaignbatches.slice";
import {
  DropdownParams,
  DropdownResults,
  SearchBarFiltersProps,
} from "./@types/searchBarFilter.types";

const SearchbarFilters = ({
  clearFilters,
  searchFiltersSelected,
  setSearchFiltersSelected,
  searchFiltersOptions,
  setSearchFiltersOptions,
  setShowFilter,
  setToggleList,
  defaultFilters,
  searchInstanceName,
  setVisible,
  skipSearchResults,
  limitSearch,
  setSkipSearchPage,
  setActiveSearchPage,
}: SearchBarFiltersProps) => {
  const { isAdmin } = useUserIsAdmin();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.auth);
  const { isLoadingDropdownUsers } = useAppSelector((state) => state.users);
  const { isLoadingDropdownTeams } = useAppSelector((state) => state.teams);
  const { isLoadingDropdownSurveys } = useAppSelector((state) => state.surveys);
  const { isLoadingDropdownTags: isLoadingDropdownContactTags } =
    useAppSelector((state) => state.tags);
  const { isLoadingDropdownCampaigns } = useAppSelector(
    (state) => state.campaigns
  );

  const { isLoadingDropdownCustomers } = useAppSelector(
    (state) => state.customers
  );
  const { isLoadingExternalapps } = useAppSelector(
    (state) => state.externalapps
  );

  const defaultParams: DropdownParams = {
    skip: skipSearchResults || 0,
    limit: limitSearch || 10,
    deleted: false,
    filter: "",
  };

  const dispatch = useAppDispatch();
  const [focusId, setFocusId] = useState<string>("");
  const [searchbarKeyword, setSearchbarKeyword] = useState<string>(
    typeof searchFiltersSelected?.content !== "undefined" &&
      searchFiltersSelected?.content.length > 0 &&
      typeof searchFiltersSelected?.content[0] !== "undefined"
      ? searchFiltersSelected?.content[0]
      : ""
  );
  const [groupChat, setGroupChat] = useState<boolean>(
    typeof searchFiltersSelected?.groupchat !== "undefined" &&
      searchFiltersSelected?.groupchat.length > 0 &&
      typeof searchFiltersSelected?.groupchat[0] !== "undefined"
      ? searchFiltersSelected?.groupchat[0]
      : false
  );
  const [showCampaignChat, setShowCampaignChat] = useState<boolean>(
    typeof searchFiltersSelected?.campaignchat !== "undefined" &&
      searchFiltersSelected?.campaignchat.length > 0 &&
      typeof searchFiltersSelected?.campaignchat[0] !== "undefined"
      ? searchFiltersSelected?.campaignchat[0]
      : false
  );

  const [searchbarFilters, setSearchbarFilters] = useState<any>(
    searchFiltersSelected
  );

  const checkShowExternalfilter = () => {
    const show = !!(
      typeof searchbarFilters.types === "object" &&
      ((searchbarFilters.types.includes("EXTERNAL") &&
        !searchbarFilters.types.includes("WIDGET")) ||
        ["WHATSAPP", "BOTMAKER"].some((type) =>
          searchbarFilters.types.includes(type)
        ))
    );
    return show;
  };

  const [showExternalappFilters, setShowExternalappFilters] = useState<boolean>(
    checkShowExternalfilter()
  );

  const [showAdvancedFilters, setShowAdvancedFilters] = useState<boolean>(true);

  const searchbarFiltersEmpty = () => {
    for (const prop of Object.keys(searchbarFilters)) {
      if (
        typeof searchbarFilters[prop] !== "undefined" &&
        searchbarFilters[prop].length > 0
      )
        return false;
    }
    return true;
  };

  useEffect(() => {
    setSearchbarFilters({
      ...searchbarFilters,
      content:
        typeof searchbarKeyword !== "undefined" && searchbarKeyword.length > 0
          ? [searchbarKeyword]
          : [],
    });
  }, [searchbarKeyword]);

  useEffect(() => {
    if (groupChat) {
      //   // clearFilters({ _instance: "campaigns" });
      //   // clearFilters({ _instance: "campaignchat" });
      setShowCampaignChat(!groupChat);
    }
    setSearchbarFilters({
      ...searchbarFilters,
      groupchat: groupChat ? [groupChat] : [],
      campaigns: groupChat ? [] : searchbarFilters.campaigns,
      campaignchat: groupChat ? [] : searchbarFilters.campaignchat,
    });
  }, [groupChat]);

  useEffect(() => {
    if (showCampaignChat) {
      setGroupChat(!showCampaignChat);
    }
    setSearchbarFilters({
      ...searchbarFilters,
      campaigns: !showCampaignChat ? [] : searchbarFilters.campaigns,
      campaignchat: !showCampaignChat ? [] : [showCampaignChat],
      groupchat: showCampaignChat ? [] : searchbarFilters.groupchat,
    });
  }, [showCampaignChat]);

  useEffect(() => {
    setShowExternalappFilters(checkShowExternalfilter());
    searchbarFiltersEmpty();
  }, [searchbarFilters]);

  useEffect(() => {
    if (!showExternalappFilters) {
      setSearchbarFilters({
        ...searchbarFilters,
        externalapps: [],
      });
    }
  }, [showExternalappFilters]);

  const formatDdResults = ({
    items,
    sort,
  }: {
    items: DropdownResults[];
    sort?: boolean;
  }) => {
    const _result: DropdownResults[] = [];
    let _sort = true;
    if (sort) _sort = sort;
    items.forEach((_item) => {
      _result.push({
        _id: _item._id,
        name: _item.name[0].toUpperCase() + _item.name.slice(1),
      } as DropdownResults);
    });

    const count = items.length;
    return {
      payload: {
        count,
        results:
          typeof _result !== "undefined" && _result.length > 0
            ? _result?.sort((a, b) => {
                if (_sort) {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                }
                return 0;
              })
            : [],
      },
    };
  };

  const getSearchtypes = () => {
    const onlyExternalTypes =
      typeof searchInstanceName !== "undefined" &&
      searchInstanceName === "dashboards";

    const items = [
      { _id: "WIDGET", name: "Widget" },
      { _id: ChatType.WHATSAPP, name: "whatsApp" },
      { _id: ChatType.BOTMAKER, name: "Agibot" },
    ];

    if (!onlyExternalTypes) {
      items.push(
        { _id: ChatType.INTERNAL, name: "Interno" },
        { _id: ChatType.EXTERNAL, name: "Externo" }
      );
      if (botAllowed()) {
        items.push({ _id: ChatType.BOTMAKER, name: "Agibot" });
      }
    }

    return formatDdResults({ items });
  };

  const statusOptions: any = {
    campaignbatch: [
      { _id: CampaignBatchStatus.SUCCESS, name: "Enviado" },
      { _id: CampaignBatchStatus.PENDING, name: "Pendente" },
      { _id: CampaignBatchStatus.CANCELED, name: "Cancelado" },
    ],
    surveyrating: [
      { _id: 1, name: "Pendente" },
      { _id: 2, name: "Respondida" },
      { _id: 3, name: "Expirada" },
    ],
    default: [
      { _id: ChatStatus.ACTIVE, name: "Em atendimento" },
      { _id: ChatStatus.FINISH, name: "Encerrado" },
      { _id: ChatStatus.SUSPEND, name: "Cancelado pelo contato" },
      { _id: ChatStatus.CANCELED, name: "Cancelado" },
      { _id: ChatStatus.WAITREPLY, name: "Aguardando retorno (WhatsApp)" },
      { _id: ChatStatus.WAIT, name: "Na fila" },
      { _id: "TRANSFERRED", name: "Transferido" },
    ],
  };

  const getSearchStatus = () => {
    const items =
      typeof searchInstanceName !== "undefined" &&
      typeof statusOptions[searchInstanceName] !== "undefined"
        ? statusOptions[searchInstanceName]
        : statusOptions.default;

    return formatDdResults({ items });
  };

  const _getSearchFilterTeams = async () => {
    const returnAll =
      typeof searchInstanceName !== "undefined" &&
      (searchInstanceName === "dashboards" || searchInstanceName === "chats") &&
      isAdmin;

    return !returnAll && user?._id
      ? getSearchFilterTeams([user?._id])
      : getSearchTeams({
          skip: 0,
          limit: 10,
          filter: "",
          deleted: false,
        });
  };

  const getSearchPeriod = () => {
    const items = [
      { _id: "thisweek", name: "Esta semana" },
      { _id: "lastweek", name: "Semana passada" },
      { _id: "thismonth", name: "Este mês" },
      { _id: "lastmonth", name: "Mês passado" },
      { _id: "last30", name: "Últimos 30 dias" },
      { _id: "last60", name: "Últimos 60 dias" },
      { _id: "last90", name: "Últimos 90 dias" },
      { _id: "last180", name: "Últimos 180 dias" },
      { _id: "thisyear", name: "Este ano" },
      { _id: "lastyear", name: "Ano passado" },
    ];

    return formatDdResults({ items, sort: false });
  };

  const getDropdownLabel = (instance: string) => {
    const labels = {
      customers: "Cliente",
      teams: "Equipe",
      users: "Agente",
      types: "Canal",
      status: "Situação",
      externalapps: "Aplicativo externo",
      period: "Período",
      surveys: "Pesquisa",
      contacttags: "Marcadores de contato",
      tags: "Marcadores de conversa",
      campaigns: "Campanhas",
      contacts: "Contatos",
    };

    if (!(instance in labels)) {
      return "";
    }

    return labels[instance as keyof typeof labels];
  };

  const getDropdownSearches = async (instance: string, _filters?: string) => {
    const searchPars =
      typeof _filters !== "undefined"
        ? {
            ...defaultParams,
            filter: _filters,
          }
        : defaultParams;

    const methods = {
      types: () => getSearchtypes(),
      status: () => getSearchStatus(),
      period: () => getSearchPeriod(),
      externalapps: () => {
        const _selectedTypes = searchbarFilters.types;
        return getSearchExternalApps({
          skip: 0,
          limit: 10,
          filter: {
            active: true,
            types: _selectedTypes,
          },
        });
      },
      users: () =>
        getSearchFilterUsers(
          searchbarFilters.teams?.length > 0
            ? searchbarFilters.teams
            : user?.teams?.map((_t) => _t._id)
        ),
      teams: async () => _getSearchFilterTeams(),
      surveys: () => getSearchSurveys(searchPars as IFilterSurvey),
      customers: () => getSearchCustomers(searchPars as IFilterCustomer),
      contacts: () => getSearchContacts(searchPars as IFilterUser),
      contacttags: () => {
        return getSearchTags({
          ...searchPars,
          filter: {
            content: searchPars.filter,
            type: [TagType.CONTACT],
            active: true,
          },
        });
      },
      tags: () => {
        return getSearchTags({
          ...searchPars,
          filter: {
            content: searchPars.filter,
            type: [TagType.CHAT],
            teams: isAdmin
              ? undefined
              : user?.teams
                  ?.map((_t) => {
                    if (typeof _t === "string") return _t;
                    if (_t.active === true) return _t._id as string;
                    return "";
                  })
                  .filter(
                    (arrayItem) =>
                      typeof arrayItem === "string" && arrayItem.length > 0
                  ),
            active: true,
          },
        });
      },
      campaigns: async () => getSearchCampaigns(searchPars as IFilterCampaign),
    };

    const method =
      instance in methods ? methods[instance as keyof typeof methods] : null;

    const methodResponse: any = method ? await method() : null;
    if (
      typeof methodResponse !== "undefined" &&
      typeof methodResponse === "function"
    ) {
      const { payload } = await dispatch(methodResponse);
      return typeof payload.results !== "undefined" ? payload.results : payload;
    }
    return typeof methodResponse.results !== "undefined"
      ? methodResponse?.results
      : methodResponse?.payload;
  };

  const prepareDDOptions = async (_instance: string, filters?: string) => {
    const payload = await getDropdownSearches(_instance, filters);
    const _options: ddOptions[] = [];
    const results = [
      "teams",
      "surveys",
      "customers",
      "contacttags",
      "tags",
      "externalapps",
      "campaigns",
      "users",
      "contacts",
    ].includes(_instance)
      ? payload
      : payload.results;

    results?.map((_opt: any) => {
      _options.push({
        key: _opt._id,
        text: _instance === "externalapps" ? _opt.fields.name : _opt.name,
        value: _opt._id,
        content:
          _instance === "users" ? (
            <div className="flex">
              <h1 className="mr-2">{_opt?.name}</h1>
              {typeof _opt?.email !== "undefined" ? (
                <h1 className="text-gray-text">({_opt?.email})</h1>
              ) : (
                ""
              )}
            </div>
          ) : (
            _opt.name
          ),
      });
      return true;
    });

    return _options;
  };

  const buildDropdown = (instance: string) => {
    const multiple = instance !== "period";
    const _instance = instance; // IFiltersDropdownInstances;

    let currentSelected: string[] | string | undefined = multiple ? [] : "";

    currentSelected =
      (multiple && typeof searchFiltersSelected[_instance] !== "boolean") ||
      (!multiple && typeof searchFiltersSelected[_instance] === "string")
        ? searchFiltersSelected[_instance]
        : "";

    const currentOptions =
      typeof searchFiltersOptions[_instance] !== "undefined"
        ? searchFiltersOptions[_instance]
        : [];

    const getIsLoading = () => {
      const isLoading = {
        types: false,
        status: false,
        period: false,
        externalapps: isLoadingExternalapps,
        users: isLoadingDropdownUsers,
        teams: isLoadingDropdownTeams,
        surveys: isLoadingDropdownSurveys,
        customers: isLoadingDropdownCustomers,
        contacttags: isLoadingDropdownContactTags,
        tags: isLoadingDropdownContactTags,
        campaigns: isLoadingDropdownCampaigns,
        contacts: false,
      };

      return isLoading[_instance as keyof typeof isLoading] || false;
    };
    return (
      <>
        <Dropdown
          className={`${
            instance === "users" ? "dropdown-filters-users" : "dropdown-filters"
          }`}
          defaultValue={currentSelected}
          options={currentOptions}
          clearable
          fluid
          selection
          id={`dropdown-relation-${instance}`}
          label={getDropdownLabel(instance)}
          placeholder={getDropdownLabel(instance)}
          loading={
            getIsLoading() && focusId === `dropdown-relation-${instance}`
          }
          search
          multiple={multiple}
          onOpen={async () => {
            const _options: ddOptions[] =
              (await prepareDDOptions(instance)) || [];
            setSearchFiltersOptions({
              ...searchFiltersOptions,
              [instance]: _options,
            });
          }}
          onFocus={() => {
            setFocusId(`dropdown-relation-${instance}`);
          }}
          onBlur={() => {
            setFocusId("");
          }}
          onSearchChange={async (e: DropdownSearchInputProps) => {
            const _options: ddOptions[] = await prepareDDOptions(
              instance,
              e.target.value
            );

            setSearchFiltersOptions({
              ...searchFiltersOptions,
              [instance]: [...searchFiltersOptions[instance], ..._options],
            });
            setSearchbarFilters(searchFiltersSelected);
          }}
          onChange={(e, { value }) => {
            const currentComboItems = {
              ...searchbarFilters,
              [instance]:
                typeof value === "object" ? removeDuplicates(value) : value,
            };
            if (instance === "teams") {
              clearFilters({ _instance: "users" });

              setSearchbarFilters({
                ...currentComboItems,
                users: [],
              });
            } else {
              setSearchbarFilters(currentComboItems);
            }
          }}
        />
        <div className="mb-3" />
      </>
    );
  };

  const getFooter = () => {
    const handleClear = () => {
      setGroupChat(false);
      setShowCampaignChat(false);
      clearFilters({});
      if (setSkipSearchPage) {
        setSkipSearchPage(0);
      }
      if (setActiveSearchPage) {
        setActiveSearchPage(1);
      }
      setSearchbarKeyword("");
      setSearchbarFilters(defaultFilters);
      setShowExternalappFilters(checkShowExternalfilter());
    };

    const handleSubmit = () => {
      try {
        let isEmpty = true;
        for (const _filterKey of Object.keys(searchbarFilters)) {
          const _filter = searchbarFilters[_filterKey];
          if (typeof _filter !== "undefined") {
            if (_filter.length > 0) {
              isEmpty = false;
            }
          }
        }
        if (!isEmpty) {
          if (typeof setToggleList !== "undefined") {
            setToggleList("search");
          }
          setSearchFiltersSelected(searchbarFilters);
          if (setSkipSearchPage) {
            setSkipSearchPage(0);
          }
          if (setActiveSearchPage) {
            setActiveSearchPage(1);
          }
          if (setVisible) {
            setVisible(false);
            navigate(location.pathname);
          }
          setShowFilter(false);
        } else {
          handleClear();
          if (setVisible) {
            setVisible(false);
            navigate(location.pathname);
          }
          setShowFilter(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <>
        <Button
          extraClass="cursor-pointer items-center justify-center rounded-3xl border shadow-sm hover:bg-agitalks-alphaC0 disabled:cursor-default"
          minWidth
          label="Limpar"
          icon="las la-eraser"
          onClick={handleClear}
          inverted
        />
        <Button
          extraClass="cursor-pointer items-center justify-center rounded-3xl border shadow-sm hover:bg-agitalks-alphaC0 disabled:cursor-default"
          minWidth
          label="Aplicar"
          icon="las la-search"
          onClick={handleSubmit}
          // disabled={searchbarFiltersEmpty()}
        />
      </>
    );
  };

  const getAdvancedFilters = () => {
    return {
      groupchat: {
        title: "Exibir somente chats de grupo",
        // readOnly: showCampaignChat,
        onChange: setGroupChat,
        checked: groupChat,
      },
      campaignchat: {
        title: "Exibir somente chats de campanha",
        // readOnly: groupChat,
        onChange: setShowCampaignChat,
        checked: showCampaignChat,
        relatedDropdown: "campaigns",
      },
    };
  };

  const getDropdownFields = () => {
    const fields: any = [];
    const advancedFilters: any = getAdvancedFilters();
    if (typeof defaultFilters !== "undefined") {
      for (const instance of Object.keys(defaultFilters)) {
        const skipInstance = Object.entries(advancedFilters).map(
          (filter: any) => {
            return filter[1].relatedDropdown === instance;
          }
        )[1];
        if (
          (!showExternalappFilters && instance === "externalapps") ||
          skipInstance ||
          instance === "content" ||
          instance === "groupchat"
        ) {
          // eslint-disable-next-line no-continue
          continue;
        }
        const _element = buildDropdown(instance);
        fields.push({
          ..._element,
          key: `dropdown-relation-${instance}`,
        });
      }
    }

    return fields;
  };

  const buildAdvancedFilters = () => {
    const fields: JSX.Element[] = [];
    Object.entries(getAdvancedFilters()).forEach((filter: any) => {
      const _filterProps = filter[1];
      const _filterType =
        typeof _filterProps !== "undefined" &&
        typeof _filterProps.relatedDropdown !== "undefined"
          ? _filterProps.relatedDropdown
          : filter[0];

      if (
        typeof defaultFilters !== "undefined" &&
        _filterType.toString() in defaultFilters
      ) {
        const relatedDropdown =
          _filterProps.checked &&
          typeof _filterProps?.relatedDropdown !== "undefined";
        fields.push(
          <div
            key={`div-toggle-${_filterType}`}
            className={`${relatedDropdown ? "mb-2" : null}`}
          >
            <Toggle
              id={`toggle-${_filterType}`}
              containerClass=" w-full justify-start items-center"
              mainClass="justify-center h-full mt-[8px] mr-[10px]"
              checked={_filterProps.checked || false}
              labelClass="flex mt-[5px]"
              label={_filterProps.title}
              simple={false}
              readOnly={_filterProps.readOnly || false}
              onChange={(_, { checked }) => {
                if (typeof _filterProps?.onChange !== "undefined") {
                  _filterProps.onChange(checked as boolean);
                }
              }}
            />
          </div>
        );

        if (relatedDropdown) {
          const _element = buildDropdown(_filterType);
          fields.push({
            ..._element,
            key: `dropdown-relation-${_filterType}`,
          });
        }
      }
    });

    return <div className="mb-4">{fields}</div>;
  };

  const buildAdvancedFilterArea = () => {
    const advancedFilters: string[] = [];
    // TODO: check if some advanced filter is setted as a default filter option and then build its area
    Object.entries(getAdvancedFilters()).forEach((filter) => {
      if (
        typeof defaultFilters !== "undefined" &&
        filter[0] in defaultFilters
      ) {
        advancedFilters.push(filter[0]);
      }
    });

    if (advancedFilters.length > 0) {
      return (
        <div className="mt-6 mb-2 mx-3">
          <div
            role="presentation"
            className="w-full flex relative"
            onClick={() => setShowAdvancedFilters((prevState) => !prevState)}
          >
            <label className="">Filtros avançados</label>
            <i
              className={`absolute right-5 las ${
                showAdvancedFilters ? "la-angle-up" : "la-angle-down"
              } text-[20px]"`}
            />
          </div>

          {typeof showAdvancedFilters !== "undefined" && showAdvancedFilters
            ? buildAdvancedFilters()
            : null}
          <hr className="h-0.5 mt-2" />
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div
        id={
          typeof searchInstanceName !== "undefined" &&
          !["surveyrating", "dashboards"].includes(searchInstanceName)
            ? "filters"
            : "sidemenu"
        }
        className="content overflow-y-scroll w-full"
      >
        {buildAdvancedFilterArea()}

        <div className="w-full">
          {typeof defaultFilters !== "undefined" &&
          "content" in defaultFilters ? (
            <SearchBox
              filters
              extraClass="bg-white my-[10px] mx-[10px] rounded-[4px] h-[36px]"
              extraClassInner="rounded-[4px]"
              placeholder="Conteúdo"
              _escFunction={() => {
                searchbarFiltersEmpty();
                setSearchbarKeyword("");
              }}
              keyword={searchbarKeyword}
              listFilter={(e: React.ChangeEvent<HTMLInputElement>) => {
                searchbarFiltersEmpty();
                setSearchbarKeyword(e.target.value);
              }}
            />
          ) : null}
        </div>
        <div className={`pb-5 bg-color-white overflow-none `}>
          <div className="px-3">{getDropdownFields()}</div>
        </div>
      </div>
      <div className="footer flex items-center justify-between ml-3 mr-5 mb-4">
        {getFooter()}
      </div>
    </>
  );
};

SearchbarFilters.defaultProps = {
  defaultFilters: {
    campaigns: [],
    period: [],
    types: [],
    externalapps: [],
    status: [],
    teams: [],
    users: [],
    customers: [],
    content: [],
    contacttags: [],
    contacts: [],
    groupchat: [],
    tags: [],
  },
  setToggleList: undefined,
  setVisible: undefined,
  searchInstanceName: undefined,
  limitSearch: undefined,
  skipSearchResults: undefined,
  setSkipSearchPage: undefined,
  setActiveSearchPage: undefined,
};
export default SearchbarFilters;
