import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { UserType } from "../slices/users.slice";
import { useAppSelector } from "../hooks/redux/hooks";
import bgAuth from "../assets/images/bg-auth.png";
import bgOfficehours from "../assets/images/bg-officehours.png";
import bgRecovery from "../assets/images/bg-recovery.png";

const PublicRoute = ({ widgetType }: { widgetType?: UserType }) => {
  const { isAuthenticated, user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      isAuthenticated &&
      user &&
      !location.pathname.includes("/privacy") &&
      !location.pathname.includes("/schedule") &&
      !location.pathname.includes("/surveyrating")
    ) {
      navigate("/");
    }
  }, []);

  const getSrc = () => {
    if (location.pathname.includes("/schedule/officehours")) {
      return bgOfficehours;
    }
    if (
      location.pathname.includes("/recovery") ||
      location.pathname.includes("/password")
    ) {
      return bgRecovery;
    }
    return bgAuth;
  };

  return (!widgetType || (widgetType && widgetType.length === 0)) &&
    !location.pathname.includes("/privacy") &&
    !location.pathname.includes("/surveyrating") ? (
    <div className="publicroute-container">
      <div className="image-container">
        <img src={getSrc()} alt="background" />
      </div>
      <Outlet />
    </div>
  ) : (
    <Outlet />
  );
};

PublicRoute.defaultProps = {
  widgetType: UserType.NONE,
};

export default PublicRoute;
