import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "semantic-ui-react";

import { IExternalAppAgidesk } from "../../../slices/externalapps.slice";
import { useAppDispatch } from "../../../hooks/redux/hooks";

import CategoriesTopicsSelectionComponent from "../Agidesk/CategoriesTopicsSelection.component";
import TeamServiceRelation from "../Agidesk/TeamServiceRelation.component";
import CustomCollapsible from "../CustomColapsible.component";
import Toggle from "../Toggle.component";

interface AgideskChatFinishProps {
  selectedInstance: IExternalAppAgidesk | null;
  instanceDispatch: ActionCreatorWithPayload<any | null, any>;
  isAdmin: boolean;
}

const setValue = (obj: Record<string, any>, path: string, value: any) => {
  const keys = path.split(".");
  keys.reduce((acc: Record<string, any>, key, index) => {
    if (index === keys.length - 1) {
      acc[key] = value;
    } else {
      acc[key] = acc[key] || {};
    }
    return acc[key];
  }, obj);

  return obj;
};

const handleUpdate = (
  field: string,
  value: any,
  selectedInstance: any,
  dispatch: Function,
  instanceDispatch: ActionCreatorWithPayload<any | null, any>,
  isAdmin: boolean
) => {
  if (selectedInstance && isAdmin) {
    const clone = structuredClone(selectedInstance);
    dispatch(
      instanceDispatch({
        ...clone,
        fields: setValue(clone.fields, field, value),
      })
    );
  }
};

const ToggleGroup = ({
  toggles,
  handleUpdate,
  selectedInstance,
  isAdmin,
}: any) => (
  <div className="sm:flex sm:!flex-row flex-col mb-6">
    {toggles.map(({ field, label }: any, idx: number) => {
      const [path, subPath] = field.split(".");

      return (
        <Toggle
          key={idx}
          mainClass=""
          containerClass="flex w-full ml-2"
          defaultChecked={selectedInstance?.fields?.[path]?.[subPath] || false}
          readOnly={!isAdmin || selectedInstance?.deleted}
          onChange={(_, { checked }) => handleUpdate(field, checked || false)}
          labelClass="ml-4 mb-1.5"
          label={label}
          simple
        />
      );
    })}
  </div>
);

const FormSection = ({
  title,
  fieldPrefix,
  selectedInstance,
  handleUpdate,
  isAdmin,
  navigate,
  locationPath,
  children,
}: any) => (
  <CustomCollapsible
    open={!!selectedInstance?.fields?.[fieldPrefix]}
    action={() => {
      if (isAdmin) {
        handleUpdate(fieldPrefix, !selectedInstance?.fields?.[fieldPrefix]);
        if (!selectedInstance?.fields?.[fieldPrefix]) {
          if (fieldPrefix === "ticketfinishinactive") {
            navigate(`${locationPath}#agidesk-chat-finishinactive`);
          } else {
            navigate(`${locationPath}#agidesk-chat-finish`);
          }
        } else {
          navigate(`${locationPath}`);
        }
      }
    }}
    title={title}
    children={children}
    classname="font-bold text-[13px]"
  />
);

export const AgideskChatFinish = ({
  selectedInstance,
  instanceDispatch,
  isAdmin,
}: AgideskChatFinishProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleFormUpdate = (field: string, value: any) =>
    handleUpdate(
      field,
      value,
      selectedInstance,
      dispatch,
      instanceDispatch,
      isAdmin
    );

  return (
    <Form>
      <FormSection
        title="Criar atendimento ao encerrar manualmente"
        fieldPrefix="ticketfinish"
        selectedInstance={selectedInstance}
        handleUpdate={handleFormUpdate}
        isAdmin={isAdmin}
        navigate={navigate}
        locationPath={location.pathname}
      >
        <>
          <CategoriesTopicsSelectionComponent type="agidesk-ticket-finish" />
          <Form.Input
            autoComplete="off"
            readOnly={!isAdmin || selectedInstance?.deleted}
            label="Assunto"
            placeholder="Assunto"
            type="text"
            required
            defaultValue={
              selectedInstance?.fields?.ticketfinishinfo?.title || ""
            }
            onChange={(e, { value }) =>
              handleFormUpdate("ticketfinishinfo.title", value)
            }
          />
          <Form.Group className="!flex !flex-col">
            <ToggleGroup
              toggles={[
                {
                  field: "ticketfinishinfo.includechat",
                  label: "Incluir conversa ao atendimento",
                },
                {
                  field: "ticketfinishinfo.internal",
                  label: "Interno (não notificar o cliente)",
                },
              ]}
              handleUpdate={handleFormUpdate}
              selectedInstance={selectedInstance}
              isAdmin={isAdmin}
            />
            <ToggleGroup
              toggles={[
                {
                  field: "ticketfinishinfo.openingsolved",
                  label: "Marcar como resolvido",
                },
              ]}
              handleUpdate={handleFormUpdate}
              selectedInstance={selectedInstance}
              isAdmin={isAdmin}
            />
            {selectedInstance?.fields?.ticketfinishservice?.id ? (
              <TeamServiceRelation />
            ) : null}
          </Form.Group>
        </>
      </FormSection>

      <hr className="mb-6" />

      <FormSection
        title="Criar atendimento por inatividade"
        fieldPrefix="ticketfinishinactive"
        selectedInstance={selectedInstance}
        handleUpdate={handleFormUpdate}
        isAdmin={isAdmin}
        navigate={navigate}
        locationPath={location.pathname}
      >
        <>
          <CategoriesTopicsSelectionComponent type="agidesk-ticket-finish-inactive" />
          <Form.Input
            autoComplete="off"
            readOnly={!isAdmin || selectedInstance?.deleted}
            label="Assunto"
            placeholder="Assunto"
            type="text"
            required
            defaultValue={
              selectedInstance?.fields?.ticketfinishinactiveinfo?.title || ""
            }
            onChange={(e, { value }) =>
              handleFormUpdate("ticketfinishinactiveinfo.title", value)
            }
          />
          <Form.Group className="!flex !flex-col">
            <ToggleGroup
              toggles={[
                {
                  field: "ticketfinishinactiveinfo.includechat",
                  label: "Incluir conversa ao atendimento",
                },
                {
                  field: "ticketfinishinactiveinfo.internal",
                  label: "Interno (não notificar o cliente)",
                },
              ]}
              handleUpdate={handleFormUpdate}
              selectedInstance={selectedInstance}
              isAdmin={isAdmin}
            />
            <ToggleGroup
              toggles={[
                {
                  field: "ticketfinishinactiveinfo.openingsolved",
                  label: "Marcar como resolvido",
                },
              ]}
              handleUpdate={handleFormUpdate}
              selectedInstance={selectedInstance}
              isAdmin={isAdmin}
            />
            {selectedInstance?.fields?.ticketfinishinactiveservice?.id ? (
              <TeamServiceRelation isInactive={true} />
            ) : null}
          </Form.Group>
        </>
      </FormSection>
    </Form>
  );
};
