import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SearchBox from "../../../shared/components/SearchBox.component";
import { IUser } from "../../../slices/users.slice";
import NoAvatar from "../../../shared/components/NoAvatar.component";
import Avatar from "../../../shared/components/Avatar.component";
import { selectHistoryContact } from "../../../slices/chats.slice";
import { useAppDispatch } from "../../../hooks/redux/hooks";
import { usersService } from "../../../services";
import { handleMaskRegex } from "../../../shared/utils/handleMaskLength";

const HistorySelectorContact = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [renderDataContacts, setRenderDataContacts] = useState<IUser[]>([]);

  useEffect(() => {
    setSearchKeyword("");
  }, []);

  useEffect(() => {
    (async () => {
      if (searchKeyword.length > 0) {
        setIsLoading(true);
        const payload = await usersService.searchContacts({
          skip: 0,
          limit: 10,
          filter: searchKeyword,
          deleted: false,
          spam: false,
          historySearch: true,
        });
        setIsLoading(false);
        setRenderDataContacts(payload.results);
      } else {
        setRenderDataContacts([]);
      }
    })();
  }, [searchKeyword]);

  const getItemsList = () => {
    if (isLoading) {
      return null;
    }
    if (searchKeyword.length > 0 && renderDataContacts.length === 0) {
      return (
        <div className="flex text-[13px] items-center p-2 justify-center">
          Nenhum resultado
        </div>
      );
    }
    if (searchKeyword.length > 0 && renderDataContacts.length > 0) {
      return renderDataContacts.map((_contact) => {
        if (typeof _contact._id !== "undefined") {
          return (
            <div
              role="presentation"
              onClick={() => {
                dispatch(
                  selectHistoryContact({
                    key: _contact._id,
                    text: _contact.name,
                  })
                );
              }}
              className="flex items-center p-2 hover:cursor-pointer hover:bg-agitalks-alpha30"
              key={_contact._id}
            >
              <div className="w-[25px] h-[25px] mr-2">
                {_contact?.avatar &&
                _contact?.avatar?.path &&
                _contact?.avatar?.name ? (
                  <Avatar
                    path={_contact?.avatar?.path}
                    name={_contact?.avatar?.name}
                    extraClass="shadow-sm"
                    title={_contact?.name}
                  />
                ) : (
                  <NoAvatar
                    displaytext={_contact?.name?.trim() || "--"}
                    elementClass="p-0"
                    labelClass="text-[10px]"
                  />
                )}
              </div>
              <div className="flex flex-col max-w-[130px]">
                <h1 className="font-semibold text-[13px] whitespace-nowrap text-ellipsis overflow-hidden">
                  {_contact?.name}
                </h1>
                <h1 className="text-[11px] whitespace-nowrap text-ellipsis overflow-hidden">
                  {_contact?.email}
                </h1>
                <h1 className="text-[11px] whitespace-nowrap text-ellipsis overflow-hidden">
                  {handleMaskRegex(_contact?.phone) || t("no-phone")}
                </h1>
                {/* {typeof _contact?.customers !== "undefined" &&
                _contact?.customers?.length > 0 &&
                typeof _contact.customers[0]?.name !== "undefined" ? (
                  <h1 className="text-[11px] font-semibold whitespace-nowrap text-ellipsis overflow-hidden">
                    {_contact?.customers[0]?.name}
                  </h1>
                ) : null} */}
              </div>
            </div>
          );
        }
        return null;
      });
    }
    return null;
  };

  return (
    <div className="flex flex-col p-4">
      {/* <h2 className="text-agitalks text-[16px]">Pesquisar contato</h2> */}
      <SearchBox
        isLoading={isLoading}
        extraClass=""
        extraClassInner={`${
          searchKeyword.length > 0 ? "rounded-[8px] rounded-b-none" : ""
        }`}
        placeholder="Pesquisar contato"
        _escFunction={() => {
          setSearchKeyword("");
        }}
        keyword={searchKeyword}
        listFilter={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearchKeyword(e.target.value);
        }}
      />
      <div
        className={`historySelector w-full ${
          searchKeyword.length > 0 && !isLoading
            ? "border-[1px] border-gray-ccc"
            : ""
        } flex flex-col shadow-md rounded-b-[8px] max-h-[180px] overflow-y-scroll`}
      >
        {getItemsList()}
      </div>
    </div>
  );
};

export default HistorySelectorContact;
