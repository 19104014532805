/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect } from "react";

import { DebounceInput } from "react-debounce-input";
import { Loader } from "semantic-ui-react";

import { SearchBoxProps } from "./@types/searchBox.types";

const SearchBox = ({
  id,
  keyword,
  listFilter,
  userPage,
  _escFunction,
  placeholder,
  extraClass,
  extraClassInner,
  extraClassLabel,
  isLoading,
  filters,
  label,
  campaign,
}: SearchBoxProps) => {
  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event && event.key === "Escape") {
      event.preventDefault();
      event.stopPropagation();
      _escFunction();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <>
      {filters ? (
        <label className={extraClassLabel ?? "pl-[10px] -mb-[8px]"}>
          {typeof label !== "undefined" ? label : "Conteúdo"}
        </label>
      ) : null}

      <div
        className={`${userPage ? "max-w-[120px] md:max-w-full pl-0" : ""} ${
          typeof extraClass !== "undefined" ? extraClass : "p-[10px]"
        } flex ${filters ? "flex-col" : ""} ${
          campaign ? "max-w-full pl-0" : ""
        }`}
      >
        <div
          className={`border-[1px] flex-1 border-gray-ccc
          ${
            typeof extraClassInner !== "undefined" && extraClassInner.length > 0
              ? extraClassInner
              : "rounded-[8px]"
          } 
          overflow-hidden flex items-center`}
        >
          {!filters ? (
            <button
              type="button"
              aria-label="Pesquisar"
              className="las la-search w-[32px] border-none p-[5px] text-[14px] text-gray-text cursor-text hover:text-gray-text"
            />
          ) : null}

          <DebounceInput
            id={id || "searchbox"}
            debounceTimeout={800}
            autoComplete="off"
            className={`${
              !filters
                ? "bg-transparent text-[13px] flex-1 p-[6px] !border-none -ml-2"
                : "flex-1 ml-4"
            }`}
            placeholder={placeholder || "Pesquisar..."}
            value={keyword}
            onChange={(e) => listFilter(e)}
          />
          <div>
            {!isLoading ? (
              <button
                type="button"
                onClick={_escFunction}
                disabled={keyword === ""}
                aria-label="Limpar"
                title={keyword === "" ? "" : "Limpar"}
                className={`las ${
                  keyword === ""
                    ? ""
                    : "la-times text-red cursor-pointer hover:text-red"
                } w-[40px] border-none p-[5px] text-[16px]`}
              />
            ) : (
              <div className="mr-3">
                <Loader size="tiny" active inline />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBox;
