import NoAvatar from "../NoAvatar.component";
import Avatar from "../Avatar.component";
import { IUser } from "../../../slices/users.slice";

interface IAvatar {
  hasAvatar: boolean;
  user: IUser | null | undefined;
  fontSize?: string;
}

export const ProfileAvatar = ({
  hasAvatar,
  user,
  fontSize = "text-[12px]",
}: IAvatar) => {
  return (
    <>
      {hasAvatar ? (
        <Avatar
          path={user?.avatar?.path!}
          name={user?.avatar?.name!}
          title="Foto do perfil"
        />
      ) : (
        <NoAvatar
          displaytext={user?.name || "--"}
          elementClass="p-0"
          labelClass={fontSize}
        />
      )}
    </>
  );
};
