import React, { useEffect, useState } from "react";
import { surveyBody } from "../shared/components/SurveyElements.component";
import { ISurveyRating } from "../slices/surveys.slice";
import { surveysService } from "../services";
import AuthLayoutPage from "./AuthLayout.page";
// import LoadingDots from "../shared/components/LoadingDots.component";

const Surveyrating = () => {
  const [currentSurvey, setCurrentSurvey] = useState<ISurveyRating>();
  const [answered, setAnswered] = useState<boolean>(false);
  // const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getCode = () => {
      const queryParameters = new URLSearchParams(window.location.search);
      const code = queryParameters.get("code");
      if (typeof code !== "undefined" && code !== null) return code;
      return undefined;
    };

    (async () => {
      const _code = getCode();
      if (typeof _code !== "undefined" && _code !== null) {
        const payload = await surveysService.surveyrating({
          code: encodeURIComponent(_code),
        });
        if (payload) {
          setCurrentSurvey({
            ...payload,
            code: _code,
          });
        }
      }
    })();
    // setIsLoading(false);
  }, [answered]);

  return (
    <AuthLayoutPage>
      {surveyBody({ surveyRating: currentSurvey, setAnswered })}
    </AuthLayoutPage>
  );
};

export default Surveyrating;
