import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/agitalks_logo.svg";
import { UserType } from "../slices/users.slice";
import TermsAndPolices from "../shared/components/TermsAndPolices.component";
import Button from "../shared/components/Buttons/Button.component";
import CloseButton from "../shared/components/Buttons/CloseButton.component";

const AuthLayoutPage = ({
  children,
  widgetType,
  _public,
}: {
  children: ReactNode;
  widgetType?: UserType;
  _public?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isScrollToTopVisible, setIsScrollToTopVisible] = useState(false);
  const modalContentRef = useRef<any>(null);

  const handleScroll = () => {
    if (location.pathname === "/privacy") {
      if (modalContentRef.current.scrollTop > 200) {
        setIsScrollToTopVisible(true);
      } else {
        setIsScrollToTopVisible(false);
      }
    }
  };

  const scrollToTop = () => {
    modalContentRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (location.pathname !== "/privacy") {
      return () => null;
    }
    const modalContent = modalContentRef.current;
    modalContent.addEventListener("scroll", handleScroll);
    return () => {
      modalContent.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (widgetType !== UserType.NONE) {
    return (
      <div className="flex justify-center w-[450px] h-full relative shadow-md overflow-y-auto">
        <div className="h-full w-full absolute flex justify-center left-0 right-0">
          <div className="flex w-full flex-col justify-between h-full">
            <div className="flex w-full flex-col items-center">
              <img
                alt="Agidesk Logo"
                className={`w-[145px] h-[56px] ${
                  widgetType === UserType.CONTACT ? "my-[30px]" : "my-[50px]"
                } self-center`}
                src={logo}
              />
              <div className={_public ? "w-[90%]" : "form-container"}>
                {children}
              </div>
            </div>
            <div className="pb-[30px]">
              <TermsAndPolices widgetType={widgetType} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (
    location.pathname === "/privacy" ||
    location.pathname === "/surveyrating"
  ) {
    return (
      <div className="background-container">
        <div className="modal-overlay">
          <div className="modal-content" ref={modalContentRef}>
            <CloseButton
              extraClass={
                location.pathname === "/surveyrating" ? "!hidden" : "close"
              }
              onClick={() => {
                navigate("/");
              }}
            />
            <img
              alt="Agidesk Logo"
              className="w-[145px] h-[56px] mt-[48px] mb-[48px] self-center"
              src={logo}
            />

            {children}
            {isScrollToTopVisible && location.pathname === "/privacy" && (
              <Button
                onClick={scrollToTop}
                minWidth={false}
                extraClass="scroll-to-top"
                icon="las la-angle-up"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="flex w-full flex-col justify-between h-screen">
      <div className="flex w-full flex-col items-center">
        <img
          alt="Agidesk Logo"
          className="w-[145px] h-[56px] mt-[90px] mb-[80px] self-center"
          src={logo}
        />
        <div className={_public ? "w-[90%]" : "form-container"}>{children}</div>
      </div>
      <div className="mb-[48px]">
        <TermsAndPolices widgetType={widgetType} />
      </div>
    </div>
  );
};

AuthLayoutPage.defaultProps = {
  widgetType: UserType.NONE,
  _public: false,
};

export default AuthLayoutPage;
