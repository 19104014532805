/* eslint-disable react/require-default-props */
import React from "react";
import ActionButton from "./ActionButton.component";

const ShowHideButton = ({
  title,
  hide,
  onClick,
  disabled,
}: {
  title: string;
  hide: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}) => {
  return (
    <ActionButton
      type="showhide"
      title={title}
      onClick={onClick}
      hide={hide}
      disabled={disabled}
    />
  );
};

export default ShowHideButton;
