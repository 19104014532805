import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import locales from "../utils/locales";
// import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { DashcardType } from "../../../slices/dashboard.slice";
import PopupComponent from "../../../shared/components/Popup.component";

const DashArea = ({
  title,
  tooltip,
  type,
  series,
  options,
  width,
}: {
  width: number;
  title: string;
  tooltip: string;
  type: DashcardType;
  series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
  options?: string[];
}) => {
  const { t } = useTranslation();
  // const { width } = useWindowDimensions();

  const [show, setShow] = useState<boolean>(
    typeof options !== "undefined" && options.length > 0
  );

  const datetimeCharts = [
    DashcardType.CHAT_HOURS_TOTAL,
    DashcardType.CHAT_FIRST_MESSAGE,
    DashcardType.CHAT_BACKLOG_TIME,
    DashcardType.CHAT_BY_AVG_TIME,
  ];

  const getWidth = () => {
    // if (width > 1024) {
    //   return "840";
    // }
    if (width > 580) {
      return width - 120;
    }
    if (width > 375) {
      return width - 80;
    }
    return "280";
    // return _width;
  };

  const getFontDetails = () => {
    if (width > 580) return "text-[24px]";
    if (width > 375) return "text-[20px]";
    return "text-[17px]";
  };

  const getFontSize = () => {
    // if (width > 1024) return "32";
    if (width > 580) return "24";
    if (width > 375) return "20";
    return "17";
  };

  const getFontDetailsLabel = () => {
    if (width > 580) return "text-[13px]";
    if (width > 375) return "text-[11px]";
    return "text-[10px]";
  };

  let _stats: number[] = [0, 0, 0, 0];

  const _series = series?.map((serie: any) => {
    if (typeof serie?.data !== "undefined" && serie.data.length > 0) {
      const _serie = {
        ...serie,
        name: t(`dashboards.type.${serie?.name}`),
      };
      _stats = serie.data.map((_datum: string | number) => +_datum);
      return _serie;
    }
    return serie;
  });

  useEffect(() => {
    setShow(typeof options !== "undefined" && options.length > 0);
    getWidth();
    getFontDetails();
    getFontDetailsLabel();
    getFontSize();
  }, [width]);

  const getTime = (_value: string | number, showSeconds?: boolean) => {
    let value = _value;
    if (!value.toString().includes(".")) {
      if (Number.isInteger(value)) {
        value = `${value.toString()}.00`;
      }
    }

    if (!show && _stats && _stats.length > 0) return `00:00`;
    if (value === 0 || (typeof value === "number" && !Number.isFinite(value))) {
      if (showSeconds) {
        return `:00`;
      }
      return `00:00`;
    }

    const hValue = value;
    const hInt = hValue.toString().split(".")[0];
    const hours = hInt.length === 1 ? `0${hInt}` : hInt;
    const hDec =
      typeof hValue.toString().split(".")[1] !== "undefined"
        ? `0.${hValue.toString().split(".")[1]}`
        : "0.0";

    const mValue = typeof hDec !== "undefined" ? +hDec * 60 : 0;
    const mInt = mValue.toString().split(".")[0];
    const minutes = mInt.length === 1 ? `0${mInt}` : mInt;
    const mDec =
      typeof mValue.toString().split(".")[1] !== "undefined"
        ? `0.${mValue.toString().split(".")[1]}`
        : "0.0";

    const sValue = typeof mDec !== "undefined" ? +mDec * 60 : 0;
    const sInt = sValue.toString().split(".")[0];
    const seconds = sInt.length === 1 ? `0${sInt}` : sInt;

    if (showSeconds) {
      // eslint-disable-next-line no-nested-ternary
      return typeof seconds === "string"
        ? Number(seconds)
          ? `:${seconds}`
          : ":00"
        : null;
    }
    return `${hours}:${minutes}`;
  };

  const sidebarStats = ({ horizontal }: { horizontal?: boolean }) => {
    const getValue = (value: number, showSeconds?: boolean) => {
      if (datetimeCharts.includes(type)) {
        return getTime(+value.toFixed(2), showSeconds);
      }
      return +value.toFixed(2);
    };

    return (
      <div
        className={`${
          horizontal
            ? "flex w-full items-center flex-wrap"
            : "w-full h-full items-center flex-wrap flex-col min-w-[120px]"
        }`}
      >
        <div className="grow basis-[25%] border-t border-b sm:border-t-0 sm:border-b border-l flex flex-col justify-center items-center px-4 py-2 text-gray-333">
          <h1 className={`${getFontDetails()}`}>
            {!show && _stats && _stats.length > 0
              ? "-"
              : getValue(Math.max(..._stats))}
            {datetimeCharts.includes(type) ? (
              <span className="text-[11px]">
                {!show && _stats && _stats.length > 0
                  ? ""
                  : getValue(Math.max(..._stats), true)}
              </span>
            ) : null}
          </h1>
          <span className={`${getFontDetailsLabel()}`}>Máximo</span>
        </div>
        <div className="grow basis-[25%] border-t border-b sm:border-t-0 border-l flex flex-col justify-center items-center px-4 py-2 text-gray-333">
          <h1 className={`${getFontDetails()}`}>
            {!show && _stats.length > 0
              ? "-"
              : getValue(_stats.reduce((a, b) => a + b, 0) / _stats.length)}
            {datetimeCharts.includes(type) ? (
              <span className="text-[11px]">
                {!show && _stats.length > 0
                  ? ""
                  : getValue(
                      _stats.reduce((a, b) => a + b, 0) / _stats.length,
                      true
                    )}
              </span>
            ) : null}
          </h1>
          <span className={`${getFontDetailsLabel()}`}>Média</span>
        </div>
        <div className="grow basis-[25%] border-t border-b sm:border-t-0 border-l flex flex-col justify-center items-center px-4 py-2 text-gray-333">
          <h1 className={`${getFontDetails()}`}>
            {!show && _stats.length > 0
              ? "-"
              : getValue(Math.min(..._stats.filter(Boolean)))}
            {datetimeCharts.includes(type) ? (
              <span className="text-[11px]">
                {!show && _stats.length > 0
                  ? ""
                  : getValue(Math.min(..._stats.filter(Boolean)), true)}
              </span>
            ) : null}
          </h1>
          <span className={`${getFontDetailsLabel()}`}>Mínimo</span>
        </div>
        <div className="grow basis-[25%] border-t border-b sm:border-t-0 sm:border-b-0 border-l flex flex-col justify-center items-center px-4 py-2 text-gray-333">
          <h1 className={`${getFontDetails()}`}>
            {!show && _stats.length > 0
              ? "-"
              : getValue(_stats.reduce((a, b) => a + b, 0))}
            {datetimeCharts.includes(type) ? (
              <span className="text-[11px]">
                {!show && _stats.length > 0
                  ? ""
                  : getValue(
                      _stats.reduce((a, b) => a + b, 0),
                      true
                    )}
              </span>
            ) : null}
          </h1>
          <span className={`${getFontDetailsLabel()}`}>Total</span>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white rounded-md flex-col sm:flex-row sm:flex items-center h-full w-full">
      <div className="w-full">
        <PopupComponent
          className="flex items-center self-start text-[16px] w-[290px] sm:w-[477px] text-gray-333 p-4 font-semibold"
          label={title}
          content={<h1 className="font-semibold text-[12px]">{tooltip}</h1>}
        />
        {/* <h1 className="self-start text-[16px] word-wrap-2 w-[290px] sm:w-[477px] text-gray-333 p-4 font-semibold">
          {title}
        </h1> */}
        <Chart
          options={{
            dataLabels: {
              enabled: true,
              formatter(value) {
                if (datetimeCharts.includes(type)) {
                  const _val = +value;
                  return `${getTime(_val.toFixed(2))}`;
                }
                return `${value}`;
              },
            },
            yaxis: {
              labels: {
                formatter(value) {
                  if (datetimeCharts.includes(type)) {
                    return `${getTime(value.toFixed(2))}`;
                  }
                  return `${value}`;
                },
              },
              axisBorder: {
                show,
              },
              axisTicks: {
                show,
              },
              show,
            },
            grid: {
              xaxis: {
                lines: {
                  show,
                },
              },
              yaxis: {
                lines: {
                  show,
                },
              },
              show,
            },
            noData: {
              text: "Nenhum resultado",
              align: "center",
              verticalAlign: "middle",
              style: {
                fontSize: "14px",
                color: "#333333",
              },
            },
            theme: {
              mode: "light",
              palette: "palette1",
              monochrome: {
                enabled: false,
                color: "#F97316",
                shadeTo: "light",
                shadeIntensity: 0.65,
              },
            },
            colors: ["#F97316"],
            title: {
              // text: title,
              align: "left",
              margin: 25,
              floating: true,
              style: {
                fontSize: "16px",
                fontWeight: "700",
                fontFamily: undefined,
                color: "#F97316",
              },
            },
            chart: {
              foreColor: "#F97316",
              events: {
                mounted: (chart) => {
                  chart.windowResizeHandler();
                },
              },
              locales,
              defaultLocale: "pt-br",
            },
            xaxis: {
              categories: options,
              axisBorder: {
                show,
              },
            },
          }}
          series={_series}
          type="area"
          width={getWidth()}
          height="280"
        />
      </div>
      <div className="hidden md:flex">{sidebarStats({})}</div>
      <div className="flex md:hidden">{sidebarStats({ horizontal: true })}</div>
    </div>
  );
};

DashArea.defaultProps = {
  options: [],
};

export default DashArea;
