/* eslint-disable no-shadow */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import { ITeam } from "./teams.slice";
import { IUser } from "./users.slice";
import customToast from "../shared/utils/customToast";
import { IToastType } from "../shared/models/types/Toast.type";
import { commenttemplatesService } from "../services";
import { IFile } from "../shared/models/interfaces/file.interface";

export interface ICommenttemplate {
  // default?: boolean;
  active?: boolean;
  content?: string;
  // type?: string;
  _id?: string;
  name?: string;
  teams?: ITeam[];
  files?: IFile[] | File[] | string[];
  created_at?: string;
  updated_at?: string;
  deleted?: boolean;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
}

export interface IFilterCommenttemplate {
  skip: number;
  limit: number;
  filter: string;
  deleted: boolean;
  teams?: string[];
  allStatus?: boolean;
}

export interface INewCommenttemplate {
  name: string;
  team?: ITeam[] | [];
  deleted?: boolean;
  active?: boolean;
  content?: string;
}

export type Commenttemplates = ICommenttemplate[];

interface CommenttemplatesState extends AsyncState {
  isLoadingCommenttemplates: boolean;
  isLoadingDropdownCommenttemplates: boolean;
  isCreatingCommenttemplates: boolean;
  isRemoving: boolean;
  commenttemplates: ICommenttemplate[];
  totalCommenttemplates: number;
  selectedCommenttemplates: ICommenttemplate | null;
  selectedCommenttemplate: ICommenttemplate | null;
  selectedLocalCommenttemplate: ICommenttemplate | null;
}

const initialState: CommenttemplatesState = {
  commenttemplates: [],
  isLoadingCommenttemplates: false,
  isRemoving: false,
  isLoadingDropdownCommenttemplates: false,
  isCreatingCommenttemplates: false,
  totalCommenttemplates: 0,
  selectedCommenttemplates: null,
  selectedCommenttemplate: null,
  selectedLocalCommenttemplate: null,
  isSuccess: false,
  isError: false,
};

export const getSearchCommenttemplates = createAsyncThunk(
  "commenttemplates/search",
  async (filterCommenttemplate: IFilterCommenttemplate, thunkAPI) => {
    try {
      return await commenttemplatesService.search(filterCommenttemplate);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const getCommenttemplates = createAsyncThunk(
//   "commenttemplates",
//   async (_groupLimit: IGroupLimit, thunkAPI) => {
//     try {
//       return await commenttemplatesService.commenttemplates(_groupLimit);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const selectCommenttemplate = createAsyncThunk(
  "commenttemplates/selectCommenttemplate",
  async ({ _id }: { _id: string }, thunkAPI) => {
    try {
      return await commenttemplatesService.commenttemplate({ _id });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateCommenttemplate = createAsyncThunk(
  "commenttemplates/update",
  async (
    {
      _commenttemplate,
      noToast,
    }: { _commenttemplate: ICommenttemplate; noToast?: boolean },
    thunkAPI
  ) => {
    try {
      return await commenttemplatesService.update({ _commenttemplate });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteCommenttemplate = createAsyncThunk(
  "commenttemplates/delete",
  async (ids: string[], thunkAPI) => {
    try {
      return await commenttemplatesService.deleteCommenttemplates({ ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createCommenttemplate = createAsyncThunk(
  "commenttemplates/create",
  async (newCommenttemplate: INewCommenttemplate, thunkAPI) => {
    try {
      return await commenttemplatesService.create(newCommenttemplate);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const commenttemplatesSlice = createSlice({
  name: "commenttemplates",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    reselectCommenttemplates(
      state,
      action: PayloadAction<ICommenttemplate | null>
    ) {
      state.selectedCommenttemplates = action.payload;
    },
    reselectCommenttemplate(
      state,
      action: PayloadAction<ICommenttemplate | null>
    ) {
      state.selectedCommenttemplate = action.payload;
    },
    reselectLocalCommenttemplate(
      state,
      action: PayloadAction<ICommenttemplate | null>
    ) {
      state.selectedLocalCommenttemplate = action.payload;
    },
    selectCommenttemplates(state, action: PayloadAction<ICommenttemplate[]>) {
      state.commenttemplates = action.payload || [];
    },
    selectTotalCommenttemplates(state, action: PayloadAction<number>) {
      state.totalCommenttemplates = action.payload || 0;
    },
  },
  extraReducers: (builder) => {
    builder
      // OFFICEHOURS
      .addCase(getSearchCommenttemplates.pending, (state, action) => {
        if (action.meta.arg.limit === 10) {
          state.isLoadingCommenttemplates = true;
        }
        state.isLoadingDropdownCommenttemplates = true;
      })
      .addCase(getSearchCommenttemplates.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.commenttemplates = action.payload.results || [];
        // if (state.totalCommenttemplates <= action.payload.count)
        state.totalCommenttemplates = action.payload.count;
        state.isLoadingCommenttemplates = false;
        state.isLoadingDropdownCommenttemplates = false;
      })
      .addCase(getSearchCommenttemplates.rejected, (state) => {
        state.isError = true;
        state.commenttemplates = [];
        state.isLoadingCommenttemplates = false;
        state.isLoadingDropdownCommenttemplates = false;
      })
      // SHOW CUSTOMER
      .addCase(selectCommenttemplate.pending, (state) => {
        state.isLoadingCommenttemplates = true;
      })
      .addCase(selectCommenttemplate.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (action.payload?._id) {
          state.selectedCommenttemplate = action.payload;
        }
        state.isLoadingCommenttemplates = false;
      })
      .addCase(selectCommenttemplate.rejected, (state) => {
        state.isError = true;
        state.selectedCommenttemplates = null;
        state.isLoadingCommenttemplates = false;
      })
      // UPDATE CUSTOMER
      .addCase(updateCommenttemplate.pending, (/* state */) => {
        // state.isLoadingCommenttemplates = true;
      })
      .addCase(updateCommenttemplate.fulfilled, (state, action) => {
        if (
          action?.payload !== null &&
          typeof action?.payload?._id !== "undefined"
        ) {
          state.isSuccess = true;
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Alteração salva com sucesso!`,
            });
          }
          // } else if (
          //   typeof action.payload?.message === "string" &&
          //   action.payload.message.includes("Error on delete default office hour")
          // ) {
          //   if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
          //     customToast({
          //       type: IToastType.WARNING,
          //       message: "Horário definido como padrão!",
          //     });
          //   }
        } else {
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado!`,
            });
          }
          state.isError = true;
        }
        state.isLoadingCommenttemplates = false;
      })
      .addCase(updateCommenttemplate.rejected, (state) => {
        state.isError = true;
        state.selectedCommenttemplates = null;
        state.isLoadingCommenttemplates = false;
      })
      .addCase(createCommenttemplate.pending, (state) => {
        state.isCreatingCommenttemplates = true;
      })
      .addCase(createCommenttemplate.fulfilled, (state, action) => {
        if (typeof action?.payload?._id !== "undefined") {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Modelo criado com sucesso!`,
          });
          const newState = state.commenttemplates;
          newState.push(action.payload);
          state.commenttemplates = newState;
        } else {
          state.isError = true;
          if (
            typeof action?.payload === "string" &&
            action?.payload.includes("duplicate")
          ) {
            customToast({
              type: IToastType.ERROR,
              message: `Opa! Já existe um modelo com esse nome!`,
            });
          } else {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado! Tente novamente.`,
            });
          }
        }
        state.isCreatingCommenttemplates = false;
      })
      .addCase(createCommenttemplate.rejected, (state) => {
        state.isError = true;
        state.isCreatingCommenttemplates = false;
      })
      .addCase(deleteCommenttemplate.pending, (state) => {
        state.isRemoving = true;
      })
      .addCase(deleteCommenttemplate.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          typeof action.payload !== "undefined" &&
          typeof action.payload?.message !== "string"
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Remoção realizada com sucesso!`,
          });
          // } else if (
          //   typeof action.payload?.message === "string" &&
          //   action.payload.message.includes("Error on delete default office hour")
          // ) {
          //   customToast({
          //     type: IToastType.WARNING,
          //     message: "Horário definido como padrão!",
          //   });
        } else {
          state.isError = true;
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado!`,
          });
        }
        state.isRemoving = false;
      })
      .addCase(deleteCommenttemplate.rejected, (state) => {
        state.isError = true;
        state.isRemoving = false;
      });
  },
});

export default commenttemplatesSlice.reducer;
export const {
  logout,
  reselectCommenttemplates,
  reselectCommenttemplate,
  reselectLocalCommenttemplate,
  selectCommenttemplates,
  selectTotalCommenttemplates,
} = commenttemplatesSlice.actions;
