import React, { useEffect, useState } from "react";
import {
  Divider,
  Form,
  Tab,
  Menu,
  Dropdown,
  DropdownProps,
  Icon,
} from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useInput from "../../../../hooks/useInput";
import validateEmail from "../../../../shared/utils/validation/email";
import validatePassword from "../../../../shared/utils/validation/password";
import {
  agidesklogin,
  changeStatus,
  login,
  refreshLogout,
  reset,
  selectMethod,
  signin,
} from "../../../../slices/auth.slice";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux/hooks";
import LoadingDots from "../../../../shared/components/LoadingDots.component";
import {
  ILoginAgideskUser,
  ILoginUser,
  UserStatus,
  UserType,
} from "../../../../slices/users.slice";
import {
  validateNameLength,
  validatePasswordLength,
} from "../../../../shared/utils/validation/length";
import FormButton from "../../../../shared/components/Buttons/FormButton.component";
import ErrorLabel from "./ErrorLabel.component";
import LinkButton from "../../../../shared/components/Buttons/LinkButton.component";
import CommonError from "./CommonError.component";
// import reassignment from "../../../../shared/utils/reassignment";
// import { teamsService } from "../../../../services";
import { teamsChatsAssignment } from "../../../../slices/teams.slice";
import ShowHideButton from "../../../../shared/components/Buttons/ShowHideButton.component";
import { IToastType } from "../../../../shared/models/types/Toast.type";
import customToast from "../../../../shared/utils/customToast";

const LoginForm = ({ widgetType }: { widgetType?: UserType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/chat";
  const dispatch = useAppDispatch();

  const [_activeIndex, setActiveIndex] = useState<number>(
    location.pathname !== "/login-agidesk" ? 0 : 1
  );

  const {
    isLoadingAuth,
    // isSuccess,
    isAuthenticated,
    loginMethods,
    isError,
    isTenantError,
    isAgideskError,
    externalapp,
    selectedLoginMethod,
    tenantID,
  } = useAppSelector((state) => state.auth);

  const {
    text: tenant,
    shouldDisplayError: tenantHasError,
    textChangeHandler: tenantChangeHandler,
    inputBlurHandler: tenantBlurHandler,
    // clearHandler: tenantClearHandler,
  } = useInput(validateNameLength);
  const {
    text: email,
    shouldDisplayError: emailHasError,
    emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    // clearHandler: emailClearHandler,
  } = useInput(validateEmail);
  const {
    text: password,
    shouldDisplayError: passwordHasError,
    textChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    // clearHandler: passwordClearHandler,
  } = useInput(_activeIndex === 0 ? validatePassword : validatePasswordLength);

  // const clearForm = () => {
  //   tenantClearHandler();
  //   emailClearHandler();
  //   passwordClearHandler();
  // };

  const [showHide, setShowHide] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (location.pathname === "/company" && tenantID) {
      navigate("/login");
    }

    if (!tenantID) {
      navigate("/company");
      dispatch(refreshLogout());
      localStorage.clear();
    }
  }, [location.pathname, tenantID]);

  useEffect(() => {
    if (!isAuthenticated) return;
    navigate(from, { replace: true });
  }, [isAuthenticated]);

  const onSubmitTenant = async () => {
    const _tenant = tenant.toLowerCase().trim();
    if (
      loginMethods &&
      loginMethods.length > 0 &&
      tenantID &&
      _tenant.toLowerCase() === tenantID.toLowerCase()
    ) {
      navigate("/login");
    } else {
      if (tenantHasError) return;
      if (_tenant.length === 0) return;
      const { payload } = await dispatch(signin({ tenant: _tenant }));
      if (payload && typeof payload !== "undefined" && !payload.error) {
        customToast({
          message: "Empresa encontrada com sucesso!",
          type: IToastType.SUCCESS,
        });
        navigate(`/login`);
        setActiveIndex(0);
      }
    }
  };

  const onSubmitAgitalksLogin = async () => {
    if (!tenantID || emailHasError || passwordHasError) return;
    if (!tenantID || email.length === 0 || password.length === 0) return;

    const loginUser: ILoginUser = {
      tenant: !tenantID
        ? tenant.toLowerCase().trim()
        : tenantID.toLowerCase().trim(),
      email: email.toLowerCase().trim(),
      password: password.trim(),
      widget: widgetType === UserType.AGENT ? "agent" : undefined,
    };

    const { payload } = await dispatch(login(loginUser));
    if (payload && typeof payload !== "string") {
      await dispatch(changeStatus({ status: UserStatus.ONLINE }));
      await dispatch(teamsChatsAssignment());
    }
  };

  const onSubmitAgideskLogin = async () => {
    if (!tenantID || emailHasError || passwordHasError) return;
    if (!tenantID || email.length === 0 || password.length <= 5) return;

    const loginUser: ILoginAgideskUser = {
      tenant: !tenantID
        ? tenant.toLowerCase().trim()
        : tenantID.toLowerCase().trim(),
      username: email,
      password,
      externalapp: externalapp || undefined,
    };

    if (externalapp && externalapp?.length > 0) {
      await dispatch(agidesklogin(loginUser));
    }
  };

  const goToCompany = async () => {
    dispatch(refreshLogout());
    dispatch(reset());
    localStorage.clear();
    navigate("/company");
  };

  const goToRecovery = async () => {
    navigate("/recovery");
    dispatch(reset());
  };

  const handleShowHide = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    setShowHide((prevState) => !prevState);
  };

  const [selectedOption, setSelectedOption] = useState<string>(
    loginMethods && loginMethods.length > 0
      ? loginMethods[0]?.fields?.displaytitlelogin ||
          loginMethods[0]?.fields?.name
      : ""
  );

  const handleDropdownChange = (
    e: React.SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    dispatch(reset());
    setIsOpen(false);
    setSelectedOption(value as string);
    const _method = loginMethods?.find(
      (method) =>
        method?.fields?.displaytitlelogin === value ||
        method?.fields?.name === value
    );
    setActiveIndex(1);
    if (_method?._id) {
      dispatch(
        selectMethod(_method._id === selectedLoginMethod?._id ? null : _method)
      );
    }
  };

  const handleTabChange = (e: React.SyntheticEvent, { activeIndex }: any) => {
    try {
      if (activeIndex === 0) {
        dispatch(selectMethod(null));
      } else {
        const _method = loginMethods?.find(
          (method) =>
            method?.fields?.displaytitlelogin === selectedOption ||
            method?.fields?.name === selectedOption
        );
        if (_method?._id) {
          dispatch(
            selectMethod(
              _method._id === selectedLoginMethod?._id ? null : _method
            )
          );
        }
      }
      if (activeIndex === 0) {
        navigate("/login");
      } else {
        navigate("/login-agidesk");
      }
      setActiveIndex(activeIndex);
    } catch (error) {
      // console.log(error);
    }
  };

  const _getEmail = () => {
    const _email = {
      menuItem: {
        key: "email",
        content: (
          <Menu.Item
            disabled={!tenantID}
            onClick={() => {
              dispatch(reset());
              navigate("/login");
            }}
            key="email"
            className="custom-tab"
          >
            <p>Entrar pelo e-mail</p>
          </Menu.Item>
        ),
      },
      render: () => (
        <Tab.Pane className="custom-tab-pane">
          <Form.Input
            disabled={!tenantID}
            className={tenantHasError ? "!mt-3" : "!mt-0"}
            label={t("form.label.email")}
            value={email}
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            error={emailHasError}
            type="email"
            name="email"
            autoComplete="new-password"
            id="email"
            variant="outlined"
            placeholder={t("form.placeholder.email")}
          />
          {emailHasError && (
            <ErrorLabel error={emailHasError} label={t("form.error.email")} />
          )}
          <Form.Input
            disabled={!tenantID}
            className={emailHasError ? "!mt-3" : "!mt-0"}
            label={t("form.label.password")}
            value={password}
            onChange={passwordChangeHandler}
            onBlur={passwordBlurHandler}
            error={passwordHasError}
            type={showHide ? "input" : "password"}
            name="password"
            autoComplete="new-password"
            id="password"
            variant="outlined"
            placeholder={t("form.placeholder.password")}
            action
          >
            <input />
            <ShowHideButton
              disabled={!tenantID}
              title={`${!showHide ? "Mostrar" : "Esconder"} ${t(
                "form.label.password"
              ).toLowerCase()}`}
              hide={showHide}
              onClick={handleShowHide}
            />
          </Form.Input>
          {passwordHasError ? (
            <ErrorLabel
              error={passwordHasError}
              label={t("form.error.password")}
            />
          ) : null}
          <div className="flex">
            <LinkButton
              disabled={!tenantID}
              label={t("linkbutton.label.recovery")}
              onClick={goToRecovery}
            />
          </div>
          {isError && (email.length === 0 || password.length === 0) ? (
            <CommonError message="E-mail e/ou senha inválidos" />
          ) : null}

          <FormButton
            onClick={() => {
              onSubmitAgitalksLogin();
            }}
            // TODO: Validate mb on button
            className={`flex flex-wrap justify-center ${
              passwordHasError ? "mt-4" : "mt-4"
            }`}
            disabled={
              !tenantID ||
              email.length === 0 ||
              password.length === 0 ||
              emailHasError ||
              passwordHasError ||
              false
            }
            label={t("formbutton.label.login")}
          />
        </Tab.Pane>
      ),
    };
    return _email;
  };

  const _getAgidesk = () => {
    const _agidesk = {
      menuItem: (
        <Menu.Item
          disabled={!tenantID}
          onClick={() => {
            if (loginMethods?.length === 1) {
              if (loginMethods[0]?._id) {
                dispatch(
                  selectMethod(
                    loginMethods[0]._id === selectedLoginMethod?._id
                      ? null
                      : loginMethods[0]
                  )
                );
              }
            }
            setActiveIndex(1);
            dispatch(reset());
            navigate("/login-agidesk");
          }}
          key="dropdown"
          className="custom-tab"
        >
          <Dropdown
            text={selectedOption}
            disabled={!tenantID || loginMethods?.length === 1}
            search
            deburr
            selectOnBlur={false}
            className="custom-dropdown"
            options={loginMethods?.map((_method) => ({
              key: _method._id,
              text: _method?.fields?.displaytitlelogin || _method?.fields?.name,
              value:
                _method?.fields?.displaytitlelogin || _method?.fields?.name,
            }))}
            placeholder="Escolha uma integração"
            noResultsMessage="Nenhuma integração habilitada"
            onChange={handleDropdownChange}
            onBlur={() => {
              setIsOpen(false);
            }}
            onClick={() => {
              setIsOpen(true);
              setActiveIndex(1);
              dispatch(reset());
              navigate("/login-agidesk");
            }}
            icon={null}
            // iconPosition="left"
          />
          <Icon
            name="dropdown"
            className={`!-ml-2 rotate ${isOpen ? "open" : ""}`}
          />
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane className="custom-tab-pane">
          <Form.Input
            disabled={!tenantID}
            className={tenantHasError ? "!mt-3" : "!mt-0"}
            label={t("form.label.email")}
            value={email}
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            error={emailHasError}
            type="email"
            name="email"
            autoComplete="new-password"
            id="email"
            variant="outlined"
            placeholder={t("form.placeholder.email")}
          />
          {emailHasError && (
            <ErrorLabel error={emailHasError} label={t("form.error.email")} />
          )}
          <Form.Input
            disabled={!tenantID}
            className={emailHasError ? "!mt-3" : "!mt-0"}
            label={t("form.label.password")}
            value={password}
            onChange={passwordChangeHandler}
            onBlur={passwordBlurHandler}
            error={passwordHasError}
            type={showHide ? "input" : "password"}
            name="password"
            autoComplete="new-password"
            id="password"
            variant="outlined"
            placeholder={t("form.placeholder.password")}
            action
          >
            <input />
            <ShowHideButton
              disabled={!tenantID}
              title={`${!showHide ? "Mostrar" : "Esconder"} ${t(
                "form.label.password"
              ).toLowerCase()}`}
              hide={showHide}
              onClick={handleShowHide}
            />
          </Form.Input>
          {passwordHasError ? (
            <ErrorLabel
              error={passwordHasError}
              label={t("form.error.password")}
            />
          ) : null}
          <div className="flex">
            <LinkButton
              disabled={!tenantID}
              label={t("linkbutton.label.recovery")}
              onClick={goToRecovery}
            />
          </div>
          {isAgideskError && (email.length === 0 || password.length === 0) ? (
            <CommonError message="E-mail e/ou senha inválidos" />
          ) : null}

          <FormButton
            onClick={() => {
              onSubmitAgideskLogin();
            }}
            // TODO: Validate mb on button
            className={`flex flex-wrap justify-center ${
              passwordHasError ? "mt-4" : "mt-4"
            }`}
            disabled={!tenantID || email.length === 0 || password.length <= 5}
            label={t("formbutton.label.login")}
          />
        </Tab.Pane>
      ),
    };
    return _agidesk;
  };

  const panes =
    loginMethods && loginMethods?.length > 0
      ? [_getEmail(), _getAgidesk()]
      : [_getEmail()];

  if (isLoadingAuth)
    return <LoadingDots className="flex justify-center items-center" />;
  return (
    <Form className="bg-white h-full rounded-xl flex flex-col -mt-[16px]">
      {widgetType !== UserType.AGENT ? (
        <>
          <Form.Field inline className="flex items-end !w-full">
            <Form.Input
              className="w-full"
              label={t("form.label.tenant")}
              value={
                location.pathname === "company" ? tenant : tenantID || tenant
              }
              disabled={
                location.pathname !== "company"
                  ? !!tenantID && tenantID.length > 0
                  : false
              }
              onChange={!tenantID ? tenantChangeHandler : undefined}
              onBlur={!tenantID ? tenantBlurHandler : null}
              error={!tenantID ? tenantHasError : null}
              type="text"
              variant="outlined"
              placeholder={t("form.placeholder.tenant-login")}
            />
            <FormButton
              disabled={false}
              className="secondary-button-eased"
              type={tenantID ? "button" : "submit"}
              label={tenantID ? "Trocar ID" : "Buscar"}
              onClick={() => {
                if (tenantID) {
                  goToCompany();
                } else {
                  onSubmitTenant();
                }
              }}
            />
          </Form.Field>
          {tenantHasError && (
            <ErrorLabel error={tenantHasError} label={t("form.error.tenant")} />
          )}
          {isTenantError && (email.length === 0 || password.length === 0) ? (
            <CommonError extraClass="!mt-0" message="Empresa não encontrada" />
          ) : null}

          <Divider className="my-6" />
        </>
      ) : null}

      {loginMethods && loginMethods.length > 0 ? (
        <Tab
          menu={{ secondary: true, pointing: true, widths: panes.length }}
          panes={panes}
          activeIndex={_activeIndex}
          renderActiveOnly
          onTabChange={handleTabChange}
        />
      ) : (
        <>
          <Form.Input
            disabled={!tenantID}
            className={tenantHasError ? "!mt-3" : "!mt-0"}
            label={t("form.label.email")}
            value={email}
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            error={emailHasError}
            type="email"
            name="email"
            autoComplete="new-password"
            id="email"
            variant="outlined"
            placeholder={t("form.placeholder.email")}
          />
          {emailHasError && (
            <ErrorLabel error={emailHasError} label={t("form.error.email")} />
          )}
          <Form.Input
            disabled={!tenantID}
            className={emailHasError ? "!mt-3" : "!mt-0"}
            label={t("form.label.password")}
            value={password}
            onChange={passwordChangeHandler}
            onBlur={passwordBlurHandler}
            error={passwordHasError}
            type={showHide ? "input" : "password"}
            name="password"
            autoComplete="new-password"
            id="password"
            variant="outlined"
            placeholder={t("form.placeholder.password")}
            action
          >
            <input />
            <ShowHideButton
              disabled={!tenantID}
              title={`${!showHide ? "Mostrar" : "Esconder"} ${t(
                "form.label.password"
              ).toLowerCase()}`}
              hide={showHide}
              onClick={handleShowHide}
            />
          </Form.Input>
          {passwordHasError ? (
            <ErrorLabel
              error={passwordHasError}
              label={t("form.error.password")}
            />
          ) : null}
          <div className="flex">
            <LinkButton
              disabled={!tenantID}
              label={t("linkbutton.label.recovery")}
              onClick={goToRecovery}
            />
          </div>
          {isError && (email.length === 0 || password.length === 0) ? (
            <CommonError message="E-mail e/ou senha inválidos" />
          ) : null}

          <FormButton
            onClick={() => {
              onSubmitAgitalksLogin();
            }}
            // TODO: Validate mb on button
            className={`flex flex-wrap justify-center ${
              passwordHasError ? "mt-4" : "mt-4"
            }`}
            disabled={
              !tenantID ||
              email.length === 0 ||
              password.length === 0 ||
              emailHasError ||
              passwordHasError ||
              false
            }
            label={t("formbutton.label.login")}
          />
        </>
      )}
    </Form>
  );
};

LoginForm.defaultProps = {
  widgetType: UserType.NONE,
};

export default LoginForm;
