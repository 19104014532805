/* eslint-disable react/button-has-type */
import React from "react";

const FormButton = ({
  disabled,
  className,
  label,
  onClick,
  icon,
  logout,
  type,
}: {
  disabled: boolean;
  className: string;
  label: any;
  onClick?: () => void;
  icon?: string;
  logout?: boolean;
  type?: any;
}) => {
  const defaultColor = logout
    ? "disabled:bg-disable bg-red-500 hover:bg-red-600"
    : "disabled:bg-orange-200 bg-orange-500 hover:bg-orange-600";

  if (className === "secondary-button-eased") {
    return (
      <div
        role="presentation"
        onClick={onClick}
        className={
          disabled
            ? "secondary-button-eased-disabled secondary-button-eased-label-disabled"
            : "secondary-button-eased secondary-button-eased-label"
        }
      >
        <button
          className="secondary-button-eased-label disabled:secondary-button-eased-label-disabled"
          type={type || "submit"}
          disabled={disabled}
          // onClick={onClick}
        >
          {icon ? <i className={icon} /> : null}
          {label}
        </button>
      </div>
    );
  }
  return (
    <div className={className}>
      <button
        type={type || "submit"}
        disabled={disabled}
        onClick={onClick}
        className={`gap-[10px] py-3 disabled:cursor-default disabled:text-white text-md rounded-[20px] border-none text-white inline-flex justify-center items-center w-full h-[38px] hover:cursor-pointer hover:text-white ${defaultColor}`}
      >
        {icon ? <i className={icon} /> : null}
        {label}
      </button>
    </div>
  );
};

FormButton.defaultProps = {
  onClick: undefined,
  icon: undefined,
  logout: false,
  type: "submit",
};

export default FormButton;
