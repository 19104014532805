import { ITeam } from "../slices/teams.slice";
import instances from "./instances";

const team = async ({ _id }: { _id: string }) => {
  try {
    let _team = null;
    const response = await instances.instanceTeams.get(`/${_id}`);
    if (response.data) {
      const _users = await instances.instanceUsers.get(`/team/${_id}`);
      _team = response.data;
      _team = { ..._team, users: _users.data };
      return _team;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const update = async ({ _team }: { _team: ITeam }) => {
  try {
    const data = _team;
    const response = await instances.instanceTeams.patch(`${_team._id}`, data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const updateUsers = async ({
  _team,
  users,
}: {
  _team: string;
  users: string[];
}) => {
  try {
    const data = { users };
    const response = await instances.instanceTeams.patch(
      `${_team}/users`,
      data
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const reassignment = async () => {
  try {
    const response = await instances.instanceTeams.post("/chatassignment");
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const assignment = async ({
  teamid,
  contactid,
  lastuserid,
}: {
  teamid: string;
  contactid?: string;
  lastuserid?: string;
}) => {
  try {
    const data = {
      team: teamid,
      contact: contactid,
      lastuser: lastuserid,
    };
    const response = await instances.instanceTeams.post("/assignment", data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const search = async ({
  skip,
  limit,
  filter,
  deleted,
  allStatus,
  withChatusers,
  transferred,
}: {
  skip?: number;
  limit?: number;
  filter: string;
  deleted: boolean;
  allStatus?: boolean;
  withChatusers?: boolean;
  transferred?: boolean;
}) => {
  try {
    const _filter = `{"name":"${filter}"${
      withChatusers ? `,"haschatusers":true` : ""
    }${transferred ? `,"transferred":true` : ""}${
      deleted ? `,"deleted":true` : ""
    }${allStatus ? `,"active":false` : ""}}`;
    const response = await instances.instanceTeams.get("", {
      params: {
        skip: skip || 0,
        limit: limit || 10,
        filter: _filter,
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const postChatusersByTeamId = async ({
  _id,
  campaign,
  offline,
}: {
  _id: string;
  campaign?: boolean;
  offline?: boolean;
}) => {
  try {
    const response = await instances.instanceTeams.post(`${_id}/chatusers`, {
      campaign,
      offline,
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const syncTeams = async ({ skip, limit }: { skip: number; limit: number }) => {
  try {
    const response = await instances.instanceTeams.post("/syncteams");
    if ([200, 201, "200", "201"].includes(response.status)) {
      return await search({ skip, limit, filter: "", deleted: false });
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const deleteTeams = async ({ ids }: { ids: string[] }) => {
  try {
    const data = { id: ids };
    const response = await instances.instanceTeams.delete("", { data });
    return response.data;
  } catch (error) {
    // console.log(error);
    return error;
  }
};

const create = async ({ name }: { name: string }) => {
  try {
    const data = {
      name,
    };
    const response = await instances.instanceTeams.post("", data);
    if (response && response.data && !response.data.message) {
      return response.data;
    }
    return response.data.message;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const chatUsers = async ({
  user,
  _team,
  action,
  lastassigned,
}: {
  user: string;
  _team: string;
  action?: string;
  lastassigned?: boolean;
}) => {
  try {
    const data = {
      user,
      team: _team,
      action,
      lastassigned,
    };
    const response = await instances.instanceTeams.post("/chatusers", data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const teamsService = {
  reassignment,
  team,
  postChatusersByTeamId,
  update,
  deleteTeams,
  updateUsers,
  chatUsers,
  syncTeams,
  create,
  search,
  assignment,
};

export default teamsService;
