import React from "react";
import logo from "../../../assets/images/agitalks_logo.svg";
import bg from "../../../assets/images/bg-nochat.png";

const NoChatSelectedComponent = () => {
  return (
    <div className="bg-white w-full flex-1 text-wrap px-20 relative flex flex-col text-3xl items-center justify-center">
      <img
        src={bg}
        alt="bg"
        className="hidden lg:flex absolute right-0 bottom-0 z-[1] h-full"
      />
      <img src={logo} alt="logo" className="flex items-center mb-8 z-[2]" />
      <span className="z-[2] text-center text-[20px] text-gray-777 font-semibold">
        <span className="text-gray-333">Selecione uma conversa</span> para
        iniciar o atendimento
        <br /> ou clique no botão “
        <span className="text-gray-333">Nova conversa</span>”
      </span>
    </div>
  );
};

export default NoChatSelectedComponent;
