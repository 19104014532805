import React, { useEffect, useRef, useState } from "react";
import { format } from "util";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form } from "semantic-ui-react";
import { IUser } from "../../../../../slices/users.slice";
import ChatDetailsCard from "../Details/ChatDetailsCard.component";
import { Header } from "../../../../../shared/components/Sidebars";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux/hooks";
import NewTicket from "./Tickets/New.component";
import LastTicket from "./Tickets/Last.component";
import Button from "../../../../../shared/components/Buttons/Button.component";
import {
  IAgideskTicket,
  searchAgideskTicket,
  createAgideskTicket,
  ticketIsCreating,
  IAgideskNewTicket,
  ticketIsUpdating,
  IAgideskUpdateTicket,
  updateAgideskTicket,
  IAgideskTicketFilter,
} from "../../../../../slices/externalapps.slice";
import Toggle from "../../../../../shared/components/Toggle.component";
import ActionButtonsModal from "../../../../../shared/components/Modals/ActionButtonsModal.component";
import {
  INewMessage,
  INewMessageBotmaker,
  INewMessageWpp,
  MessageType,
  createMessage,
  sendMessageBotmaker,
  sendMessageWpp,
} from "../../../../../slices/messages.slice";
import {
  ChatStatus,
  ChatType,
  checkingMessages,
} from "../../../../../slices/chats.slice";
import customToast from "../../../../../shared/utils/customToast";
import { IToastType } from "../../../../../shared/models/types/Toast.type";
import { externalappsService, messagesService } from "../../../../../services";
import UpdateTicket from "./Tickets/Update.component";

const ChatAgidesk = ({
  chatContact,
  setVisible,
  actions,
  setActions,
  setLastUrl,
  visible,
  disable,
  setDisable,
}: {
  visible: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  actions: {
    show: boolean;
    update: boolean;
    newTicket: boolean;
  };
  setActions: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      update: boolean;
      newTicket: boolean;
    }>
  >;
  chatContact: IUser | null | undefined;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setLastUrl: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { selectedExternalAppAgidesk, isCreatingTicket, isUpdatingTicket } =
    useAppSelector((state) => state.externalapps);
  const { selectedChat, checkingChatMessages } = useAppSelector(
    (state) => state.chats
  );
  const [tickets, setTickets] = useState<IAgideskTicket[]>([]);
  const [ticketsOnlyThisChat, setTicketsOnlyThisChat] =
    useState<boolean>(false);

  const [ticketsIncludeFollower, setTicketsIncludeFollower] =
    useState<boolean>(false);
  const [ticket, setTicket] = useState<IAgideskNewTicket>({
    services: "",
    contacts: chatContact?._id || "",
    title: selectedExternalAppAgidesk?.fields?.ticketinfo?.title || "",
    htmlcontent: selectedExternalAppAgidesk?.fields?.ticketinfo?.subject || "",
    addchat:
      selectedExternalAppAgidesk?.fields?.ticketinfo?.includechat || false,
    internal: selectedExternalAppAgidesk?.fields?.ticketinfo?.internal || false,
    openingsolved:
      selectedExternalAppAgidesk?.fields?.ticketinfo?.openingsolved || false,
    chat: selectedChat?._id || "",
    files: [],
    messages: checkingChatMessages.selectedMessageIDs,
  });
  const [ticketUpdate, setTicketUpdate] = useState<IAgideskUpdateTicket>({
    contacts: chatContact?._id || "",
    tasks: "",
    tasktitle: "",
    htmlcontent: "",
    addchat: false,
    chat: selectedChat?._id || "",
    files: [],
    internal: false,
    messages: checkingChatMessages.selectedMessageIDs,
  });
  const [files, setFiles] = useState<File[]>([]);

  const _searchTicketParameters = useRef<IAgideskTicketFilter>({
    chat: selectedChat?._id || "",
    contacts: chatContact?._id || "",
    id: selectedExternalAppAgidesk._id || undefined,
    priority: ticketsOnlyThisChat,
    follower: ticketsIncludeFollower,
  });

  useEffect(() => {
    if (location.hash === "#tickets") {
      setActions({
        show: true,
        update: false,
        newTicket: false,
      });
    }
  }, [location]);

  useEffect(() => {
    if ((actions.show || actions.update) && visible) {
      _searchTicketParameters.current = {
        ..._searchTicketParameters.current,
        priority: ticketsOnlyThisChat,
        follower: ticketsIncludeFollower,
      };

      (async () => {
        const { payload } = await dispatch(
          searchAgideskTicket({
            search: _searchTicketParameters.current,
          })
        );
        if (!payload.error) {
          setTickets(payload);
        }
      })();
    }
  }, [
    actions.show,
    actions.update,
    visible,
    ticketsOnlyThisChat,
    ticketsIncludeFollower,
  ]);

  useEffect(() => {
    if (!isCreatingTicket) {
      setTicket({
        services: "",
        contacts: chatContact?._id || "",
        title: selectedExternalAppAgidesk?.fields?.ticketinfo?.title || "",
        htmlcontent:
          selectedExternalAppAgidesk?.fields?.ticketinfo?.subject || "",
        addchat:
          selectedExternalAppAgidesk?.fields?.ticketinfo?.includechat || false,
        internal:
          selectedExternalAppAgidesk?.fields?.ticketinfo?.internal || false,
        openingsolved:
          selectedExternalAppAgidesk?.fields?.ticketinfo?.openingsolved ||
          false,
        chat: selectedChat?._id || "",
        messages: checkingChatMessages.selectedMessageIDs || [],
        files: [],
      });
      setFiles([]);
    }
  }, [isCreatingTicket]);

  useEffect(() => {
    if (!isUpdatingTicket) {
      setTicketUpdate({
        tasks: "",
        contacts: chatContact?._id || "",
        addchat: false,
        htmlcontent: "",
        internal: false,
        openingsolved: false,
        chat: selectedChat?._id || "",
        files: [],
        messages: checkingChatMessages.selectedMessageIDs || [],
        tasktitle: "",
      });
      setFiles([]);
    }
  }, [isUpdatingTicket]);

  const goDisabled = () => {
    if (actions.newTicket) {
      return (
        disable || ticket.services.length === 0 || ticket.title.length === 0
      );
    }
    if (actions.update) {
      return (
        disable ||
        ticketUpdate.tasks.length === 0 ||
        ticketUpdate.htmlcontent.length === 0
      );
    }
    return disable;
  };

  const goBack = () => {
    if (!actions.show) {
      setActions({ newTicket: false, update: false, show: true });
      navigate(`${location.pathname}#tickets`);
    }
  };

  const createFiles = async ({ update }: { update?: boolean }) => {
    const getMessageError = (_files: string) => {
      if (_files.toLowerCase().includes("unsupported")) {
        return t("toast.error.files.unsupported");
      }
      if (_files.toLowerCase().includes("too large"))
        return t("toast.error.files.too-large");
      return t("toast.error.files.upload");
    };

    const _files: string[] | string | null =
      await externalappsService.uploadFile({
        _files: files,
        id: selectedExternalAppAgidesk._id,
      });
    if (typeof _files === "string") {
      customToast({ type: IToastType.ERROR, message: getMessageError(_files) });
      return [];
    }

    if (
      _files &&
      typeof _files === "object" &&
      _files !== null &&
      _files?.length > 0
    ) {
      if (typeof update !== "undefined" && update) {
        setTicketUpdate({
          ...ticketUpdate,
          files: _files,
        });
      } else {
        setTicket({
          ...ticket,
          files: _files,
        });
      }
      return _files;
    }
    return [];
  };

  const sendInternalMessage = async (
    _ticket: {
      id: string;
      title: string;
      internal: boolean;
    },
    update?: boolean
  ) => {
    const agideskContactUrl = `${selectedExternalAppAgidesk?.fields?.url}/atendimento/${_ticket?.id}`;
    const agideskUrl = `${selectedExternalAppAgidesk?.fields?.url}/painel/atendimento/${_ticket?.id}`;

    const agitalksUserMessage =
      user?.name && _ticket?.id && _ticket?.title
        ? format(
            t(
              typeof update !== "undefined" && update
                ? "chat-agidesk.update.internal-message.agitalks.externallink"
                : "chat-agidesk.new.internal-message.agitalks.externallink"
            ),
            user.name,
            agideskUrl,
            _ticket?.id,
            _ticket?.title
          )
        : "";

    const agitalksContactMessage =
      user?.name && _ticket?.id && _ticket?.title
        ? format(
            t(
              typeof update !== "undefined" && update
                ? "chat-agidesk.update.internal-message.agitalks.externallink"
                : "chat-agidesk.new.internal-message.agitalks.externallink"
            ),
            user.name,
            agideskContactUrl,
            _ticket?.id,
            _ticket?.title
          )
        : "";

    const whatsappMessage =
      user?.name && _ticket?.id && _ticket?.title
        ? format(
            t(
              typeof update !== "undefined" && update
                ? "chat-agidesk.update.internal-message.whatsapp.text"
                : "chat-agidesk.new.internal-message.whatsapp.text"
            ),
            user.name,
            _ticket?.id,
            _ticket?.title,
            agideskContactUrl
          )
        : "";

    if (typeof selectedChat?._id !== "undefined") {
      const newMessage: INewMessage = {
        chat: selectedChat,
        content: agitalksUserMessage,
        files: [],
        parent: null,
        internal: true,
        read: true,
        type: MessageType.USER,
      };
      if (selectedChat?.status !== ChatStatus.FINISH) {
        const { payload } = await dispatch(createMessage(newMessage));
        if (
          payload !== null &&
          typeof payload?.chat?._id !== "undefined" &&
          !_ticket.internal &&
          payload.chat.type !== ChatType.INTERNAL
        ) {
          const newContactMessage: INewMessage = {
            chat: selectedChat,
            content: agitalksContactMessage,
            files: [],
            parent: null,
            internal: true,
            read: true,
            type: MessageType.CONTACT,
          };
          const actionContactMessage = await dispatch(
            createMessage(newContactMessage)
          );
          const defaultMessageContent = {
            message_id: actionContactMessage?.payload?._id,
            to:
              `${
                selectedChat?.users?.find(
                  (_user: IUser) => _user._id !== user?._id
                )?.phone
              }` || "",
            type: "text",
            recipient_type: "individual",
          };
          const _newMessage =
            // eslint-disable-next-line no-nested-ternary
            actionContactMessage?.payload?.chat?.type === ChatType.WHATSAPP
              ? ({
                  ...defaultMessageContent,
                  messaging_product: "whatsapp",
                  externalapp:
                    actionContactMessage?.payload?.chat?.externalapp?._id ||
                    actionContactMessage?.payload?.chat?.externalapp ||
                    undefined,
                  text: {
                    preview_url: true,
                    body: whatsappMessage,
                  },
                } as INewMessageWpp)
              : actionContactMessage?.payload?.chat?.type === ChatType.BOTMAKER
              ? ({
                  ...defaultMessageContent,
                  messaging_product: "botmaker",
                  message: {
                    text: whatsappMessage,
                  },
                  externalapp: actionContactMessage?.payload?.chat?.externalapp,
                  externalcode:
                    actionContactMessage?.payload?.chat?.externalcode,
                  externalcodephone:
                    actionContactMessage?.payload?.chat?.externalcodephone,
                } as INewMessageBotmaker)
              : {};

          if (actionContactMessage?.payload?.chat?.type === ChatType.WHATSAPP) {
            await dispatch(sendMessageWpp(_newMessage as INewMessageWpp));
          } else if (
            actionContactMessage?.payload?.chat?.type === ChatType.BOTMAKER
          ) {
            await dispatch(
              sendMessageBotmaker(_newMessage as INewMessageBotmaker)
            );
          }
        }
      } else {
        await messagesService.create(newMessage);
      }
    }
  };

  const createTicket = async () => {
    setDisable(true);
    const _newTicket = {
      id: "",
      title: "",
      internal: false,
    };
    if (files.length > 0) {
      const _files = await createFiles({});
      const { payload } = await dispatch(
        createAgideskTicket({
          id: selectedExternalAppAgidesk._id,
          ticket: {
            ...ticket,
            files: _files,
          },
        })
      );
      const _ticket = payload?.items?.slice(-1).pop();
      if (typeof _ticket !== "undefined") {
        _newTicket.id = _ticket?.id;
        _newTicket.title = _ticket?.title;
        _newTicket.internal = _ticket?.internal;
      }
    } else {
      const { payload } = await dispatch(
        createAgideskTicket({
          id: selectedExternalAppAgidesk._id,
          ticket,
        })
      );
      const _ticket = payload?.items?.slice(-1).pop();
      if (typeof _ticket !== "undefined") {
        _newTicket.id = _ticket?.id;
        _newTicket.title = _ticket?.title;
        _newTicket.internal = ticket.internal || false;
      }
    }
    if (typeof _newTicket?.id !== "undefined" && _newTicket.id.length > 0) {
      await sendInternalMessage(_newTicket);
    }
    dispatch(checkingMessages({ checking: false, selectedMessages: [] }));
    setDisable(false);
  };

  const updateTicket = async () => {
    setDisable(true);
    const _updateTicket = {
      id: ticketUpdate.tasks,
      title: ticketUpdate.tasktitle || "",
      internal: ticketUpdate.internal || false,
    };
    let _files: string[] = [];
    if (files.length > 0) {
      _files = await createFiles({ update: true });
    }
    const { payload } = await dispatch(
      updateAgideskTicket({
        id: selectedExternalAppAgidesk._id,
        ticket: {
          ...ticketUpdate,
          tasktitle: undefined,
          files: _files,
        },
      })
    );
    if (typeof payload?.id !== "undefined" && payload.id.length > 0) {
      await sendInternalMessage(_updateTicket, true);
    } else if (setVisible) setVisible(false);
    dispatch(checkingMessages({ checking: false, selectedMessages: [] }));
    setTicketUpdate({
      ...ticketUpdate,
      files: [],
      htmlcontent: "",
      addchat: false,
      internal: false,
      openingsolved: false,
      tasks: "",
      tasktitle: "",
    });
    setDisable(false);
  };

  const submitAction = async () => {
    try {
      if (setDisable) setDisable(true);
      if (actions.newTicket) {
        dispatch(ticketIsCreating(true));
        if (!selectedChat) {
          if (setDisable) setDisable(false);
          return;
        }
        await createTicket();
        dispatch(ticketIsCreating(false));
      } else if (actions.update) {
        dispatch(ticketIsUpdating(true));
        if (!selectedChat) {
          if (setDisable) setDisable(false);
          return;
        }
        await updateTicket();
        dispatch(ticketIsUpdating(false));
      }
      if (setDisable) setDisable(false);
      goBack();
    } catch (error) {
      // console.log(error);
    }
  };

  const getPageComponent = () => {
    if (actions.show) {
      return <LastTicket tickets={tickets} />;
    }
    if (actions.update) {
      return (
        <UpdateTicket
          searchTicketParameters={_searchTicketParameters.current}
          tickets={tickets}
          // setTickets={setTickets}
          ticket={ticketUpdate}
          setTicket={setTicketUpdate}
          files={files}
          setFiles={setFiles}
        />
      );
    }
    return (
      <NewTicket
        ticket={ticket}
        setTicket={setTicket}
        files={files}
        setFiles={setFiles}
      />
    );
  };

  const getHeaderTitle = () => {
    if (actions.show) {
      return t("chat-agidesk.title.last");
    }
    if (actions.newTicket) {
      return t("chat-agidesk.title.new");
    }
    return t("chat-agidesk.title.update");
  };

  return (
    <div className="flex-container p-3">
      <div className="header">
        <Header
          icon="agidesk"
          title={
            selectedExternalAppAgidesk?.fields?.displaytitlechat ||
            selectedExternalAppAgidesk?.fields?.name
          }
          setVisible={setVisible}
        />
      </div>
      <div className="px-4">
        <ChatDetailsCard chatContact={chatContact} setLastUrl={setLastUrl} />
        <div className="flex items-center">
          <i className="las la-life-ring text-[18px] mr-2" />
          <span className="font-semibold text-[14px]">{getHeaderTitle()}</span>
        </div>
        {actions.show || actions.update ? (
          <Form>
            <Form.Group>
              <Toggle
                containerClass="flex ml-1 mt-2"
                checked={ticketsOnlyThisChat}
                mainClass="scale-[80%]"
                onChange={(_, { checked }) => {
                  if (typeof checked !== "undefined") {
                    if (checked === true) {
                      setTicketsIncludeFollower(false);
                    }
                    setTicketsOnlyThisChat(checked);
                  }
                }}
                labelClass="ml-2 mb-1.5 text-[12px]"
                label={t("toggle.chat-agidesk.onlythischat")}
              />
            </Form.Group>
            <Form.Group>
              <Toggle
                containerClass="flex ml-1 -mt-2"
                mainClass="items-center flex scale-[80%]"
                checked={ticketsIncludeFollower}
                onChange={(_, { checked }) => {
                  if (typeof checked !== "undefined") {
                    if (checked === true) {
                      setTicketsOnlyThisChat(false);
                    }
                    setTicketsIncludeFollower(checked);
                  }
                }}
                labelClass="ml-2 text-[12px]"
                label={t("toggle.chat-agidesk.includefollower")}
              />
            </Form.Group>
          </Form>
        ) : null}
      </div>
      <div id="sidemenu" className="px-4 content overflow-y-scroll">
        {getPageComponent()}
      </div>
      <div className="footer mx-4 gap-4 flex justify-center">
        {actions.show ? (
          <div className="flex mx-auto items-center justify-between w-full mt-2">
            <Button
              minWidth
              inverted
              width="w-[175px]"
              label={t("chat-agidesk.title.update") || "Atualizar atendimento"}
              onClick={() => {
                navigate(`${location.pathname}#updateticket`);
                setActions({
                  show: false,
                  newTicket: false,
                  update: true,
                });
              }}
              icon="las la-comment"
            />
            <Button
              minWidth
              width="w-[175px]"
              // label={t("chat-agidesk.button-create") || ""}
              label="Novo atendimento"
              onClick={() => {
                navigate(`${location.pathname}#newticket`);
                setActions({
                  show: false,
                  newTicket: true,
                  update: false,
                });
              }}
              icon="las la-plus"
            />
          </div>
        ) : (
          <ActionButtonsModal
            sidebar
            disabled={goDisabled()}
            cancelAction={goBack}
            cancelLabel={t("formbutton.label.back") || ""}
            submitIcon="las la-life-ring"
            submitLabel={
              actions.newTicket
                ? t("formbutton.label.new-ticket") || ""
                : t("formbutton.label.update-ticket") || ""
            }
            submitAction={submitAction}
          />
        )}
      </div>
    </div>
  );
};
ChatAgidesk.defaultProps = {
  setVisible: undefined,
};
export default ChatAgidesk;
