import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import CopyButton from "../../shared/components/Buttons/CopyButton.component";
import ActionButton from "../../shared/components/Buttons/ActionButton.component";
import ShowHideButton from "../../shared/components/Buttons/ShowHideButton.component";
import { useAppDispatch } from "../../hooks/redux/hooks";
import { refreshAppKey } from "../../slices/appkey.slice";
import { reselectSettings } from "../../slices/settings.slice";
import customToast from "../../shared/utils/customToast";
import { IToastType } from "../../shared/models/types/Toast.type";
import LinkButton from "../../shared/components/Buttons/LinkButton.component";

const AppkeySection = ({ instance }: { instance: any }) => {
  const [showHide, setShowHide] = useState<{
    current: boolean;
    older: boolean;
  }>({
    current: false,
    older: false,
  });

  const dispatch = useAppDispatch();

  const messages = {
    success: "Chave de acesso atualizada com sucesso",
  };

  const handleShowHide = (
    e: React.MouseEvent<Element, MouseEvent>,
    type: "current" | "older"
  ) => {
    e.preventDefault();
    setShowHide((prevState) => ({
      current: type === "current" ? !prevState.current : prevState.current,
      older: type === "older" ? !prevState.older : prevState.older,
    }));
  };

  const rotateCurrentKey = async () => {
    const { payload } = await dispatch(refreshAppKey(instance));
    if (typeof payload.message === "undefined") {
      dispatch(
        reselectSettings({
          ...instance,
          appkey: payload.appkey,
          lastappkey: payload.lastappkey,
        })
      );
      customToast({
        type: IToastType.SUCCESS,
        message: messages.success,
      });
    }
  };

  interface customObject {
    [key: string]: {
      label: string;
      value: string;
      placeholder: string;
      _id: string;
      hide: boolean;
      titleButton: string;
      primaryAction: {
        type: "goto" | "copy" | "delete" | "rotate" | "showhide";
        title: string;
        onClick: React.MouseEventHandler<HTMLButtonElement>;
      };
    };
  }

  const inputKeyValues: customObject = {
    current: {
      label: "Chave de acesso atual",
      value: instance?.appkey,
      placeholder: "Chave de acesso atual",
      _id: "appkey-current",
      hide: showHide.current,
      titleButton: `${!showHide.current ? "Mostrar" : "Esconder"} chave atual`,
      primaryAction: {
        type: "rotate",
        title: "Rotacionar",
        onClick: rotateCurrentKey,
      },
    },
    older: {
      label: "Chave de acesso anterior",
      value: instance?.lastappkey,
      placeholder: "Chave de acesso anterior",
      _id: "appkey-older",
      hide: showHide.older,
      titleButton: `${!showHide.older ? "Mostrar" : "Esconder"} chave anterior`,
      primaryAction: {
        type: "delete",
        title: "Remover",
        onClick: () => null,
      },
    },
  };

  const inputKey = (type: "current" | "older") => {
    return (
      <Form.Group>
        <Form.Input
          autoComplete="off"
          className="relative w-full mt-4 md:w-full"
          label={inputKeyValues[type].label}
          value={inputKeyValues[type].value}
          readOnly
          fluid
          type={inputKeyValues[type].hide ? "input" : "password"}
          name={inputKeyValues[type]._id}
          id={inputKeyValues[type]._id}
          key={inputKeyValues[type]._id}
          variant="outlined"
          placeholder={inputKeyValues[type].placeholder}
          action
        >
          <input />
          <ShowHideButton
            hide={inputKeyValues[type].hide}
            title={inputKeyValues[type].titleButton}
            onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
              handleShowHide(e, type)
            }
          />
          {type === "current" ? (
            <ActionButton
              type={inputKeyValues[type].primaryAction.type}
              title={inputKeyValues[type].primaryAction.title}
              onClick={inputKeyValues[type].primaryAction.onClick}
            />
          ) : null}
          <CopyButton _id={inputKeyValues[type]._id} />
        </Form.Input>
      </Form.Group>
    );
  };

  return (
    <Form>
      <Form.Field
        required
        className="flex flex-col cursor-normal w-full md:w-full"
      >
        <a
          title="Clique para acessar a documentação da API"
          href="https://atendimento.agidesk.com/br/central-de-ajuda/api-agitalks"
          target="_blank"
          rel="noreferrer"
        >
          <LinkButton label="Documentação da API" onClick={() => null} />
        </a>
      </Form.Field>
      {inputKey("current")}
      {inputKey("older")}
    </Form>
  );
};

export default AppkeySection;
