/* eslint-disable no-shadow */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { tagsService } from "../services";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import { IUser } from "./users.slice";
import customToast from "../shared/utils/customToast";
import { IToastType } from "../shared/models/types/Toast.type";
import { ITeam } from "./teams.slice";

export enum TagType {
  ALL = "ALL",
  CONTACT = "CONTACT",
  CHAT = "CHAT",
  // AGENT = "AGENT",
  // CAMPAIGN = "CAMPAIGN",
  // TEAM = "TEAM",
  // CUSTOMER = "CUSTOMER",
}

export interface ITag {
  _id?: string;
  name?: string;
  type?: TagType;
  color?: string;
  active?: boolean;
  global?: boolean;
  tenant?: string;
  teams?: string[] | ITeam[];
  deleted?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
}

export type Tags = ITag[];

export interface INewTag {
  name: string;
  type: TagType;
}

interface TagsState extends AsyncState {
  isLoadingTags: boolean;
  isLoadingDropdownTags: boolean;
  isCreatingTags: boolean;
  isRemoving: boolean;
  tags: Tags;
  totalTags: number;
  chattags: Tags;
  totalChattags: number;
  contacttags: Tags;
  totalContacttags: number;
  selectedTag: ITag | null;
  selectedLocalTag: ITag | null;
}

const initialState: TagsState = {
  tags: [],
  totalTags: 0,
  chattags: [],
  totalChattags: 0,
  contacttags: [],
  totalContacttags: 0,
  selectedTag: null,
  selectedLocalTag: null,
  isRemoving: false,
  isLoadingTags: false,
  isLoadingDropdownTags: false,
  isCreatingTags: false,
  isSuccess: false,
  isError: false,
};

export interface ITagFilter {
  type?: TagType[];
  teams?: string[];
  groupchat?: boolean;
  deleted?: boolean;
  content?: string;
  name?: string;
  active?: boolean;
}

export interface IFilterTag {
  skip: number;
  limit: number;
  filter: ITagFilter;
  deleted?: boolean;
  allStatus?: boolean;
}

export const getSearchTags = createAsyncThunk(
  "tags/search",
  async (filterTag: IFilterTag, thunkAPI) => {
    try {
      return await tagsService.search(filterTag);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const getTags = createAsyncThunk(
//   "tags",
//   async (_groupLimit: IGroupLimit, thunkAPI) => {
//     try {
//       return await tagsService.tags(_groupLimit);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const selectTag = createAsyncThunk(
  "tags/selectTag",
  async ({ _id }: { _id: string }, thunkAPI) => {
    try {
      return await tagsService.tag({ _id });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateTag = createAsyncThunk(
  "tags/update",
  async ({ _tag, noToast }: { _tag: any; noToast?: boolean }, thunkAPI) => {
    try {
      return await tagsService.update({ _tag });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteTag = createAsyncThunk(
  "tags/delete",
  async (ids: string[], thunkAPI) => {
    try {
      return await tagsService.deleteTags({ ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createTag = createAsyncThunk(
  "tags/create",
  async (newTag: INewTag, thunkAPI) => {
    try {
      return await tagsService.create(newTag);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    reselectLocalTag(state, action: PayloadAction<ITag | null>) {
      state.selectedLocalTag = action.payload;
    },
    selectTags(state, action: PayloadAction<ITag[]>) {
      state.tags = action.payload || [];
    },
    selectTotalTags(state, action: PayloadAction<number>) {
      state.totalTags = action.payload || 0;
    },
    selectChattags(state, action: PayloadAction<ITag[]>) {
      state.chattags = action.payload || [];
    },
    selectTotalChattags(state, action: PayloadAction<number>) {
      state.totalChattags = action.payload || 0;
    },
    selectContacttags(state, action: PayloadAction<ITag[]>) {
      state.contacttags = action.payload || [];
    },
    selectTotalContacttags(state, action: PayloadAction<number>) {
      state.totalContacttags = action.payload || 0;
    },
    reselectTag(state, action: PayloadAction<ITag | null>) {
      state.selectedTag = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // TAGS
      .addCase(getSearchTags.pending, (state, action) => {
        if (action.meta.arg.limit === 10) {
          state.isLoadingTags = true;
        }
        state.isLoadingDropdownTags = true;
      })
      .addCase(getSearchTags.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.tags = action.payload.results || [];
        // if (state.totalTags <= action.payload.count)
        state.totalTags = action.payload.count;
        state.isLoadingTags = false;
        state.isLoadingDropdownTags = false;
      })
      .addCase(getSearchTags.rejected, (state) => {
        state.isError = true;
        state.tags = [];
        state.isLoadingTags = false;
        state.isLoadingDropdownTags = false;
      })
      // SHOW CUSTOMER
      .addCase(selectTag.pending, (state) => {
        state.isLoadingTags = true;
      })
      .addCase(selectTag.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (action.payload?._id) {
          state.selectedTag = action.payload;
          state.selectedLocalTag = action.payload;
        }
        state.isLoadingTags = false;
      })
      .addCase(selectTag.rejected, (state) => {
        state.isError = true;
        state.selectedTag = null;
        state.isLoadingTags = false;
      })
      // UPDATE CUSTOMER
      .addCase(updateTag.pending, (/* state */) => {
        // state.isLoadingTags = true;
      })
      .addCase(updateTag.fulfilled, (state, action) => {
        if (
          action?.payload !== null &&
          typeof action?.payload?._id !== "undefined"
        ) {
          state.isSuccess = true;
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Alteração salva com sucesso!`,
            });
          }
        } else {
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado!`,
            });
          }
          state.isError = true;
        }
        state.isLoadingTags = false;
      })
      .addCase(updateTag.rejected, (state) => {
        state.isError = true;
        state.selectedTag = null;
        state.isLoadingTags = false;
      })
      .addCase(createTag.pending, (state) => {
        state.isCreatingTags = true;
      })
      .addCase(createTag.fulfilled, (state, action) => {
        if (typeof action?.payload?._id !== "undefined") {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Marcador criado com sucesso!`,
          });
          const newState = state.tags;
          newState.push(action.payload);
          state.tags = newState;
        } else {
          state.isError = true;
          if (
            typeof action?.payload === "string" &&
            action?.payload.includes("duplicate")
          ) {
            customToast({
              type: IToastType.ERROR,
              message: `Opa! Já existe um marcador com esse nome!`,
            });
          } else {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado! Tente novamente.`,
            });
          }
        }
        state.isCreatingTags = false;
      })
      .addCase(createTag.rejected, (state) => {
        state.isError = true;
        state.isCreatingTags = false;
      })
      .addCase(deleteTag.pending, (state) => {
        state.isRemoving = true;
      })
      .addCase(deleteTag.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          typeof action.payload !== "undefined" &&
          typeof action.payload?.message !== "string"
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Remoção realizada com sucesso!`,
          });
        } else {
          state.isError = true;
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado!`,
          });
        }
        state.isRemoving = false;
      })
      .addCase(deleteTag.rejected, (state) => {
        state.isError = true;
        state.isRemoving = false;
      });
  },
});

export default tagsSlice.reducer;
export const {
  logout,
  reselectTag,
  reselectLocalTag,
  selectTags,
  selectTotalTags,
  selectChattags,
  selectContacttags,
  selectTotalChattags,
  selectTotalContacttags,
} = tagsSlice.actions;
