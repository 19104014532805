/* eslint-disable no-nested-ternary */
import React from "react";
import { RecoveryForm } from "../features/auth/components/Forms";
import AuthLayoutPage from "./AuthLayout.page";
import { useAppSelector } from "../hooks/redux/hooks";
import { UserType } from "../slices/users.slice";

const RecoveryPage = ({ widgetType }: { widgetType?: UserType }) => {
  const { isLoadingAuth, isForgot } = useAppSelector((state) => state.auth);

  const getMessage = () => {
    if (isForgot)
      return (
        <div className="mb-[32px]">
          <h2 className="my-4 text-[20px] 2xl:text-[24px] font-bold text-[#334155]">
            Pronto.
            <br />
            Agora verifique seu e-mail
          </h2>
          <h2 className="mt-6 mb-[32px] text-[14px] 2xl:text-[18px] font-normal text-[#64748B]">
            Enviamos um link de confirmação para
            <br />o e-mail informado
          </h2>
          <h2 className="text-[12px] 2xl:text-[16px] font-normal text-[#94A3B8]">
            Não recebeu o e-mail?
            <br />
            Verifique sua pasta de spam ou tente novamente.
          </h2>
        </div>
      );
    if (isLoadingAuth)
      return (
        <h2 className="my-4 text-[20px] 2xl:text-[24px] font-bold text-[#334155]">
          Seu e-mail está sendo verificado!
        </h2>
      );
    return null;
  };

  return (
    <AuthLayoutPage widgetType={widgetType}>
      {getMessage()}
      <RecoveryForm widgetType={widgetType} />
    </AuthLayoutPage>
  );
};

RecoveryPage.defaultProps = {
  widgetType: UserType.NONE,
};

export default RecoveryPage;
