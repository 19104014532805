import React from "react";
import { PasswordForm } from "../features/auth/components/Forms";
import AuthLayoutPage from "./AuthLayout.page";
import { useAppSelector } from "../hooks/redux/hooks";
import { UserType } from "../slices/users.slice";

const PasswordPage = ({ widgetType }: { widgetType?: UserType }) => {
  const { isLoadingAuth } = useAppSelector((state) => state.auth);

  return (
    <AuthLayoutPage widgetType={widgetType}>
      {isLoadingAuth ? (
        <h2 className="my-4 text-[20px] 2xl:text-[24px] font-bold text-[#334155]">
          Sua senha está sendo alterada
        </h2>
      ) : null}
      <PasswordForm />
    </AuthLayoutPage>
  );
};

PasswordPage.defaultProps = {
  widgetType: UserType.NONE,
};

export default PasswordPage;
