import React from "react";
import { GroupedForm } from "../features/auth/components/Forms";
import AuthLayoutPage from "./AuthLayout.page";
import { UserType } from "../slices/users.slice";

const LoginTenantPage = ({ widgetType }: { widgetType?: UserType }) => {
  return (
    <AuthLayoutPage widgetType={widgetType}>
      <GroupedForm widgetType={widgetType} />
    </AuthLayoutPage>
  );
};

LoginTenantPage.defaultProps = {
  widgetType: UserType.NONE,
};

export default LoginTenantPage;
