/* eslint-disable react/no-danger */
import React from "react";
import { UserType } from "../slices/users.slice";
import AuthLayoutPage from "./AuthLayout.page";
import privacy from "../assets/html/privacy";

const Privacy = ({ widgetType }: { widgetType?: UserType }) => {
  return (
    <AuthLayoutPage widgetType={widgetType}>
      <div className="mx-4">
        <span
          dangerouslySetInnerHTML={{
            __html: privacy,
          }}
        />
      </div>
    </AuthLayoutPage>
  );
};

Privacy.defaultProps = {
  widgetType: UserType.NONE,
};

export default Privacy;
