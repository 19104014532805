import React, { useEffect } from "react";
import { Form } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../../../hooks/redux/hooks";
import Toggle from "../../../../../../shared/components/Toggle.component";
import FilesArea from "../../../../../../shared/components/Files/FilesArea.component";
import { IAgideskNewTicket } from "../../../../../../slices/externalapps.slice";
import LoadingDots from "../../../../../../shared/components/LoadingDots.component";
import CategoriesTopicsSelectionComponent from "../../../../../../shared/components/Agidesk/CategoriesTopicsSelection.component";

const NewTicket = ({
  ticket,
  setTicket,
  files,
  setFiles,
}: {
  ticket: IAgideskNewTicket;
  setTicket: React.Dispatch<React.SetStateAction<IAgideskNewTicket>>;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { selectedChat, checkingChatMessages } = useAppSelector(
    (state) => state.chats
  );
  const { isCreatingTicket } = useAppSelector((state) => state.externalapps);

  const _value = checkingChatMessages.selectedMessageIDs.length;

  useEffect(() => {
    if (location.hash === "#newticket") {
      const _update = {
        ...ticket,
        messages: checkingChatMessages.selectedMessageIDs,
      };
      setTicket(_update);
    }
  }, [_value]);

  return (
    <div className="bg-white pt-2 pb-5">
      <Form>
        {isCreatingTicket ? (
          <LoadingDots medium className="flex justify-center items-center" />
        ) : (
          <>
            <CategoriesTopicsSelectionComponent
              type="chat-new-ticket"
              ticket={ticket}
              setTicket={setTicket}
            />
            {ticket.services ? (
              <>
                <Form.Input
                  autoComplete="off"
                  required
                  label={t("form.label.subject")}
                  placeholder={t("form.placeholder.subject")}
                  type="text"
                  defaultValue={ticket.title}
                  onChange={(e, { value }) => {
                    setTicket({
                      ...ticket,
                      title: value,
                    });
                  }}
                />
                <Form.TextArea
                  style={{ resize: "none" }}
                  label={t("form.label.help")}
                  placeholder={t("form.placeholder.help")}
                  maxLength={1024}
                  type="text"
                  defaultValue={ticket.htmlcontent}
                  onChange={(e, { value }) => {
                    if (value) {
                      setTicket({
                        ...ticket,
                        htmlcontent: value.toString(),
                      });
                    } else {
                      setTicket({
                        ...ticket,
                        htmlcontent: "",
                      });
                    }
                  }}
                />
                <Toggle
                  containerClass="flex w-full mt-3"
                  defaultChecked={ticket.addchat || false}
                  mainClass=""
                  onChange={(_, { checked }) => {
                    setTicket({
                      ...ticket,
                      addchat: checked || false,
                    });
                  }}
                  labelClass="ml-4 mb-5"
                  label={t("toggle.chat-agidesk.addchat")}
                  simple
                />
                <Toggle
                  containerClass="flex w-full -mt-1"
                  defaultChecked={ticket.internal || false}
                  mainClass=""
                  onChange={(_, { checked }) => {
                    setTicket({
                      ...ticket,
                      internal: checked || false,
                    });
                  }}
                  labelClass="ml-4 mb-5"
                  label={t("toggle.chat-agidesk.internal")}
                  simple
                />
                <Toggle
                  containerClass="flex w-full -mt-1"
                  defaultChecked={ticket.openingsolved || false}
                  mainClass=""
                  onChange={(_, { checked }) => {
                    setTicket({
                      ...ticket,
                      openingsolved: checked || false,
                    });
                  }}
                  labelClass="ml-4 mb-5"
                  label={t("toggle.chat-agidesk.openingsolved")}
                  simple
                />
                <div className="-mt-3">
                  {selectedChat && typeof selectedChat._id !== "undefined" ? (
                    <FilesArea
                      chat={selectedChat}
                      files={files}
                      setFiles={setFiles}
                    />
                  ) : null}
                </div>
                {!ticket.addchat ? (
                  <label
                    className="mt-3"
                    title={
                      _value > 0
                        ? `${
                            _value === 1 ? "Será incluída" : "Serão incluídas"
                          } ${_value} ${
                            _value === 1 ? "mensagem" : "mensagens"
                          } ao atendimento selecionado`
                        : "Nenhuma mensagem da conversa será incluída ao atendimento selecionado"
                    }
                  >
                    {_value > 0 && !ticket.addchat
                      ? `${
                          _value === 1 ? "Será incluída" : "Serão incluídas"
                        } ${_value} ${
                          _value === 1 ? "mensagem" : "mensagens"
                        } ao atendimento selecionado`
                      : "Nenhuma mensagem da conversa será incluída ao atendimento selecionado"}
                  </label>
                ) : null}
              </>
            ) : null}
          </>
        )}
      </Form>
    </div>
  );
};

export default NewTicket;
