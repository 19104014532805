/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../hooks/redux/hooks";
import {
  IAgideskEntity,
  reselectExternalappAgidesk,
} from "../../../slices/externalapps.slice";
import PopupComponent from "../Popup.component";
import Button from "../Buttons/Button.component";
import Dropdown from "../Dropdown.component";
import { ITeam } from "../../../slices/teams.slice";
import { ddOptions } from "../../models/interfaces/dropdownOptions.interface";
import findInObject from "../../utils/findInObject";
import { RoleUser } from "../../../slices/users.slice";
import CategoriesTopicsSelectionComponent from "./CategoriesTopicsSelection.component";
import ServicesDisplayComponent from "./ServicesDisplay.component";

const TeamServiceRelation = ({
  clearOptions,
  clearValues,
  isInactive = false,
}: {
  clearOptions?: () => void;
  clearValues?: () => void;
  isInactive?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { selectedExternalAppAgidesk } = useAppSelector(
    (state) => state.externalapps
  );

  type dataProps = { team: string; service: IAgideskEntity };

  const [relationValue, setRelationValue] = useState<dataProps>({
    team: "",
    service: {
      id: "",
      title: "",
    },
  });
  const [focusId, setFocusId] = useState<string>("");
  const [isLoadingDropdown, setIsLoadingDropdown] = useState<boolean>(false);

  const TEAMS_LENGTH = user?.teams?.length || 0;

  const [data, setData] = useState<dataProps[]>(
    isInactive
      ? selectedExternalAppAgidesk?.fields?.ticketfinishinactiveteam || []
      : selectedExternalAppAgidesk?.fields?.ticketfinishteam || []
  );

  const [optionsTeams, setOptionsTeams] = useState<ddOptions[]>([]);
  if (typeof user?.teams !== "undefined" && user?.teams.length > 0) {
    user?.teams?.forEach((team: ITeam) => {
      if (
        typeof team?._id !== "undefined" &&
        typeof findInObject(data, "team", team._id) === "undefined"
      ) {
        optionsTeams.push({
          key: team._id,
          text: team.name,
          value: team._id,
        });
      }
    });
  }

  const [showTeamRelation, setShowTeamRelation] = useState(
    isInactive
      ? (typeof selectedExternalAppAgidesk?.fields?.ticketfinishinactiveteam !==
          "undefined" &&
          selectedExternalAppAgidesk.fields.ticketfinishinactiveteam.length >
            0) ||
          data.length > 0
      : (typeof selectedExternalAppAgidesk?.fields?.ticketfinishteam !==
          "undefined" &&
          selectedExternalAppAgidesk.fields.ticketfinishteam.length > 0) ||
          data.length > 0
  );

  const handleClick = () => {
    const updatedData = [...data, relationValue];

    const filteredData = updatedData.reduce((acc: any, current: any) => {
      const existingIndex = acc.findIndex((item: any) => {
        return (
          item.team._id === current.team._id || item.team._id === current.team
        );
      });
      if (existingIndex !== -1) {
        acc.splice(existingIndex, 1);
      }
      acc.push(current);
      return acc;
    }, []);

    setData(filteredData);
    dispatch(
      reselectExternalappAgidesk({
        ...selectedExternalAppAgidesk,
        fields: {
          ...selectedExternalAppAgidesk.fields,
          [isInactive ? "ticketfinishinactiveteam" : "ticketfinishteam"]:
            filteredData,
        },
      })
    );
    setOptionsTeams([]);
    if (clearValues) clearValues();
    if (clearOptions) clearOptions();
    setRelationValue({
      team: "",
      service: {
        id: "",
        title: "",
      },
    });
  };

  return (
    <div key="team-service-relation" className="mx-2">
      {TEAMS_LENGTH > 0 ? (
        <PopupComponent
          className="flex items-center"
          icon={
            showTeamRelation
              ? "las la-angle-up"
              : "las la-angle-down pb-[0.3px]"
          }
          classLabel="!font-semibold"
          label={
            showTeamRelation
              ? "Fechar serviços por equipes"
              : "Relacionar serviços por equipes"
          }
          content={
            <h1 className="font-semibold text-[12px]">{`Clique para escolher um serviço específico para cada equipe.\nEquipes sem serviço específico usarão o serviço padrão.`}</h1>
          }
          onClick={() => setShowTeamRelation((prevState) => !prevState)}
        />
      ) : null}

      {showTeamRelation ? (
        <div className="flex flex-col gap-y-2 border rounded-[4px] p-4 mt-2 mb-4 w-full">
          <div className="flex flex-col gap-4 w-full">
            <Dropdown
              id="dropdown-agidesk-teams"
              className="mt-2 items-center w-full"
              disabled={
                selectedExternalAppAgidesk?.deleted ||
                !user?.roles?.includes(RoleUser.ADMINISTRATOR)
              }
              loading={
                isLoadingDropdown && focusId === "dropdown-agidesk-teams"
              }
              label="Equipe"
              placeholder="Equipe"
              required
              value={relationValue.team.length > 0 ? relationValue.team : ""}
              fluid
              search
              selection
              options={optionsTeams}
              onFocus={() => {
                setFocusId("dropdown-agidesk-service-teams");
              }}
              onBlur={() => {
                setFocusId("");
              }}
              onChange={(e, { value }) => {
                if (typeof value === "string") {
                  setRelationValue({
                    ...relationValue,
                    team: value,
                  });
                }
              }}
              onOpen={async () => {
                setIsLoadingDropdown(true);
                if (
                  typeof user?.teams !== "undefined" &&
                  user?.teams.length > 0
                ) {
                  user?.teams?.forEach((team: ITeam) => {
                    if (
                      typeof team?._id !== "undefined" &&
                      typeof findInObject(data, "team", team._id) ===
                        "undefined"
                    ) {
                      optionsTeams.push({
                        key: team._id,
                        text: team.name,
                        value: team._id,
                      });
                    }
                  });
                  setOptionsTeams(optionsTeams);
                }
                setIsLoadingDropdown(false);
              }}
            />
            <CategoriesTopicsSelectionComponent
              type="team-service-relation"
              relation={relationValue}
              setRelation={setRelationValue}
            />
          </div>
          <div className="my-2 flex justify-end">
            <Button
              disabled={
                relationValue?.team?.length === 0 ||
                relationValue?.service?.id?.length === 0
              }
              minWidth
              icon="las la-plus"
              label="Adicionar"
              title="Adicionar relação"
              onClick={handleClick}
            />
          </div>

          <ServicesDisplayComponent
            data={data as any}
            setData={setData as any}
            type="team-service"
          />
        </div>
      ) : null}
    </div>
  );
};

export default TeamServiceRelation;
