import React from "react";
import { DropdownProps, SemanticWIDTHS } from "semantic-ui-react";
import Dropdown from "../Dropdown.component";

// eslint-disable-next-line no-shadow
export enum ImportKey {
  "ONLY-NEW" = "only-new",
  "UPDATE-CREATE" = "update-and-create",
}

const ImportKeysDropdown = ({
  id,
  className,
  disabled,
  defaultValue,
  onChange,
  width,
  fluid,
}: {
  id?: string;
  width?: SemanticWIDTHS;
  className?: string;
  disabled?: boolean;
  defaultValue?: ImportKey;
  onChange?:
    | ((
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: DropdownProps
      ) => void)
    | undefined;
  fluid?: boolean;
}) => {
  return (
    <Dropdown
      id={id || "dropdown-importkeys"}
      className={className || "w-full md:w-full"}
      disabled={disabled || false}
      fluid={fluid}
      width={width}
      selection
      required
      placeholder="Defina o tipo de importação desejado"
      label="Defina o tipo de importação desejado"
      defaultValue={defaultValue}
      onChange={onChange}
      options={[
        {
          key: 0,
          text: "Somente criar novos",
          value: ImportKey["ONLY-NEW"],
        },
        {
          key: 1,
          text: "Criar novos e atualizar existentes",
          value: ImportKey["UPDATE-CREATE"],
        },
      ]}
    />
  );
};

ImportKeysDropdown.defaultProps = {
  id: undefined,
  className: undefined,
  disabled: undefined,
  defaultValue: undefined,
  onChange: undefined,
  fluid: undefined,
  width: undefined,
};

export default ImportKeysDropdown;
