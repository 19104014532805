import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { teamsChatsAssignment } from "../../../slices/teams.slice";
import { IUser, UserStatus } from "../../../slices/users.slice";
import {
  changeStatus,
  logout,
  refreshLogout,
} from "../../../slices/auth.slice";

interface IUserProfile {
  user: IUser | null | undefined;
  isOnline: boolean;
  hasAvatar: boolean;
  updateStatus: () => Promise<void>;
  handleLogout: () => Promise<void>;
}

const useUserProfile = (): IUserProfile => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const hasAvatar = Boolean(user?.avatar?.path && user?.avatar?.name);
  const isOnline = user?.status === UserStatus.ONLINE;

  const updateStatus = async () => {
    if (user?._id) {
      dispatch(
        changeStatus({
          status:
            user?.status === UserStatus.OFFLINE
              ? UserStatus.ONLINE
              : UserStatus.OFFLINE,
          origin: user?._id,
          manual: true,
        })
      );

      if (user?.status === UserStatus.OFFLINE) {
        await dispatch(teamsChatsAssignment());
      }
    }
  };

  const handleLogout = async () => {
    dispatch(changeStatus({ status: UserStatus.OFFLINE, origin: user?._id }));
    await dispatch(logout());
    dispatch(refreshLogout());
    localStorage.clear();
  };

  return {
    user,
    isOnline,
    hasAvatar,
    updateStatus,
    handleLogout,
  };
};

export default useUserProfile;
