import React from "react";
import { ToastOptions, toast } from "react-toastify";
import { IToastType } from "../models/types/Toast.type";

const customToast = ({
  type,
  message,
  time,
}: {
  type: IToastType;
  message: string | React.ReactNode;
  time?: number;
}) => {
  const toastOptions: ToastOptions = {
    position: "top-right",
    autoClose: time || 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  switch (type) {
    case IToastType.ERROR:
      return toast.error(message, toastOptions);
    case IToastType.WARNING:
      return toast.warning(message, toastOptions);
    case IToastType.SUCCESS:
    default:
      return toast.success(message, toastOptions);
  }
};

export default customToast;
