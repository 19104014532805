import { forwardRef, RefObject } from "react";
import { Popup } from "semantic-ui-react";

interface PopUpProps {
  open: boolean;
  children?: React.ReactNode;
}

const PopUpMenu = forwardRef<HTMLDivElement, PopUpProps>(
  ({ open, children }, ref) => (
    <Popup
      context={ref as RefObject<HTMLElement>}
      open={open}
      basic
      style={{ padding: 0 }}
      offset={[30, 0]}
      position="top right"
    >
      {children}
    </Popup>
  )
);

export default PopUpMenu;
