import React, { FormEvent, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import useInput from "../../../../hooks/useInput";
import validateEmail from "../../../../shared/utils/validation/email";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux/hooks";
import { forgotPassword } from "../../../../slices/auth.slice";
import LoadingDots from "../../../../shared/components/LoadingDots.component";
import { validateNameLength } from "../../../../shared/utils/validation/length";
import FormButton from "../../../../shared/components/Buttons/FormButton.component";
import ErrorLabel from "./ErrorLabel.component";
import LinkButton from "../../../../shared/components/Buttons/LinkButton.component";
import CommonError from "./CommonError.component";
import { UserType } from "../../../../slices/users.slice";

const RecoveryForm = ({ widgetType }: { widgetType?: UserType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/chat";
  const dispatch = useAppDispatch();
  const {
    isLoadingAuth,
    isSuccess,
    isAuthenticated,
    isForgot,
    isErrorPass,
    tenantID,
  } = useAppSelector((state) => state.auth);

  const {
    text: tenant,
    shouldDisplayError: tenantHasError,
    textChangeHandler: tenantChangeHandler,
    inputBlurHandler: tenantBlurHandler,
  } = useInput(validateNameLength);
  const {
    text: email,
    shouldDisplayError: emailHasError,
    emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(validateEmail);

  useEffect(() => {
    //
  }, [isSuccess, dispatch, email.length]);

  useEffect(() => {
    if (!isAuthenticated) return;
    navigate(from, { replace: true });
  }, [isAuthenticated]);

  const onSubmitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if ((tenantHasError && !tenantID) || emailHasError) return;
    if ((tenant.length === 0 && !tenantID) || email.length === 0) return;
    const recoveryUser = {
      tenant: !tenantID
        ? tenant.toLowerCase().trim()
        : tenantID.toLowerCase().trim(),
      email: email.toLowerCase().trim(),
    };
    await dispatch(forgotPassword(recoveryUser));
  };

  const goToLogin = async () => {
    navigate("/login");
  };

  if (isLoadingAuth)
    return <LoadingDots className="flex justify-center items-center" />;
  if (!isForgot)
    return (
      <Form
        className="bg-white h-full rounded-xl flex flex-col -mt-[16px]"
        onSubmit={onSubmitHandler}
      >
        {!widgetType || widgetType !== UserType.AGENT ? (
          <>
            <Form.Input
              label={t("form.label.tenant")}
              value={tenantID || tenant}
              // required
              disabled={!!tenantID && tenantID.length > 0}
              onChange={!tenantID ? tenantChangeHandler : undefined}
              onBlur={!tenantID ? tenantBlurHandler : null}
              error={!tenantID ? tenantHasError : null}
              type="text"
              name="tenant"
              id="tenant"
              variant="outlined"
              placeholder={t("form.placeholder.tenant")}
              autoComplete="off"
            />
            {tenantHasError && (
              <ErrorLabel
                error={tenantHasError}
                label={t("form.error.tenant")}
              />
            )}
          </>
        ) : null}
        <Form.Input
          label={t("form.label.email")}
          value={email}
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
          error={emailHasError}
          type="email"
          name="email"
          id="email"
          variant="outlined"
          placeholder={t("form.placeholder.email")}
          autoComplete="new-email"
        />
        {emailHasError && (
          <ErrorLabel error={emailHasError} label={t("form.error.email")} />
        )}
        <FormButton
          className={`flex flex-wrap justify-center ${
            isErrorPass ? "mb-0" : "mb-5"
          } ${emailHasError ? "mt-4" : "mt-1"}`}
          disabled={
            (tenant.length === 0 && !tenantID) ||
            email.length === 0 ||
            (tenantHasError && !tenantID) ||
            emailHasError ||
            false
          }
          label={t("formbutton.label.send")}
        />
        {isErrorPass ? (
          <CommonError message="Empresa e/ou usuário inválidos" />
        ) : null}

        <div className="!mt-0 text-center flex items-center justify-center">
          <LinkButton label={t("linkbutton.label.login")} onClick={goToLogin} />
        </div>
      </Form>
    );
  return (
    <LinkButton
      extraClass="-ml-2"
      leftIcon="las la-arrow-left mr-2"
      label={t("formbutton.label.back")}
      onClick={goToLogin}
    />
  );
};

RecoveryForm.defaultProps = {
  widgetType: UserType.NONE,
};

export default RecoveryForm;
