/* eslint-disable react/require-default-props */
import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "semantic-ui-react";

export type CommonErrorProps = {
  message?: string;
  extraClass?: string;
};

const CommonError = ({ message, extraClass }: CommonErrorProps) => {
  const { t } = useTranslation();
  return (
    <div className={`mt-3 mb-4 ${extraClass}`}>
      <Label
        basic
        prompt
        className="w-full !border-[1px] !border-red-300 !bg-red-50 !text-red-500 !font-medium !text-[12px] !leading-[18px] !px-4 !py-3 !rounded"
      >
        {message ?? t("form.error.common")}
      </Label>
    </div>
  );
};

export default CommonError;
